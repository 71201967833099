declare global {
  interface Window {
    uabModule: any;
    umidToken: any;
  }
}

export interface ILoginRiskInfo {
  ncSessionId: string;
  ncSig: string;
  ncToken: string;
}

export type IReponse<T> = {
  success: boolean;
  data: T & {
    errorCode?: {
      displayMessage: string;
    };
  };
};

export interface ILoginParams {
  countryCallingCode?: string;
  phoneNumber?: string;
  email?: string;
  riskInfo?: ILoginRiskInfo | '';
  password?: string;
  redirect_url?: string;
}

export type ILoginResponse = IReponse<{
  actionParameters: {
    loginJumpUrl?: string; // 重定向URL
    token?: string; // OTP登录的Token
    tokenType?: string; // Token类型
    redirectUrl?: string
  };
  loginUserDTO?: {
    email: string;
    userId: string;
  };
  actionType: ACTION_TYPE;
}>;

export enum ACTION_TYPE {
  NC_AUTH = 'NC_AUTH',
  REDIRECT = 'REDIRECT',
  OAUTH_PHONE_VERIFY = 'OAUTH_PHONE_VERIFY',
  COMPLETE_PASSWORD_FOR_LOGIN = 'COMPLETE_PASSWORD_FOR_LOGIN',
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  OAUTH_COMPLETE_PHONE_FOR_REGISTER = 'OAUTH_COMPLETE_PHONE_FOR_REGISTER',
}

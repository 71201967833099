import request from './request';
import get from '../pure-functions/get';
import loadscript from '../side-effects/load-script';
import enableEmoji from './emoji-parse';
import { isEmpty } from '../pure-functions/condition';

const maxRetryCount = 3;
let mtop = get(window, 'lib.mtop');
let mtopConfig = get(window, 'mtopConfig');

const slsLogKey = 'api';
const slsLogSend = get(window, ['slsLogger', 'send'], console.log);

const sendLog = (starttime, api, status, code) => {
  const timeend = new Date().getTime();
  slsLogSend(slsLogKey, `${api}+${status}+${timeend - starttime}+${code}`);
}

export const initial = async () => {
  /**
   * 加载mtop
   */
  let currentLibRetryCount = 0;
  const mtopCdnUrl = get(window, 'getMtopCdnUrl') || 'https://assets.alicdn.com/g/mtb/lib-mtop/2.3.16/mtop.js';
  const mtopLibFallback = async () => {
    await loadscript(mtopCdnUrl);
    mtop = get(window, 'lib.mtop');
    if (!mtop) {
      if (currentLibRetryCount < maxRetryCount) {
        currentLibRetryCount += 1;
        await mtopLibFallback();
      } else {
        mtop = {
          request: () => {
            return Promise.resolve({
              success: false,
              error: 'Mtop library load failed'
            });
          },
          config: {}
        }
      }
    }
  }
  if (!mtop) {
    await mtopLibFallback();
  }

  // 解决mtop请求体附带emoji导致验签失败的问题
  // http://gitlab.alibaba-inc.com/mtb/lib-mtop-middlewares/tree/master/middlewares/emoji
  enableEmoji();

  /**
   * 获取mtop配置
   */
  (window as any).currentConfigRetryCount = 0;
  const mtopConfigUrl = get(window, 'getMtopConfigUrl') || '/apps/mtop/metadata';
  const getMtopConfigRequest = get(window, 'getMtopConfigRequest') || {};
  const mtopConfigFallback = async () => {
    if (!(window as any).mtopMetaDataPromise) {
      (window as any).mtopMetaDataPromise = request({
        url: mtopConfigUrl,
        type: 'json',
        method: 'GET',
        ...getMtopConfigRequest
      }).then((res) => {
        if (res && res.success && res.module) {
          (window as any).mtopConfig = res.module;
        }
      })
    }
    try {
      await (window as any).mtopMetaDataPromise;
    } catch (ex) { console.warn(ex) }
    mtopConfig = get(window, 'mtopConfig');
    if (!mtopConfig) {
      if ((window as any).currentConfigRetryCount < maxRetryCount) {
        (window as any).currentConfigRetryCount += 1;
        (window as any).mtopMetaDataPromise = null;
        await mtopConfigFallback();
      }
    }
  }
  if (!mtopConfig) {
    await mtopConfigFallback();
  }

  /**
   * 配置mtop
   */
  if (mtop && mtopConfig) {
    mtop.config.prefix = mtopConfig.prefix;
    mtop.config.subDomain = mtopConfig.subDomain;
    mtop.config.mainDomain = mtopConfig.mainDomain;
    if (mtopConfig.pageDomain) {
      mtop.config.pageDomain = mtopConfig.pageDomain;
    }
  }
}

const getParamStr = (data, paramStringify = true) => {
  return Object.entries(data)
    .filter(([, val]) => val !== undefined)
    .reduce((obj, [key, returnValue]) => {
      if (returnValue instanceof Object && paramStringify) {
        returnValue = JSON.stringify(returnValue);
      }
      return { ...obj, [key]: returnValue };
    }, {});
}

const getOptions = (options) => {
  const headers = {
    ...(options.headers || {}),
    ...(mtopConfig.headers || {})
  }

  if (options.data) {
    options.data = getParamStr(options.data, options.paramStringify);
  }

  const defaultOptions: any = {
    v: '1.0',
    appKey: mtopConfig ? mtopConfig.appKey : '0',
    timeout: 30 * 1000,
    H5Request: true, // 默认为true 减少容器和浏览器的差异
  };

  const { H5Request, ...otherOptions } = options;

  if (H5Request === false) {
    // 手动声明为false的我们处理成undefined 不然mtop底层库识别上会有问题
    delete defaultOptions.H5Request;
    // 默认为0 和mtop底层库逻辑保持一致
    defaultOptions.ecode = 0;
  }

  let finalOptions = {
    ...defaultOptions,
    ...otherOptions,
    api: options.url,
    headers,
    type: options.method || 'GET',
    dataType: options.type || 'json',
    valueType: 'original',
    // ecode 客户端请求参数 请求需要登录的接口为1，反之为0。
    // ecode: 0,
    data: {
      ...(options.data || {})
    }
  };
  // 加载环境参数
  if (mtopConfig && mtopConfig.queryStringParameters) {
    try {
      finalOptions = {
        ...finalOptions,
        ...(mtopConfig.queryStringParameters || {})
      };
    } catch (ex) { console.log(ex) }
  }
  return finalOptions;
};

/**
 * 确保返回数据中的success有最高优先级
 * @param resp 请求返回数据
 * @param fallback 默认success值
 */
const getResponseSuccess = (resp, fallback) => {
  const businessSuccess = get(resp, 'data.success');
  return isEmpty(businessSuccess) ? fallback : businessSuccess;
}

/**
 *
 * @param {url, data} options
 */
const requestMtop = async (options) => {
  await initial();
  if (!options.url) {
    return Promise.resolve({
      success: false,
      error: `Request Mtop: No url in options => ${JSON.stringify(options)}`
    });
  }
  if (!mtopConfig) {
    return Promise.resolve({
      success: false,
      error: `Request Mtop: Fetch mtop config failed, please try again.`
    });
  }
  options._euPrefix && (mtop.config.prefix = options._euPrefix);
  return new Promise(resovle => {
    const timestart = new Date().getTime();
    const submitOption = getOptions(options);
    mtop
      .request(submitOption)
      .then((res) => {
        let status = 'Y';
        let ret = res.ret;
        if (ret instanceof Array) {
          ret = ret.join(',');
        }
        if (ret.includes('SUCCESS')) {
          resovle({
            ...res,
            ...res.data,
            success: getResponseSuccess(res, true)
          });
        } else {
          resovle({
            ...res,
            error: ret,
            ...res.data,
            success: getResponseSuccess(res, false),
          });
          status = 'N';
        }
        sendLog(timestart, submitOption.api, status, ret);
      })
      .catch((res) => {
        const resError = res && res.data ? res.data.error : '';
        const resRet = res && res.ret && res.ret.length ? res.ret.join(',') : '';
        const useError = resError || resRet || 'mtop-error';
        resovle({
          ...res,
          error: useError,
          ...(res.data || {}),
          success: getResponseSuccess(res, false),
        });
        sendLog(timestart, submitOption.api, 'N', useError);
      });
  });
}

export default requestMtop;

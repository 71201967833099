const flatObj = targetObj => {
  const flatResult = {};
  const flatRecusive = (obj, prefix = '') => {
    if (obj === undefined || obj === null) {
      return;
    } else if (Array.isArray(obj)) {
      flatResult[`${prefix}`] = obj;
    } else if (typeof obj === 'object') {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'object') {
          flatRecusive(value, `${prefix}${key}.`);
        } else {
          flatResult[`${prefix}${key}`] = value;
        }
      });
    }
  };
  flatRecusive(targetObj);
  return flatResult;
};

export default flatObj;

// src/utils/index.ts
export * from "./seller-basics";
var arrayToMap = (list = [], fieldName = "value") => {
  return list.reduce((prev, item) => {
    prev[item[fieldName]] = item;
    return prev;
  }, {});
};
function getUrlParam(variable) {
  let reg = new RegExp("(^|&)" + variable + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}
export {
  arrayToMap,
  getUrlParam
};

import { DynamicHtml } from '@ali/merlion-ui';
import { manualSpmReport } from '@alife/workstation-utils';
import { Button } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CONST_SWITCH_BUTTON, NATIONAL_CODE, type EnumOtpChannel } from '../constants';
import * as locale from '../locale/common';
import { registerCacheModel } from '../model';
import { default as API } from '../service/mobile';
import { getVerifyCode, logAfterSubmit, logBeforeSubmit, loginViaMSite, signUpViaMsiteLogin } from '../untils/index';
import VerificationCodeInput from './code-input';
import './index.scss';
declare global {
  interface Window {
    timer: any;
  }
}


export default function Code() {
  const params = new URLSearchParams(useLocation().search);
  const code = params.get('code') ?? '';
  const phone = params.get('phone') ?? '';
  const type = (params.get('type') as EnumOtpChannel) ?? '';
  const codeLength = Number(/\d/.test(String(params.get('len'))) ? params.get('len') : '6');

  const [codeValue, setCodeValue] = useState<string>('');
  const [number, setNumber] = useState(0);
  const [lenSix, setLenSix] = useState(false);
  const [isTrue, setIsTrue] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleGoQuestionnaire = async (codeValue) => {
    if (codeValue.length < codeLength) {
      setLenSix(true);
      return;
    }
    manualSpmReport({spmc: 'd_m_code', spmd: 'd_next',  type: 'CLK'})
    setLoading(true);
    const data = {
      phoneNumber: phone,
      countryCallingCode: code,
      code: codeValue,
    };

    API.verificationCode(data, registerCacheModel.state.passwordFormData)
      .then((res) => {
        const { pathname } = window.location;
        const flag = pathname.includes('register');
        const { success, actionType, actionParameters } = res;
        // const { actionType, actionParameters } = data;
        if (success) {
          //  const {actionType, actionParameters} = data
          if (actionType === 'COMPLETE_PROFILE') {
            const { loginUserDTO } = res;
            if (flag) {
              // 注册入口
              logAfterSubmit(loginUserDTO?.userId, 'msite-register');
            } else {
              // 登录入口
              signUpViaMsiteLogin(loginUserDTO?.userId, 'msite');
            }
            const { token } = actionParameters;
            localStorage.setItem('token', token);
            history.push('/password');
          } else {
            loginViaMSite();
            const { loginJumpUrl } = actionParameters;
            if (loginJumpUrl !== '/') {
              location.href = loginJumpUrl;
            } else {
              location.href = `${window.location.origin}/apps/home/new`;
            }
          }
        } else {
          if (actionType === 'REDIRECT') {
            // 二次验证
            const { redirectUrl } = actionParameters;
            location.href = redirectUrl;
          } else if (actionType === 'COMPLETE_PROFILE') {
            const { loginUserDTO } = res;
            if (flag) {
              // 注册入口
              logAfterSubmit(loginUserDTO?.userId, 'msite-register');
            } else {
              // 登录入口
              signUpViaMsiteLogin(loginUserDTO?.userId, 'msite');
            }
            const { token } = actionParameters;
            localStorage.setItem('token', token);
            window.location.href = '/apps/home/new';
          } else {
            setIsTrue(res?.errorCode?.displayMessage || res?.error || 'error');
          }
        }
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        setIsTrue(res?.errorCode?.displayMessage || res?.error);
      });
  };

  const handleCode = () => {
    getVerifyCode(type, phone, history, true);
    count(59);
  };

  const count = (num) => {
    if (num <= 60 && num > 0) {
      window.timer = setTimeout(() => {
        localStorage.setItem('number', JSON.stringify(num - 1));
        setNumber(num - 1);
      }, 1000);
    }
  };

  // 组件卸载时清除计时器
  useEffect(() => {
    logBeforeSubmit('register');
    manualSpmReport({spmc: 'd_m_code', spmd: 'd_next',  type: 'EXP'})
    return () => {
      clearTimeout(window.timer);
      localStorage.removeItem('number');
    };
  }, []);

  useEffect(() => {
    const value = localStorage.getItem('number') ?? 0;
    if (value && Number(value) > 0 && Number(value) <= 60) {
      count(Number(value));
    } else if (number < 60 && number > 0) {
      count(number);
    }
  }, [number]);

  return (
    <div data-spm="d_m_code" className="code-page aplus-module-auto-exp">
      <div className="codeTip">{locale.enter_code}</div>
      <div className="codeNumberTip">
        <DynamicHtml
          html={locale.otp_code}
          renderers={{
            digit: () => <span>{codeLength}</span>,
            account: () => (
              <span className="sms-code-container-tips-number">
                {`+${NATIONAL_CODE} ${phone}`}
              </span>
            ),
            type: () => <span className="sms-code-container-tips-number">{CONST_SWITCH_BUTTON[type]?.text}</span>,
          }}
        />
      </div>
      <div className="inputBox">
        <VerificationCodeInput length={codeLength} value={codeValue} onChange={(e) => {
          setCodeValue(e)
          if(e?.length === codeLength) {
            handleGoQuestionnaire(e)
          }
        }} />
      </div>
      {lenSix && <span style={{ color: 'red', fontSize: '15px' }}>{locale.please_fill_in}</span>}
      {isTrue && (
        <span style={{ color: 'red', fontSize: '15px', width: '100%', wordBreak: 'break-word' }}>{isTrue}</span>
      )}
      <div className="countBox">
        <span className="notReceive">{locale.not_receive_code}</span>
        {number < 1 ? (
          <Button
            fill="none"
            data-spm="d_resend"
            className={`${'button'} aplus-auto-clk aplus-auto-exp`}
            onClick={handleCode}
          >
            {locale.resend}
          </Button>
        ) : (
          <Button fill="none" className={`${'button'}`}>{`${locale.resend}(${number}s)`}</Button>
        )}
      </div>
    </div>
  );
}

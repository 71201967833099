import labCore from '@ali/lab-core-web';
import goldlog from '@alife/intl-util/lib/side-effects/goldlog';
import { ASCTools, AxiosRequestHeaders } from '@alife/workstation-utils';
import { Toast } from 'antd-mobile';
import classname from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { EnumOtpChannel, NATIONAL_CODE } from '../constants';
import { ACTION_TYPE } from '../interface';
import { registerCacheModel } from '../model';
import { default as API, EnumType } from '../service/mobile';
import arms from './arms';
import { REPORT_TYPE, logger } from './logger';

declare global {
  interface Window {
    dataLayer: any;
    fbq: any;
    AWSC: any;
    __locale__: any;
  }
}

export const getComputedClassName = (clazz: Record<string, boolean> = {}) =>
  classname({ ...clazz, 'aplus-auto-exp': true, 'aplus-auto-clk': true });

export const delay = (time) => new Promise((resolve) => setTimeout(() => resolve(true), time * 1000));

const suffixMap = {
  sg: 'sg',
  my: 'com.my',
  th: 'co.th',
  vn: 'vn',
  id: 'co.id',
  ph: 'com.ph',
};

export const getCountryHost = (country) => {
  const { hostname } = location;
  const res = hostname.match(/^sellercenter(-staging)?\.lazada\.(.+)/);
  if (res) {
    return hostname.replace(res[2], suffixMap[country]);
  } else {
    const cnRes = hostname.match(/^sellercenter-(\w+)(-staging)?\.lazada-seller\.cn$/);
    if (cnRes) {
      return hostname.replace(cnRes[1], country);
    }
  }
  return hostname;
};

export const getCountry = () => {
  const { hostname } = location;
  const res = hostname.match(/^sellercenter(-staging)?\.lazada\.(.+)/);
  if (res) {
    return {
      [suffixMap.sg]: 'Singapore',
      [suffixMap.my]: 'Malaysia',
      [suffixMap.th]: 'Thailand',
      [suffixMap.vn]: 'Vietnam',
      [suffixMap.id]: 'Indonesia',
      [suffixMap.ph]: 'Philippines',
    }[res[2]];
  } else {
    const cnRes = hostname.match(/^sellercenter-(\w+)(-staging)?\.lazada-seller\.cn$/);
    if (cnRes && cnRes[1]) {
      return {
        sg: 'Singapore',
        my: 'Malaysia',
        th: 'Thailand',
        vn: 'Vietnam',
        id: 'Indonesia',
        ph: 'Philippines',
      }[cnRes[1]];
    }
  }
  return 'Singapore';
};

const getScriptCaches = {};
export async function getScript(url) {
  if (!getScriptCaches[url]) {
    getScriptCaches[url] = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = () => {
        resolve('');
      };
      script.onerror = () => {
        reject();
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
  return getScriptCaches[url];
}

export async function getUmidToken() {
  const awscAPI = 'generate_awsc';
  const awscApiBegin = Date.now();
  let awscReported = false;
  return getScript('//g.alicdn.com/AWSC/AWSC/awsc.js')
    .then(() => {
      window.uabModule = undefined;
      window.AWSC.use('uab', (state, uab) => {
        if (state === 'loaded') {
          window.uabModule = uab;
        }
      });
      window.umidToken = `defaultToken1_um_not_loaded@@${location.href}@@${new Date().getTime()}`;
      window.AWSC.use('um', (state, umModule) => {
        if (state === 'loaded') {
          window.umidToken = `defaultToken2_init_callback_not_called@@${location.href}@@${new Date().getTime()}`;
          umModule.init(
            {
              appName: 'lzd-sc-seller-portal',
              serviceLocation: 'lazada',
            },
            (initState, result) => {
              if (initState === 'success') {
                // this callback was called 2 times
                window.umidToken = result.tn;
                if (!awscReported) {
                  if (result.tn) {
                    arms.api(awscAPI, true, Date.now() - awscApiBegin, 200, 'success');
                  } else {
                    arms.api(awscAPI, false, Date.now() - awscApiBegin, 'empty_token', 'empty_token');
                  }
                  awscReported = true;
                }
              } else {
                window.umidToken = `defaultToken4_init_failed with ${initState}@@${
                  location.href
                }${+'@@'}${new Date().getTime()}`;
                arms.api(awscAPI, false, Date.now() - awscApiBegin, 'init_failed', 'init_failed');
              }
            },
          );
        } else {
          window.umidToken = `defaultToken3_load_failed with ${state}@@${location.href}${+'@@'}${new Date().getTime()}`;
          arms.api(awscAPI, false, Date.now() - awscApiBegin, 'load_failed', 'load_failed');
        }
      });
    })
    .catch((error) => {
      arms.api(awscAPI, false, Date.now() - awscApiBegin, 'error', error ? error.message : 'load_awsc_file_failed');
    });
}

export function getHeader() {
  const headers = {} as AxiosRequestHeaders;
  if (window.uabModule && window.uabModule.getUA) {
    headers['ua'] = window.uabModule.getUA();
  }
  if (
    !window.umidToken ||
    window.umidToken.indexOf('defaultToken1') !== -1 ||
    window.umidToken.indexOf('defaultToken2') !== -1
  ) {
    delay(1);
  }
  if (window.umidToken) {
    headers['x-umidToken'] = window.umidToken;
  }
  return headers;
}

export function getCurrentLang() {
  return window.__locale__ ?? 'en_US';
}

export const getABTest = async ({ expId }, appCode?) => {
  return new Promise((resolve, reject) => {
    labCore
      .getResult({
        appCode: appCode || 'lazada-lab-logistic',
        expId: getExpIdByCountry(expId),
        lazy: false,
      })
      .then((res) => {
        const { mappings = null, releaseId = '', variationId = '' } = res;
        resolve(mappings);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getSellerABTest = async ({ expId }, appCode?, factor?) => {
  // 当seller登录时以sellerID为分桶因子
  return new Promise((resolve, reject) => {
    labCore
      .getResult({
        appCode: appCode || 'lazada-lab-logistic',
        expId: getExpIdByCountry(expId),
        lazy: false,
        factor,
      })
      .then((res) => {
        const { mappings = null, releaseId = '', variationId = '' } = res;
        resolve(mappings);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFeedBackCountry = () => {
  const { hostname } = location;
  const res = hostname.match(/^sellercenter(-staging)?\.lazada\.(.+)/);
  if (res) {
    return {
      [suffixMap.sg]: 'sg',
      [suffixMap.my]: 'my',
      [suffixMap.th]: 'th',
      [suffixMap.vn]: 'vn',
      [suffixMap.id]: 'id',
      [suffixMap.ph]: 'ph',
    }[res[2]];
  } else {
    const cnRes = hostname.match(/^sellercenter-(\w+)(-staging)?\.lazada-seller\.cn$/);
    if (cnRes) {
      return cnRes[1];
    }
  }
  return 'sg';
};

export const getExpIdByCountry = (expId) => {
  return typeof expId === 'string' ? expId : expId[getFeedBackCountry()];
};

export const venturePatterns = {
  sg: [/^([8|9])\d{7}$/],
  my: [/^(0)(1-9)\d{8}$/, /^(1-9)\d{8}$/],
  hk: [/^([6|9])\d{7}$/],
  id: [/^([8])\d{8,12}$/],
  ph: [/^([9])\d{9}$/],
  th: [/^(0)\d{9}$/],
  jp: [/^(080|090)\d{8}$/],
  vn: [/^(09)\d{8}$/],
};
export const validNumbersByVenture = (venture, value) => {
  const patterns = venturePatterns[venture.toLocaleLowerCase()];
  let result = 0;
  patterns.map((item) => {
    result += Number(item.test(value));
  });
  return result >= 1;
};

export const logBeforeSubmit = async (channel) => {
  goldlog({
    key: '/onboarding.onboarding_signup.msite_sign_up',
    type: 'CLK',
    method: 'POST',
    data: {
      type: 'register-success-before',
      channel,
    },
  });
  logger && logger.adsReport(REPORT_TYPE.FbSubmitApplication);
};

export const logAfterSubmit = async (sellerId, channel) => {
  sellerId &&
    goldlog({
      key: '/onboarding.onboarding_signup.msite_sign_up_success',
      type: 'CLK',
      method: 'POST',
      data: {
        type: 'register-success',
        sellerId,
        channel,
      },
    });
  logger && logger.adsReport(REPORT_TYPE.FbCompleteRegistration);
};

export const buyerlogAfterSubmit = async (sellerId, channel) => {
  sellerId &&
    goldlog({
      key: '/onboarding.onboarding_signup.msite_third_sign_up_success',
      type: 'CLK',
      method: 'POST',
      data: {
        type: 'third_register-success',
        sellerId,
        channel,
      },
    });
  logger && logger.adsReport(REPORT_TYPE.FbCompleteRegistration);
};

export const loginViaMSite = async () => {
  goldlog({
    key: '/onboarding.onboarding_signup.m_login_via_signup',
    type: 'OTHER',
    method: 'GET',
  });
};

export const signUpViaMsiteLogin = async (sellerId, channel) => {
  goldlog({
    key: '/onboarding.onb oarding_signup.m_signup_via_login',
    type: 'OTHER',
    method: 'GET',
    data: {
      type: 'register-success',
      sellerId,
      channel,
    },
  });
};

export const goldLog = ({ key, type = 'CLK', method = 'POST', data = {}, spm = '' }) => {
  const { spmA = 'a1zawe', spmB = '15023480' } = window['dadaConfig'];

  if (!!spm) data['spm'] = `${spmA}.${spmB}.${spm}`;
  const paramStr = JSON.stringify(data);
  const { goldlog_queue = [] } = window as any;

  goldlog_queue.push({
    action: 'goldlog.record',
    arguments: [key, type, paramStr],
  });
};

export const GetQueryValue = (queryName) => {
  const query = decodeURI(window.location.search.substring(1));
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == queryName) {
      return pair[1];
    }
  }
  return '';
};

export const getHashPath = () => {
  localStorage.setItem('hashpath', location.hash);
};

const url = new URL(window.location.href);

export const searchParams = url.searchParams;
export const isLazMall = searchParams.get('lazMall') === '1';
export const isCb = searchParams.get('crossBorder') === '1' || searchParams.get('basedCountry') !== null;
export const isIframe = GetQueryValue('iframe') === 'true';

export const checkPhoneNumber = (phonCheckNumber) => {
  if (!phonCheckNumber) return false;
  return isValidPhoneNumber(phonCheckNumber, ASCTools.getCountry());
};

/**
 * 获取验证码以及跳转逻辑
 * @param type opt类型
 * @param phoneNumber 手机号
 * @param history useHistory同于跳转页面
 * @param isResetCode 是否点击 reset code
 * @param params 接口参数(需要覆盖默认参数可传)
 * @returns code response
 */
export const getVerifyCode = (
  type: EnumOtpChannel | undefined,
  phoneNumber,
  history,
  isResetCode = false,
  params = {},
  riskCallback = () => {},
) => {
  const data = {
    phoneNumber: phoneNumber,
    countryCallingCode: NATIONAL_CODE,
    type: EnumType.OTP_LOGIN,
    riskInfo: '{ "ncSessionId": "", "ncSig": "", "ncToken": "" }',
    deliveryType: type,
    checkRisk: type === EnumOtpChannel.SMS ? true : undefined,
    ...params,
  };
  if (!phoneNumber) {
    const errorMsg = 'phone is null';
    Toast.show(errorMsg);
    return Promise.reject(errorMsg);
  }
  return API.getVerifyCode(data)
    .then((res) => {
      if (res && res?.data?.actionType === ACTION_TYPE.NC_AUTH) {
        history.push(`/manmachine?phone=${phoneNumber}&code=${NATIONAL_CODE}`);
      } else if (res && res?.data?.success) {
        // 重新发送验证码不需要跳转code页面
        if (isResetCode) {
          Toast.show({
            icon: 'success',
            content: 'Success',
          });
        } else {
          history.push(`/code?phone=${phoneNumber}&code=${NATIONAL_CODE}&type=${type}&len=${res?.data?.codeLength}`);
        }
        registerCacheModel.update({ phone: phoneNumber, nationalCode: NATIONAL_CODE, type });
        goldlog({
          key: '/onboarding.onboarding_signup.new_m_onboarding_sentotp_success',
          type: 'CLK',
          method: 'GET',
        });
      } else {
        if (
          (res?.data?.errorCode?.displayMessage?.indexOf('user has risk') >= 0 ||
            res?.data?.error?.indexOf('user has risk') >= 0) &&
          riskCallback
        ) {
          riskCallback?.();
        } else {
          Toast.show({
            icon: 'fail',
            content: res?.data?.errorCode?.displayMessage || res?.data?.error,
          });
        }
      }
      return res;
    })
    .catch((res) => {
      Toast.show({
        icon: 'fail',
        content: res?.data?.errorCode?.displayMessage || res?.error,
      });
      return Promise.reject(res);
    });
};

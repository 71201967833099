import request from './xhr';

const getParamStr = data => {
  return Object.entries(data)
    .filter(([, val]) => val !== undefined)
    .reduce((obj, [key, returnValue]) => {
      if (returnValue instanceof Object) {
        returnValue = JSON.stringify(returnValue);
      }
      return { ...obj, [key]: returnValue };
    }, {});
}

const getOptions = (options) => {
  if (options.data) {
    options.data = getParamStr(options.data);
  }

  const finalOptions = {
    method: 'GET',
    type: 'json',
    ...options,
    contentType: 'application/json;charset=utf-8',
    data: {
      data: {
        ...(options.data || {})
      }
    }
  };
  return finalOptions;
};

/**
 *
 * @param {url, data} options
 */
const requestAdminGW = async (options) => {
  if (!options.url) {
    return Promise.resolve({
      success: false,
      error: `Request Admin GW: No url in options => ${JSON.stringify(options)}`
    });
  }
  return new Promise(resovle => {
    const submitOption = getOptions(options);
    request(submitOption).then(res => {
      if (res && (res as any).retSuccess) {
        resovle({
          success: true,
          ...(res as any).data
        });
      } else {
        // 判断权限
        if ((res as any).code === 403 && (res as any).debugMessage) {
          location.href = (res as any).debugMessage;
          return;
        }
        resovle({
          success: false,
          error: [(res as any).code, (res as any).message, (res as any).debugMessage].join(';'),
          ...(res as any).data
        });
      }
    }).catch((ex) => {
      resovle({
        success: false,
        error: ex.message || 'Request Error.'
      });
    });
  });
}

export default requestAdminGW;

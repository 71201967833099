/**
 * 
 * @param dataSource 
 * @param keys 
 */

export default (dataSource: any[] = [], keys) => {
  const keyArr = Array.isArray(keys) ? keys : [keys];
  return dataSource.reduce((res, row) => {
    const groupKey = keyArr.map(key => row[key]).join('-');
    return {...res, [groupKey]: [...(res[groupKey] || []), row]}
  }, {})
};

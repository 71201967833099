import PasswordCore, { EnumPasswordType } from './core';
import './index.scss';

export default function Password() {
  return (
    <div data-spm="d_m_password" className="passwordPage aplus-auto-exp">
     <PasswordCore coreType={EnumPasswordType.password} />
    </div>
  );
}

import { ASCTools } from '@alife/workstation-utils';
import { Button, Checkbox, Form, Input, Radio, Toast } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EnumOtpChannel } from '../constants';
import EmailTag from '../EmailTag';
import * as locale from '../locale/common';
import { registerCacheModel } from '../model';
import API from '../service/mobile';
import { getVerifyCode } from '../untils';
import useUserRisk from '../useUserRisk';
import './index.scss';

declare global {
  interface Window {
    lzdCommonData: any;
  }
}
export const eyeOpen = 'https://img.alicdn.com/imgextra/i4/O1CN01It9D781O0XQBPqtuR_!!6000000001643-2-tps-86-84.png';
export const eyeClose = 'https://img.alicdn.com/imgextra/i4/O1CN01H5FlWQ1XZBvpoA0oF_!!6000000002937-2-tps-84-84.png';
export const successIcon = 'https://img.alicdn.com/imgextra/i3/O1CN01bHYR741uz3Sa6yvYt_!!6000000006107-2-tps-48-48.png';
export const errorIcon = 'https://img.alicdn.com/imgextra/i1/O1CN01Bsu6lv24AoAMfimmW_!!6000000007351-2-tps-48-48.png';

export enum EnumPasswordType {
  password = 'password',
  threeWayPassword = 'three-way-password',
}

export default function PasswordCore({ coreType = EnumPasswordType.password }: { coreType: EnumPasswordType }) {
  // 链接参数
  const params = new URLSearchParams(useLocation().search);
  const phone = params.get('phone') ?? '';
  const initOtpType = (params.get('type') || EnumOtpChannel.SMS) as EnumOtpChannel;

  const reg1 = new RegExp(/[0-9]{1,}/);
  const reg2 = new RegExp(/[a-zA-Z]{1,}/);
  const reg3 = new RegExp(/.{8,128}/);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [otpType, setOtpType] = useState(initOtpType);
  const [retypePassword, setPasswordRetype] = useState('');
  const [type, setType] = useState('1');
  const [passwordEye, setPasswordEye] = useState<any>(true);
  const [retyprPasswordEye, setRetypePasswordEye] = useState<any>(true);
  const [isAgentSupport, setAgentSupport] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const country = ASCTools.getCountry();

  const isPasswordType = EnumPasswordType.password === coreType;
  const isThreeWayPassword = EnumPasswordType.threeWayPassword === coreType;

  // EnumPasswordType.threeWayPassword 的方法
  const handleData = () => {
    const buyerEmail = localStorage.getItem('email') ?? '';
    setEmail(buyerEmail);
  };

  const {Dialog, create} = useUserRisk((otpType) => {
    getVerifyCode(otpType, phone, history);
  })

  const handleSubmit = async (values: any) => {
    if (values) {
      const data = {
        password: values.password1,
        token: localStorage.getItem('token') ?? '',
        sellerType: values.type,
        platform: 'H5',
        email: values.email,
        onboardParamsJson: JSON.stringify({ needAgentSupport: isAgentSupport }),
      };
      if (values.shopName) {
        data['shopName'] = values.shopName;
      }
      const res = await API.validateEmailPassword({
        email: data?.email,
        password: data?.password,
        repeatPassword: values?.password2,
      });
      // 保存设置密码表单的数据
      registerCacheModel.update({
        passwordFormData: {
          sellerType: data?.sellerType,
          email: data?.email,
          shopName: values?.shopName,
          password: data?.password,
          repeatPassword: values?.password2,
          agentSupport: {
            needAgentSupport: isAgentSupport,
          },
        },
      });
      if (res?.success) {
        // 获取验证码 & 跳转code页面
        getVerifyCode(otpType, phone, history, false, {}, create);
      } else {
        Toast.show(res.message);
      }
    } else {
      Toast.show({
        icon: 'fail',
        content: locale.please_fill_in,
      });
    }
  };

  const handlePassword = (value, type) => {
    if (type === 1) {
      setPassword(value.trim());
    } else {
      setPasswordRetype(value.trim());
    }
  };

  const renderEye = type => {
    return (
      <img
        onClick={() => {
          if (type === 1) {
            setPasswordEye(!passwordEye);
          } else {
            setRetypePasswordEye(!retyprPasswordEye);
          }
        }}
        className="eye"
        src={type === 1 ? (!passwordEye ? eyeOpen : eyeClose) : !retyprPasswordEye ? eyeOpen : eyeClose}
        alt=""
      />
    );
  };

  useEffect(() => {
    const { sellerType, email, shopName, agentSupport } = (registerCacheModel.state?.passwordFormData || {}) as any;
    let formData: { type?: string; email?: string; shopName?: string } = {};
    sellerType && (formData.type = sellerType);
    email && (formData.email = email);
    shopName && (formData.shopName = shopName);
    agentSupport?.needAgentSupport && setAgentSupport(true);
    form.setFieldsValue(formData);

    // EnumPasswordType.threeWayPassword 的逻辑
    isThreeWayPassword && handleData();
  }, []);

  // threeWayPassword 需要隐藏邮箱
  const isHideEmail = isThreeWayPassword && !email;

  const hasAgentSupport = ['TH', 'VN'].includes(country);

  useEffect(() => {
    setOtpType(initOtpType);
  }, []);

  return (
    <>
      <div className="titleOne">{locale.almost_there_tip}</div>
      <div className="titleTwo">{locale.set_password_keep_safe}</div>
      <div className="formBox" style={{ padding: 0 }}>
        <Form
          form={form}
          onFinish={handleSubmit}
          onValuesChange={(...[, values]) =>
            registerCacheModel.update({
              passwordFormData: {
                ...values,
                ...(hasAgentSupport
                  ? {
                      agentSupport: {
                        needAgentSupport: isAgentSupport,
                      },
                    }
                  : {}),
              },
            })
          }
          footer={
            <Button
              color="primary"
              data-spm="d_next"
              type="submit"
              className={`${'formBtn'} aplus-auto-clk aplus-auto-exp`}
            >
              {locale.next}
            </Button>
          }
        >
          {!['MY', 'ID'].includes(country) && (
            <>
              <Form.Item
                name="type"
                initialValue="1"
                rules={[{ required: true, message: locale.type_required }]}
                className="form-item-seller-type"
              >
                <Radio.Group
                  onChange={(val: any) => {
                    setType(val);
                  }}
                >
                  <Radio data-spm="d_sellertype" className={`aplus-auto-clk aplus-auto-exp radioBtn`} value="0">
                    {locale.business}
                  </Radio>
                  <Radio data-spm="d_sellertype" className={`aplus-auto-clk aplus-auto-exp radioBtn`} value="1">
                    {locale.individual}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <div className="identity">
                <img
                  className="iconImg"
                  src="https://img.alicdn.com/imgextra/i2/O1CN01o5zerj1jRCPR2AHPk_!!6000000004544-2-tps-48-48.png"
                  alt=""
                />
                <div className="identitytip">{type === '0' ? locale.business_tip : locale.individual_tip}</div>
              </div>
            </>
          )}
          {country !== 'TH' && (
            <Form.Item
              name="shopName"
              rules={[
                {
                  required: true,
                  message: locale.shopName_required,
                },
              ]}
            >
              <Input
                data-spm="d_shopname"
                className={`${'password'} aplus-auto-clk aplus-auto-exp`}
                placeholder={locale.shopName}
              />
            </Form.Item>
          )}
          {/* threeWayPassword 特定条件隐藏邮箱 */}
          {isHideEmail ? null : (
            <Form.Item name="email" rules={[{ required: true, message: locale.email_required }]}>
              <Input
                type="email"
                data-spm="d_email"
                className={`${'password'} aplus-auto-clk aplus-auto-exp`}
                placeholder={locale.email}
              />
            </Form.Item>
          )}
          {/* password 才有email标签 */}
          {isPasswordType ? (
            <EmailTag
              onClickTag={item => {
                const email = form.getFieldValue('email') || '';
                form.setFieldsValue({ email: `${email}${item}` });
              }}
            />
          ) : null}
          <div className="passwordTwo">
            <Form.Item
              name="password1"
              rules={[
                { required: true, message: locale.password_required },
                () => ({
                  validator(_, value) {
                    return new Promise((resolve, reject) => {
                      const number = reg1.test(value);
                      const letter = reg2.test(value);
                      const pass = reg3.test(value);
                      if (!value) {
                        resolve('');
                      } else if (value.length < 8) {
                        reject('');
                      } else if (!number && letter) {
                        reject('');
                      } else if (number && !letter) {
                        reject('');
                      } else if (pass) {
                        resolve('');
                      } else {
                        reject('');
                      }
                    });
                  },
                }),
              ]}
            >
              <Input
                type={`${passwordEye ? 'password' : ''}`}
                data-spm="d_password"
                className={`${'password'} aplus-auto-clk aplus-auto-exp`}
                placeholder={locale.new_password}
                onChange={(value: string) => handlePassword(value, 1)}
              />
            </Form.Item>
            {renderEye(1)}
          </div>
          {password ? (
            <div className="passwordError">
              {[reg1, reg2, reg3]
                .map(item => {
                  return item.test(password);
                })
                .map((en, index) => {
                  return (
                    <div key={index} className={`${en ? 'successtip' : 'errortip'}`}>
                      <img className="iconImg" src={`${en ? successIcon : errorIcon}`} alt="" />
                      {index === 0
                        ? `${locale.at_least_one_number}`
                        : index === 1
                        ? `${locale.at_least_one_letter}`
                        : `${locale.at_least_eight_characters}`}
                    </div>
                  );
                })}
            </div>
          ) : null}
          <div className="passwordTwo">
            <Form.Item
              name="password2"
              rules={[
                { required: true, message: locale.password_required },
                () => ({
                  validator(_, value) {
                    return new Promise((resolve, reject) => {
                      if (!value) {
                        resolve('');
                      } else if (value === password) {
                        resolve('');
                      } else {
                        reject('');
                      }
                    });
                  },
                }),
              ]}
            >
              <Input
                data-spm="d_retype_password"
                className={`${'password'} aplus-auto-clk aplus-auto-exp`}
                type={`${retyprPasswordEye ? 'password' : ''}`}
                placeholder={locale.retype_password}
                onChange={(value: string) => handlePassword(value, 2)}
              />
            </Form.Item>
            {renderEye(2)}
          </div>
          {retypePassword && (
            <div className={retypePassword === password ? 'successtip' : 'errortip'}>
              <img className="iconImg" src={retypePassword === password ? successIcon : errorIcon} alt="" />
              {locale.password_not_same}
            </div>
          )}
          {hasAgentSupport && (
            <Checkbox
              checked={isAgentSupport}
              onChange={checked => setAgentSupport(checked)}
              className="agent-support-text"
            >
              <span dangerouslySetInnerHTML={{ __html: locale.agent_support_text }} />
            </Checkbox>
          )}
        </Form>
      </div>
      <Dialog />
    </>
  );
}

import { get } from '../pure-functions';

declare const window: {
  define: any;
};

const loadscript = src => {
  if (!src) {
    return Promise.resolve();
  }
  const loadPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
  });

  const promise = get(window, 'define.promise') || Promise.resolve();

  // 避免多个AMDLoader同时加载
  // tslint:disable-next-line:no-unused-expression
  !window.define && (window.define = {});
  window.define.promise = promise.then(() => loadPromise);

  return loadPromise;
};

export default loadscript;

interface URLLike {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  readonly origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
}

const checkEntry = ([key, value]) => !!key && !!value;

const decodeValue = (value) => { try { return decodeURIComponent(value) } catch (e) { return value } };

const getSearchObj = (url: URLLike = window.location) => url.search
  .slice(1)
  .split('&')
  .map(pair => pair.split('='))
  .filter(checkEntry)
  .reduce((res, [key, value]) => ({ ...res, [key]: decodeValue(value) }), {});

const getUrlPath = (url: URLLike | string = window.location) => {
  const localUrl = typeof url === 'string' ? new URL(url, window.location.href) : url;
  return localUrl.origin + localUrl.pathname;
};

const getSearchStr = (data: object = {}) => Object.entries(data)
  .filter(checkEntry)
  .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
  .join('&')

export default {
  getSearchObj,
  getSearchStr,
  getUrlPath
};



export const stringifyParams = dataObj =>
  Object.keys(dataObj)
    .filter(key => dataObj[key])
    .map(key => `${key}=${dataObj[key]}`)
    .join('&');

function record(...args) {
  const anyWindow: any = window;
  const goldlog =
    anyWindow.goldlog && anyWindow.goldlog.record ?
      anyWindow.goldlog.record :
      console.error;
  return goldlog(...args);
}

export default ({ key, type = 'CLK', method = 'GET', data = {} }) => {
  const paramStr = stringifyParams(data);
  record(key, type, paramStr, method);
};

import { Caller, Platform } from '@alife/workstation-utils';
import { Button } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useSpmD } from '../../../utils';
import { NATIONAL_CODE } from '../constants';
import { type IData } from '../Create/index';
import '../Create/index.scss';
import * as locale from '../locale/common';

export default function Create() {
  const [data, setData] = useState<IData>({});

  const handleData = () => {
    const params: any = new URLSearchParams(location.hash.split('?')[1]);
    const dataUrl = {
      phone: params.get('phone') ?? '',
      email: params.get('email') ?? '',
      shopName: params.get('shopName') ?? '',
      shopLogo: params.get('shopLogo') ?? '',
    };
    if (dataUrl) {
      setData(dataUrl);
    }
  };

  const handleToSellerApp = () => {
    // 跳下载
    const handleSeller = Caller.getInstance({ platform: Platform.SELLER });
    handleSeller.download();
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <div className="bindPage" data-spm="m_bind_page">
      <div className="createTitle">{locale.Login}</div>
      <div className="avatarBox">
        <img
          className="avatar"
          src="https://img.alicdn.com/imgextra/i1/O1CN01IwEuI51HV2VSe78k1_!!6000000000762-2-tps-120-120.png"
          alt=""
        />
        <span className="span">{data?.shopName}</span>
      </div>
      <div className="personalInformation">
        <div className="title">{locale.Use_the_following_to_connect_to_the_shop}</div>
        {data?.phone && (
          <div className="information">
            <img
              src="https://img.alicdn.com/imgextra/i4/O1CN01Ygs0rw1KudUIUhrB2_!!6000000001224-55-tps-30-30.svg"
              alt=""
            />
            <span className="phone">
              (+{NATIONAL_CODE}) {data?.phone ?? ''}
            </span>
          </div>
        )}
        {data?.email && (
          <div className="information">
            <img
              className="emailImg"
              src="https://img.alicdn.com/imgextra/i3/O1CN01ZOc98r26olyYD2eRK_!!6000000007709-55-tps-30-28.svg"
              alt=""
            />
            <span className="email">{data?.email}</span>
          </div>
        )}
      </div>
      <div className="bottomBox">
        <Button {...useSpmD('d_bind_account')} onClick={handleToSellerApp} color="primary">
          {locale.next}
        </Button>
      </div>
    </div>
  );
}

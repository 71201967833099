import { EnumOtpType } from '@/constants';
import goldlog from '@alife/intl-util/lib/side-effects/goldlog';
import { ASCTools, When } from '@alife/workstation-utils';
import { useBoolean, useRequest, useSetState } from 'ahooks';
import { Button, Form, Input, Loading, Toast } from 'antd-mobile';
import classNames from 'classnames';
import type React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDialog } from '../../../hooks';
import { CONST_SWITCH_BUTTON, EnumOtpChannel } from '../constants';
import { ventureCodes } from '../constants/otp';
import * as locale from '../locale/common';
import * as API from '../service/mobile';
import { EnumType, getChannelList } from '../service/mobile';
import { checkPhoneNumber } from '../untils';
import './index.scss';

const country = ASCTools.getCountry();
const OTP: React.FC = () => {
  const urlParams = new URLSearchParams(useLocation().search);
  const phone = urlParams.get('phone');

  const [form] = Form.useForm();
  const phoneNumber = form.getFieldValue('number');
  const history = useHistory();
  const [isError, { setFalse, setTrue }] = useBoolean(false);
  // verifyMessage：接口错误信息，abbreviation：国家区号
  const [state, setState] = useSetState({
    verifyMessage: '',
    abbreviation: ventureCodes[country].nationalCode,
    current: EnumOtpChannel.WHATSAPP,
  });
  const { verifyMessage, abbreviation, current } = state;

  const { data: otpChannelList = [], loading: otpLoading } = useRequest(getChannelList, {
    defaultParams: [EnumOtpType.login],
    onError: (err: any) => {
      Toast.show({
        icon: 'fail',
        content: err?.error,
      });
    },
  });

  const { run, loading } = useRequest(API.default.getVerifyCode, {
    manual: true,
    onSuccess: (result) => {
      if (result && result?.data?.actionType === 'NC_AUTH') {
        history.push(`/manmachine?phone=${phoneNumber}&code=${abbreviation}`);
        setFalse();
      } else if (result && result?.data?.success) {
        history.push(`/code?phone=${phoneNumber}&code=${abbreviation}&type=${current}&len=${result?.data?.codeLength}`);
        goldlog({
          key: '/onboarding.onboarding_signup.new_m_onboarding_sentotp_success',
          type: 'CLK',
          method: 'GET',
        });
        setFalse();
      } else {
        if (
          result?.data?.errorCode?.displayMessage?.indexOf('user has risk') >= 0 ||
          result?.data?.error?.indexOf('user has risk') >= 0
        ) {
          create();
        } else {
          setState({ verifyMessage: result?.data?.errorCode?.displayMessage || result?.data?.error });
          setTrue();
        }
      }
    },
   
  });

  const [Dialog, { create, close }] = useDialog({
    title: locale.verifyVoiceCallTitle,
    content: (
      <div className="voice-call-content">
        <div className="voice-call-text">{locale.verifyVoiceCallText}</div>
        <div
          className="voice-call-button"
          onClick={() => {
            setState({ current: EnumOtpChannel.VOICE });
            sendCode(EnumOtpChannel.VOICE);
          }}
        >
          {locale.callNow}
        </div>
        <div className="voice-call-button" onClick={() => close()}>
          {locale.callCancel}
        </div>
      </div>
    ),
  });

  useEffect(() => {
    form.setFieldsValue({ number: phone });
    phone && form.validateFields(['number']);

    return () => {
      localStorage.setItem('number', '60');
    };
  }, []);

  const sendCode = async (type: EnumOtpChannel) => {
    const res = await form.validateFields(['number']);
    if (res) {
      run({
        phoneNumber: res.number,
        countryCallingCode: `${abbreviation}`,
        type: EnumType.OTP_LOGIN,
        riskInfo: '',
        deliveryType: type,
        checkRisk: type === EnumOtpChannel.SMS ? true : undefined,
      });
    }
  };

  const loginWithPassword = () => {
    history.push('/login');
  };

  return (
    <div data-spm="d_m_otp" className="channel-content aplus-auto-exp">
      <div className="channel-header">
        <div className="channel-title">
          <span className="phone-number">{locale.LoginWithOTP}</span>
          <a className="code-tips" href={`/apps/register/index${location.search}`}>
            {locale.SignUp}
          </a>
        </div>
        <div className="phone-box">
          <Form form={form}>
            <div className="register-phone">
              <Form.Item
                name="number"
                required
                rules={[
                  {
                    required: true,
                    message: locale.mobile_required_tip,
                  },
                  () => ({
                    validator(_, value) {
                      return new Promise((resolve, reject) => {
                        if (!value) {
                          setTrue();
                          resolve('');
                        } else if (/[^0-9]/.test(value)) {
                          setTrue();
                          reject(locale.must_be_number);
                        } else if (!checkPhoneNumber(value)) {
                          setTrue();
                          reject(locale.msite_mobile_rule);
                        } else {
                          setFalse();
                          resolve('');
                        }
                      });
                    },
                  }),
                ]}
              >
                <Input
                  onChange={() => {
                    setState({ verifyMessage: '' });
                  }}
                  data-spm="d_otp_country"
                  className={classNames({
                    'aplus-auto-clk': true,
                    'aplus-auto-exp': true,
                    'country-input': true,
                    'phone-input': !isError,
                    'err-phone-input': isError,
                  })}
                  placeholder={locale.enter_phone_tip}
                />
              </Form.Item>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="country-show">
                <img src={ventureCodes[country].flagUrl} alt={ventureCodes[country].text} />
                <div className="country-show-abbreviation">+{ventureCodes[country].nationalCode}</div>
              </div>
            </div>
            {verifyMessage && <div className="phone-error">{verifyMessage}</div>}
            <div className="bottom-next">
              <div data-spm="d_otp_protocol_text" className="next-tips aplus-auto-clk aplus-auto-exp">
                <div
                  dangerouslySetInnerHTML={{ __html: locale.seller_center_otp_protocol_text }}
                  className="next-tips-text"
                ></div>
              </div>
              <div className="otp-buttons">
                <When condition={otpLoading}>
                  <Loading color="primary" className="LoginWithSMS" />
                </When>
                <When condition={!otpLoading}>
                  {otpChannelList?.map?.((item, index) => (
                    <Button
                      color="primary"
                      fill="outline"
                      data-spm={`d_login_switch_${item}`}
                      className={classNames({
                        'switch-btn-primary': index === 0,
                        'switch-btn-default': index !== 0 && index !== otpChannelList?.length - 1,
                        'switch-btn-text': index === otpChannelList?.length - 1,
                        'aplus-auto-clk': true,
                        'aplus-auto-exp': true,
                      })}
                      onClick={() => {
                        setState({ current: item });
                        sendCode(item);
                      }}
                      disabled={isError || loading || !phoneNumber}
                      key={item}
                    >
                      {CONST_SWITCH_BUTTON?.[item]?.buttonLocale}
                    </Button>
                  ))}
                </When>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="password-login-btn" onClick={loginWithPassword}>
        {locale.LoginWithPassword}
      </div>
      <Dialog />
    </div>
  );
};

export default OTP;

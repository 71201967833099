
const setByPath = (object, pathArr, value) => {
  const length = pathArr.length;
  const lastIndex = length - 1;
  let index = -1;
  let nested = object;
  while (nested != null && ++index < length) {
    const key = pathArr[index];
    if (index !== lastIndex) {
      const objValue = nested[key] || {};
      nested[key] = objValue;
    } else {
      nested[key] = value;
    }
    nested = nested[key];
  }
  return object;
}
const set = (object, pathInput, value) => {
  if (!object) { return object; }
  try {
    return setByPath(object, typeof pathInput === 'string' ? pathInput.split('.') : pathInput, value)
  } catch(e) {
    console.error(`Can't set ${value} by path:`, pathInput);
  }
}

export default set;

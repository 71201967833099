import { Logger, Platform, REPORT_TYPE } from '@alife/asc-login-comp/lib/Login/utils/logger';
declare global {
  interface Window {
    logger: any;
    WindVane: any;
  }
}
export const isBuyerApp =
  /Aliapp\(LA\//i.test(navigator.userAgent) ||
  /Aliapp\(DZ\//i.test(navigator.userAgent) ||
  /Aliapp\(SM\//i.test(navigator.userAgent);

const platform: Platform = isBuyerApp ? Platform.BUYER_APP : Platform.H5;
const logger = Logger.getInstance({ platform, useInsider: true });

window.logger = logger;
export { REPORT_TYPE, logger };

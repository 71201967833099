import { EnumOtpType } from '@/constants';
import { TMerchantResponse, mtopAxios } from '@alife/seller-mtop-axios';
import { encryptByRSA } from '@alife/seller-onboarding-shared';
import { request } from '@alife/workstation-utils';
import omit from 'lodash/omit';
import type { EnumOtpChannel } from '../constants';
import type { ILoginResponse } from '../interface';
import { getHeader, getUmidToken } from '../untils/index';
import { logger } from '../untils/logger';

getUmidToken();

export enum EnumType {
  OTP_LOGIN = 'OTP_LOGIN',
  COMMON_VERIFICATION = 'COMMON_VERIFICATION',
}

interface CommonParams {
  phoneNumber?: string;
  countryCallingCode?: string;
  type?: EnumType;
  tokenType?: EnumType.COMMON_VERIFICATION;
  riskInfo?: string;
  token?: string;
  platform?: string;
  deliveryType?: EnumOtpChannel;
  code?: string;
  checkRisk?: boolean;
}

type SendOtpParams = Pick<
  CommonParams,
  'phoneNumber' | 'countryCallingCode' | 'type' | 'riskInfo' | 'token' | 'platform' | 'deliveryType' | 'checkRisk'
>;

export default {
  // 注册发送验证码
  async getVerifyCode(params: SendOtpParams) {
    const headers = getHeader();
    return mtopAxios<{
      actionType: string;
      actionParameters: any
      codeLength: number
    }>({
      url: 'mtop.global.seller.verificationCode.sendSms',
      method: 'POST',
      type: 'json',
      data: { 
        ...{
          ...omit(params, 'password'),
          ...params?.password ? { encryptPassword: encryptByRSA(params?.password ?? '') } : {},
        }, 
        external: JSON.stringify(logger?.externalObj), 
        platform: logger?.platform 
      },
      headers,
      v: '1.1',
    })
    
  },
  // 三方绑定手机发送验证码
  async getThreeWayVerifyCode(params) {
    const headers = getHeader();
    return mtopAxios<{
      actionType: string
    }>({
      url: 'mtop.global.seller.verificationCode.sendSms',
      method: 'POST',
      type: 'json',
      data: { 
        ...{
          ...omit(params, 'password'),
          ...params?.password ? { encryptPassword: encryptByRSA(params?.password ?? '') } : {},
        }, 
        external: JSON.stringify(logger?.externalObj), 
        platform: logger?.platform 
      },
      headers,
    })
    
  },
 // 邮箱密码校验接口
 async validateEmailPassword (data): Promise<{ success: boolean; message: string }> {
  return mtopAxios<{isValid: boolean}>({
    url: 'mtop.global.seller.register.validateEmailPassword',
    // url: 'https://oneapi.alibaba-inc.com/mock/lxd3btbo/mtop.global.seller.register.validateemailpassword',
    method: 'POST',
    type: 'json',
    data,
  })
    .then(res => {
      return {
        success: res?.data?.isValid,
        message: res?.ret?.[0].split('::')[1] || 'System Error',
      };
    })
    .catch(ex => {
      return {
        success: false,
        message: ex?.ret?.[0].split('::')[1] || 'System Error',
      };
    });
  },
  // 注册验证验证码
  async verificationCode(params, external = {}) {
    const headers = getHeader();
    return mtopAxios<{
      actionType: string
      actionParameters: any
      loginUserDTO: any
    }>({
      url: 'mtop.global.seller.login.loginByOTP',
      // url: 'https://oneapi.alibaba-inc.com/mock/lxd3btbo/mtop.global.seller.login.loginbyotp',
      method: 'POST',
      data: { 
        ...{
          ...omit(params, 'password'),
          ...params?.password ? { encryptPassword: encryptByRSA(params?.password ?? '') } : {},
          ...params?.repeatPassword ? { repeatPassword: encryptByRSA(params?.repeatPassword ?? '') } : {},

        }, 
        external: JSON.stringify({...logger?.externalObj, ...external}), 
        platform: logger?.platform 
      },
      type: 'json',
      headers,
    }).then(({ data }) => data);
  },
  // 三方绑定验证验证码
  async ThreeVerificationCode(params) {
    const headers = getHeader();
    return mtopAxios<{
      actionType: string
      actionParameters: any
      loginUserDTO: any
    }>({
      url: 'mtop.global.seller.login.oauthBindLogin',
      method: 'POST',
      data: { 
        ...{
          ...omit(params, 'password'),
          ...params?.password ? { encryptPassword: encryptByRSA(params?.password ?? '') } : {},
        }, 
        external: JSON.stringify(logger?.externalObj), 
        platform: logger?.platform 
      },
      type: 'json',
      headers,
    }).then(({ data }) => data);
  },

  // 设置密码，shopname，邮箱等
  async setPassword(params) {
    return mtopAxios({
      url: 'mtop.lazada.merchant.onboard.seller.update',
      // api: 'mtop.global.seller.profileManage.completeProfileByRegisterToken',
      method: 'POST',
      type: 'json',
      data: { ...{
        ...omit(params, 'password'),
        ...params.password ? { encryptPassword: encryptByRSA(params?.password ?? '') } : {},
        }, 
        external: JSON.stringify(logger?.externalObj),
        platform: logger?.platform 
      },
    })
  },


  // 三方绑定接口（在端内有登陆态的情况）
  async bindBuyerAndSeller(data) {
    return mtopAxios<{
      actionType: string
      actionParameters: any
      loginUserDTO: any
    }>({
      url: 'mtop.global.seller.login.loginByLazadaOauth',
      type: 'json',
      method: 'POST',
      data: {
        ...{
          ...omit(data, 'password'),
          ...data.password ? { encryptPassword: encryptByRSA(data?.password ?? '') } : {},
        },
        platform: logger?.platform,
        external: JSON.stringify(logger?.externalObj),
      },
    });
  },
  // 进行三方绑定前的buyer信息check
  async getBuyerCheck() {
    return request({
      url: 'mtop.lazada.merchant.pre.onboard.buyer.check',
    });
  },
  // 三方绑定缺少手机号，补全手机号时候对手机号是否已被使用过的校验
  async threeWayPhoneCheck(data) {
    return mtopAxios<TMerchantResponse<{bindingStatus: string}>>({
      url: 'mtop.lazada.merchant.pre.onboard.phone.check',
      type: 'json',
      method: 'POST',
      data,
    })
  },

  async loginByPassword(data: ILoginParams) {
    const headers = getHeader();
    return mtopAxios<ILoginResponse['data']>({
      url: 'mtop.global.seller.login.loginByPassword',
      type: 'json',
      method: 'POST',
      headers,
      data: {
        ...{
          ...omit(data, 'password'),
          ...data.password ? { encryptPassword: encryptByRSA(data?.password ?? '') } : {},
        }, 
        platform: logger?.platform,
        external: JSON.stringify(logger?.externalObj),
      },
    }).catch(res=>{
      return Promise.resolve(res.data)
    })
  },
  // 检测已登陆的买家并已经绑定卖家，获取token
  async getSellerToken(data) {
    return mtopAxios<{idToken: string}>({
      url: 'mtop.global.user.authorization.getOauthIdToken',
      method: 'POST',
      data: { 
        ...{
          ...omit(data, 'password'),
          ...data.password ? { encryptPassword: encryptByRSA(data?.password ?? '') } : {},
        }, 
        external: JSON.stringify(logger?.externalObj), 
        platform: logger?.platform 
      },
    });
  },
  async loginSeller(data) {
    return mtopAxios<{actionParameters: any, actionType: string}>({
      url: 'mtop.global.seller.login.loginBySNS',
      method: 'POST',
      data: { 
        ...{
          ...omit(data, 'password'),
          ...data.password ? { encryptPassword: encryptByRSA(data?.password ?? '') } : {},
        }, 
        external: JSON.stringify(logger?.externalObj), platform: logger?.platform 
      },
    });
  },
  async referralReport(data) {
    return mtopAxios<TMerchantResponse<any>>({
      url: 'mtop.lazada.merchant.onboard.referral.visit',
      data: data,
    }).then(({ data }) => data);
  },
};

type CheckOptCodeParams = Pick<CommonParams, 'code' | 'type' | 'token'>;
/** 验证登录OTP */
export function checkOtpCode(params: CheckOptCodeParams) {
  return mtopAxios({ url: 'mtop.global.seller.identification.verifyIdentificationOTPCode', params })
}

/** 获取不同国家可验证渠道 */
export function getChannelList(bizScene?: EnumOtpType): Promise<EnumOtpChannel[]> {
  return mtopAxios<{
    module: EnumOtpChannel[];
  }>({
    url: 'mtop.global.seller.chats.app.list',
    method: 'POST',
    v: '1.1',
    data: {
      bizScene: bizScene || EnumOtpType.login,
    },
  }).then(({ data }) => data?.module ?? []);
}

import { useDialog } from '@/hooks';
import { EnumOtpChannel } from './constants';
import * as locale from './locale/common';
import './scss/voice-call.scss';

export default (callback = optType => {}) => {
  const [Dialog, { create, close }] = useDialog({
    title: locale.verifyVoiceCallTitle,
    content: (
      <div className="voice-call-content">
        <div className="voice-call-text">{locale.verifyVoiceCallText}</div>
          <div
            className="voice-call-button"
            onClick={() => {
              callback(EnumOtpChannel.VOICE);
            }}
          >
            {locale.callNow}
          </div>
          <div className="voice-call-button" onClick={() => close()}>
            {locale.callCancel}
          </div>
        </div>
    ),
  });

  return { Dialog, create };
};

const getInt = (str) => {
  if (!str) { return str; }
  try {
    return parseInt(str, 10);
  } catch(e) {
    return str;
  }
}

const getFloat = (str) => {
  if (!str) { return str; }
  try {
    return parseFloat(str);
  } catch(e) {
    return str;
  }
}

const isZero = (input) => {
  if (typeof input === 'string') {
    const str = input.trim();
    return str === '0' || str === '0%';
  } else if (typeof input === 'number') {
    return input === 0;
  } else {
    return false;
  }
}

export {
  getInt,
  getFloat,
  isZero
};
import goldlog from '@alife/intl-util/lib/side-effects/goldlog';
import { useBoolean, useRequest } from 'ahooks';
import { Button, Form, Input } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EnumOtpChannel, ventureCodes } from '../constants';
import * as locale from '../locale/common';
import * as API from '../service/mobile';
import { EnumType } from '../service/mobile';
import { checkPhoneNumber, getCountry } from '../untils';
import './index.scss';

export default function Mobile() {
  // 区号
  const [abbreviation, setAbbreviation] = useState('');
  // 手机是否合法提示
  const [verifyMessage, setVerifyMessage] = useState('');
  // 设置红框样式标识
  const [isError, { setFalse, setTrue }] = useBoolean(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const country = getCountry() as string;

  const { run, loading } = useRequest(API.default.getVerifyCode, {
    manual: true,
    onSuccess: (result) => {
      const phoneNumber = form.getFieldValue('number');
      if (result && result?.data?.actionType === 'NC_AUTH') {
        history.push(`/manmachine?phone=${phoneNumber}&code=${abbreviation}`);
        setFalse();
      } else if (result && result?.data?.success) {
        history.push(`/code?phone=${phoneNumber}&code=${abbreviation}&type=${EnumOtpChannel.SMS}`);
        goldlog({
          key: '/onboarding.onboarding_signup.new_m_onboarding_sentotp_success',
          type: 'CLK',
          method: 'GET',
        });

        setFalse();
      } else {
        setVerifyMessage(result?.data?.errorCode?.displayMessage || result?.error);
        setTrue();
      }
    }
  });

  const renderselect = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="countryShow">
        <img src={ventureCodes[country].flagUrl} alt={ventureCodes[country].text} />
        <div style={{ fontSize: '15px', color: '#595F6D', fontFamily: 'Roboto-Regular' }}>
          +{ventureCodes[country].nationalCode}
        </div>
      </div>
    );
  };
  const onFinish = (values: any) => {
    if (values) {
      run({
        phoneNumber: values.number,
        countryCallingCode: `${abbreviation}`,
        type: EnumType.OTP_LOGIN,
        riskInfo: '',
        deliveryType: EnumOtpChannel.SMS,
      });
    }
  };

  const LoginWithPassword = () => {
    history.push('/login');
  };

  const LoginWithOtp = () => {
    history.push('/otp');
  };

  useEffect(() => {
    form.setFieldsValue({
      number: new URLSearchParams(location.hash.split('?')[1]).get('phone'),
    });

    const value = ventureCodes[country].nationalCode;
    setAbbreviation(value);
    return () => {
      localStorage.setItem('number', '60');
    };
  }, []);

  return (
    <div data-spm="d_m_mobile" className="mobilePage aplus-auto-exp">
      <div className="content">
        <span className="phoneNumber">{locale.mobile_number}</span>
        <span className="codeTip">{locale.send_code_tip}</span>
        <div className="phoneBox">
          <Form
            form={form}
            onFinish={onFinish}
            footer={
              <div className="bottomNext">
                <Button
                  data-spm="d_next"
                  loading={loading}
                  className={`${'nextBtn'} aplus-auto-clk aplus-auto-exp`}
                  color="primary"
                  type="submit"
                >
                  {locale.next}
                </Button>
                <div data-spm={`${'d_terms&condition'}`} className={`${'nextTip'} aplus-auto-clk aplus-auto-exp`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: locale.seller_center_protocol_text,
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  ></div>
                </div>
              </div>
            }
          >
            <div className="registerPhone">
              <Form.Item
                name="number"
                required
                rules={[
                  {
                    required: true,
                    message: locale.mobile_required_tip,
                  },

                  () => ({
                    validator(_, value) {
                      return new Promise((resolve, reject) => {
                        if (!value) {
                          setTrue();
                          resolve('');
                        } else if (/[^0-9]/.test(value)) {
                          setTrue();
                          reject(locale.must_be_number);
                        } else if (!checkPhoneNumber(value)) {
                          setTrue();
                          reject(locale.msite_mobile_rule);
                        } else {
                          setFalse();
                          resolve('');
                        }
                      });
                    },
                  }),
                ]}
              >
                <Input
                  onChange={() => setVerifyMessage('')}
                  data-spm="d_country"
                  className={`${!isError ? 'phoneInput' : 'errPhoneInput'} aplus-auto-clk aplus-auto-exp countryInput`}
                  placeholder={locale.enter_phone_tip}
                />
              </Form.Item>
              {renderselect()}
            </div>
            <div className="password-login-btn" onClick={LoginWithPassword}>
              {locale.LoginWithPassword}
            </div>
            <div className="password-login-btn" onClick={LoginWithOtp}>
              {locale.LoginWithOTP}
            </div>
            {verifyMessage && <div className="phoneError">{verifyMessage}</div>}
          </Form>
        </div>
      </div>
    </div>
  );
}

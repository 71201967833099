import PasswordCore, { EnumPasswordType } from '../Password/core';
import '../Password/index.scss';

export default function Password() {
  return (
    <div data-spm="d_m_thirdWayBindPassword" className="passwordPage aplus-auto-exp">
     <PasswordCore coreType={EnumPasswordType.threeWayPassword} />
    </div>
  );
}

// src/payload-rules.ts
var reportEmailOrPhoneNumber = (params) => {
  const { formData } = params || {};
  let res;
  try {
    const data = typeof formData === "string" ? JSON.parse(formData) : formData;
    res = (data == null ? void 0 : data.email) || (data == null ? void 0 : data.phoneNumber) || (data == null ? void 0 : data.phone) || "";
  } catch (e) {
    console.error(e);
  }
  return res;
};
var reportPayloadRules = {
  "mtop.global.seller.login.loginByPassword": {
    getPayload: reportEmailOrPhoneNumber
  },
  "mtop.global.seller.login.loginByOTP": {
    getPayload: reportEmailOrPhoneNumber
  },
  "mtop.global.seller.login.oauthBindLogin": {
    getPayload: reportEmailOrPhoneNumber
  },
  "mtop.global.seller.verificationCode.sendSms": {
    getPayload: reportEmailOrPhoneNumber
  },
  "mtop.lazada.merchant.pre.onboard.phone.check": {
    getPayload: reportEmailOrPhoneNumber
  },
  "mtop.global.seller.register.validateEmailPassword": {
    getPayload: reportEmailOrPhoneNumber
  }
};
var payload_rules_default = reportPayloadRules;
export {
  payload_rules_default as default
};

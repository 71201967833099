import pipe from '../pure-functions/pipe';

const defaultRequestFilterArray = [(request: any) => request];
const defaultResponseFilterArray = [(res: any) => res];

let requestFilterArray: any[] = [...defaultRequestFilterArray];
let responseFilterArray: any[] = [...defaultResponseFilterArray];

export const registerRequestFilter = (func) => {
  requestFilterArray.push(func);
};

export const registerResponseFilter = (func) => {
  responseFilterArray.push(func);
}

export const clearRequestFilter = () => {
  requestFilterArray = [...defaultRequestFilterArray];
}

export const clearResponseFilter = () => {
  responseFilterArray = [...defaultResponseFilterArray];
}

export const getRequestFilter = () => pipe(...requestFilterArray);

export const getResponseFilter = () => pipe(...responseFilterArray);
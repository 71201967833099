import { ASCTools } from '@alife/workstation-utils';
export const VENTURE = ASCTools.getCountry();

export enum EnumVentureType {
  my = 'MY',
  id = 'ID',
  ph = 'PH',
  vn = 'VN',
  th = 'TH',
  sg = 'SG',
}
export enum EnumOtpType {
  login = 'LOGIN',
  register = 'REGISTER',
}

// src/constant/index.ts
import { ASCTools } from "@alife/workstation-utils";
import { DEFAULT_FIELD_INFO_MAP } from "./idbank-field-info";
export * from "./country";
export * from "./mp-idbank";
var _a;
var country = (_a = ASCTools.getCountry()) == null ? void 0 : _a.toUpperCase();
var currentBu = ASCTools.getBU();
var isPre = location.hostname.includes("staging");
var isGSP = location.hostname.includes("lazada-seller.cn");
var ASC_UPLOAD_HOST = {
  SG: isPre ? "filebroker-staging.lazada.sg" : "filebroker.lazada.sg",
  TH: isPre ? "filebroker-staging.lazada.co.th" : "filebroker.lazada.co.th",
  VN: isPre ? "filebroker-staging.lazada.vn" : "filebroker.lazada.vn",
  MY: isPre ? "filebroker-staging.lazada.com.my" : "filebroker.lazada.com.my",
  PH: isPre ? "filebroker-staging.lazada.com.ph" : "filebroker.lazada.com.ph",
  ID: isPre ? "filebroker-staging.lazada.co.id" : "filebroker.lazada.co.id",
  PK: isPre ? "filebroker.daraz.pk" : "filebroker.daraz.pk",
  // 预发的域名被下掉了，暂时先用线上域名
  BD: isPre ? "filebroker.daraz.com.bd" : "filebroker.daraz.com.bd",
  NP: isPre ? "filebroker.daraz.com.np" : "filebroker.daraz.com.np",
  LK: isPre ? "filebroker.daraz.lk" : "filebroker.daraz.lk",
  MM: isPre ? "filebroker.shop.com.mm" : "filebroker.shop.com.mm"
}[country];
var GSP_UPLOAD_HOST = {
  SG: isPre ? "filebroker-sg-staging.lazada-seller.cn" : "filebroker-sg.lazada-seller.cn",
  TH: isPre ? "filebroker-th-staging.lazada-seller.cn" : "filebroker-th.lazada-seller.cn",
  VN: isPre ? "filebroker-vn-staging.lazada-seller.cn" : "filebroker-vn.lazada-seller.cn",
  MY: isPre ? "filebroker-my-staging.lazada-seller.cn" : "filebroker-my.lazada-seller.cn",
  PH: isPre ? "filebroker-ph-staging.lazada-seller.cn" : "filebroker-ph.lazada-seller.cn",
  ID: isPre ? "filebroker-id-staging.lazada-seller.cn" : "filebroker-id.lazada-seller.cn"
}[country];
var GSP_FB_PRIVACY_HOST = {
  SG: isPre ? "fbprivacy-sg-staging.lazada-seller.cn" : "fbprivacy-sg.lazada-seller.cn",
  TH: isPre ? "fbprivacy-th-staging.lazada-seller.cn" : "fbprivacy-th.lazada-seller.cn",
  VN: isPre ? "fbprivacy-vn-staging.lazada-seller.cn" : "fbprivacy-vn.lazada-seller.cn",
  MY: isPre ? "fbprivacy-my-staging.lazada-seller.cn" : "fbprivacy-my.lazada-seller.cn",
  PH: isPre ? "fbprivacy-ph-staging.lazada-seller.cn" : "fbprivacy-ph.lazada-seller.cn",
  ID: isPre ? "fbprivacy-id-staging.lazada-seller.cn" : "fbprivacy-id.lazada-seller.cn"
}[country];
var UPLOAD_HOST = isGSP ? GSP_UPLOAD_HOST : ASC_UPLOAD_HOST;
var ASC_HOST = {
  SG: isPre ? "sellercenter-staging.lazada.sg" : "sellercenter.lazada.sg",
  TH: isPre ? "sellercenter-staging.lazada.co.th" : "sellercenter.lazada.co.th",
  VN: isPre ? "sellercenter-staging.lazada.vn" : "sellercenter.lazada.vn",
  MY: isPre ? "sellercenter-staging.lazada.com.my" : "sellercenter.lazada.com.my",
  PH: isPre ? "sellercenter-staging.lazada.com.ph" : "sellercenter.lazada.com.ph",
  ID: isPre ? "sellercenter-staging.lazada.co.id" : "sellercenter.lazada.co.id",
  PK: isPre ? "sellercenter-staging.daraz.pk" : "sellercenter.daraz.pk",
  BD: isPre ? "sellercenter-staging.daraz.com.bd" : "sellercenter.daraz.com.bd",
  NP: isPre ? "sellercenter-staging.daraz.com.np" : "sellercenter.daraz.com.np",
  LK: isPre ? "sellercenter-staging.daraz.lk" : "sellercenter.daraz.lk",
  MM: isPre ? "sellercenter-staging.shop.com.mm" : "sellercenter.shop.com.mm"
}[country];
var EnumJoinInStatus = /* @__PURE__ */ ((EnumJoinInStatus2) => {
  EnumJoinInStatus2["ready"] = "ready";
  EnumJoinInStatus2["pending"] = "pending";
  EnumJoinInStatus2["rejected"] = "rejected";
  return EnumJoinInStatus2;
})(EnumJoinInStatus || {});
var isDaraz = ASCTools.getBU() === "DARAZ";
var isLazada = ASCTools.getBU() === "LAZADA";
var EnumVentureType = /* @__PURE__ */ ((EnumVentureType2) => {
  EnumVentureType2["my"] = "MY";
  EnumVentureType2["id"] = "ID";
  EnumVentureType2["ph"] = "PH";
  EnumVentureType2["vn"] = "VN";
  EnumVentureType2["th"] = "TH";
  EnumVentureType2["sg"] = "SG";
  EnumVentureType2["pk"] = "PK";
  EnumVentureType2["bd"] = "BD";
  EnumVentureType2["np"] = "NP";
  EnumVentureType2["lk"] = "LK";
  EnumVentureType2["mm"] = "MM";
  return EnumVentureType2;
})(EnumVentureType || {});
var isMY = country === "MY" /* my */;
var isID = country === "ID" /* id */;
var isPH = country === "PH" /* ph */;
var isVN = country === "VN" /* vn */;
var isTH = country === "TH" /* th */;
var isSG = country === "SG" /* sg */;
var isPK = country === "PK" /* pk */;
var isBD = country === "BD" /* bd */;
var isNP = country === "NP" /* np */;
var isLK = country === "LK" /* lk */;
var isMM = country === "MM" /* mm */;
var DEFAULT_FIELD_INFO = DEFAULT_FIELD_INFO_MAP[country];
export {
  ASC_HOST,
  DEFAULT_FIELD_INFO,
  EnumJoinInStatus,
  EnumVentureType,
  GSP_FB_PRIVACY_HOST,
  UPLOAD_HOST,
  country,
  currentBu,
  isBD,
  isDaraz,
  isGSP,
  isID,
  isLK,
  isLazada,
  isMM,
  isMY,
  isNP,
  isPH,
  isPK,
  isSG,
  isTH,
  isVN
};

import { Button, Toast } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as locale from '../locale/common';
import { default as API } from '../service/mobile';
import { getCountry } from '../untils';
import { buyerlogAfterSubmit } from '../untils/index';

import './index.scss';

export interface IData {
  phone?: string;
  email?: string;
  shopName?: string;
  shopLogo?: string;
}

export const ventureCodes = {
  Singapore: '65',
  Philippines: '63',
  Vietnam: '84',
  Thailand: '66',
  Indonesia: '62',
  Malaysia: '60',
};

export default function Create() {
  const [dataUrl, setData] = useState<IData>({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleData = () => {
    const params: any = new URLSearchParams(location.hash.split('?')[1]);
    const data = {
      phone: params.get('phone') ?? '',
      email: params.get('email') ?? '',
      shopName: params.get('shopName') ?? '',
      shopLogo: params.get('shopLogo') ?? '',
    };
    if (data) {
      setData(data);
    }
  };

  // 在调用绑定接口后，一手机号和邮箱都存在需要补全，二只存在邮箱需要补全手机号，当是后者的时候，需要本地存储邮箱，用于password页面使用
  const handleCreate = async () => {
    const params = {
      snsType: 'lzd_buyer',
    };
    setLoading(true);
    const { success, data } = await API.bindBuyerAndSeller(params);
    const { actionType, actionParameters, loginUserDTO, error } = data;
    // 此threewaytoken用于三方绑定手机号时,验证验证码的正确与否
    if (!success) {
      if (actionType === 'COMPLETE_PROFILE') {
        buyerlogAfterSubmit(loginUserDTO?.userId, 'msite_lzd_buyer_one_click');
        if (dataUrl?.email) {
          localStorage.setItem('email', dataUrl?.email);
        } else if (localStorage.getItem('email') && !dataUrl?.email) {
          localStorage.removeItem('email');
        }
        localStorage.setItem('token', actionParameters?.token);
        history.push(`/threewaypassword?create=true&email=${dataUrl?.email}`);
        setLoading(false);
      } else {
        Toast.show({
          icon: 'fail',
          content: error,
        });
        setLoading(false);
      }
    } else {
      location.href = `${window.location.origin}/apps/home/new`;
      setLoading(false);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <div data-spm="d_m_thirdWayBind" className="createPage">
      <div className="createTitle">{locale.create_new_account}</div>
      <div className="avatarBox">
        <img
          className="avatar"
          src="https://img.alicdn.com/imgextra/i1/O1CN01IwEuI51HV2VSe78k1_!!6000000000762-2-tps-120-120.png"
          alt=""
        />
        <div className="span">{locale.lazada_seller}</div>
      </div>
      <div className="personalInformation">
        <div className="title">{locale.Use_the_following_to_create_new_account}</div>
        {dataUrl?.phone && (
          <div className="information">
            <img
              src="https://img.alicdn.com/imgextra/i4/O1CN01Ygs0rw1KudUIUhrB2_!!6000000001224-55-tps-30-30.svg"
              alt=""
            />
            <span className="phone">
              (+{ventureCodes[getCountry()]}) {dataUrl?.phone}
            </span>
          </div>
        )}
        {dataUrl?.email && (
          <div className="information">
            <img
              className="emailImg"
              src="https://img.alicdn.com/imgextra/i3/O1CN01ZOc98r26olyYD2eRK_!!6000000007709-55-tps-30-28.svg"
              alt=""
            />
            <span className="email">{dataUrl?.email}</span>
          </div>
        )}
      </div>
      <div className="bottomBox">
        <Button
          onClick={handleCreate}
          color="primary"
          className="aplus-auto-exp aplus-auto-clk"
          data-spm="d_create"
          loading={loading}
          disabled={loading}
        >
          {locale.create}
        </Button>
      </div>
    </div>
  );
}

import 'uno.css';
import React from 'react';
import ReactDOM from 'react-dom';
import '../m-register/untils/reportTracker';
import App from './page';
import { getDarazDownloadUrl, isDaraz } from '../../utils'

if (isDaraz) {
    window.location.replace(getDarazDownloadUrl())
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}

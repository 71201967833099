import { TypeRequestOptions } from '../interfaces';

const HttpErrorMessageMap = {
  270: 'API_ERROR_400',
  0: 'Network_ERROR_NOT_FOUND'
};

const getParamStr = data =>
  Object.keys(data)
    .filter(key => data[key] !== null && data[key] !== undefined)
    .map(key => {
      if (Object.prototype.toString.call(data[key]) === '[object Object]') {
        data[key] = JSON.stringify(data[key]);
      }
      return key
    })
    .map(key => `${key}=${encodeURIComponent(data[key])}`)
    .join('&')

const defaultOption = {
  url: '',
  data: {},
  method: 'GET',
  dataType: 'json',
  retry: 2,
  retryDelay: 500,
  timeout: 20000,
  redirect: 'follow',
  headers: {}
}

function parseResult(oReq) {
  try {
    return JSON.parse(oReq.responseText);
  } catch (err) {
    err.message = /<html>/.test(oReq.responseText) ? 'The current user has been logged out' : `JSON parse failed by: ${oReq.responseText}`;
    throw err
  }
}

function handleError(oReq: XMLHttpRequest, options: TypeRequestOptions) {
  const errorMsg = HttpErrorMessageMap[oReq.status];
  if (errorMsg) {
    throw new Error(errorMsg);
  } else if (options.retry && options.retry > 0) {
    options.retry -= 1;
    setTimeout(() => { request(options); }, options.retryDelay);
  } else {
    throw new Error('Network error please try later');
  }
}

interface RequestResponse {
  success: boolean
}

function request(options: TypeRequestOptions) {
  const localOptions = { ...defaultOption, ...options };
  const {
    url,
    data,
    method,
    type,
    headers,
    withCredentials,
    contentType,
    timeout,
    redirect,
  } = localOptions;
  return new Promise<RequestResponse>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    try {
      xhr.onreadystatechange = async () => {
        try {
          if (redirect && xhr.readyState === 2 && xhr.responseURL !== url) {
            if (redirect === 'error') {
              reject({ xhr, redirect: true, msg: 'request redirected', responseURL: xhr.responseURL })
            } else if (redirect === 'manual') {
              const res = { success: false, redirect: true, msg: 'request redirected', responseUrl: xhr.responseURL, xhr }
              resolve(res);
            }
          }
          if (xhr.readyState !== 4) { return; };
          if (xhr.status === 200) {
            const result = type === 'json' ? parseResult(xhr) : xhr.responseText
            result.responseHeaders = xhr.getAllResponseHeaders();
            resolve(result);
            return ;
          }
          // 下一个tick执行 不然这个先throw错误 ontimeout给的错误处理不了了
          await new Promise(reso => setTimeout(reso, 0));
          handleError(xhr, options);
        } catch (exception) {
          const responseHeaders = xhr.getAllResponseHeaders();
          reject({ xhr, msg: exception.message, exception, responseHeaders });
        }
      };
      xhr.ontimeout = (e) => {
        reject({ xhr, exception: e });
      }
      if (method.toUpperCase() === 'POST') {
        xhr.open(method, url);
      } else {
        xhr.open(method, url.includes('?') ? `${url}&${getParamStr(data)}` : `${url}?${getParamStr(data)}`);
      }
      Object.entries(headers).forEach(([key, value]: [string, string]) => {
        xhr.setRequestHeader(key, value);
      })
      if (contentType) {
        xhr.setRequestHeader('Content-Type', contentType);
      }
      if (withCredentials) {
        xhr.withCredentials = withCredentials;
      }
      if (timeout) {
        xhr.timeout = timeout;
      }
      if (method.toUpperCase() === 'POST') {
        if (contentType && (contentType as any).includes('application/json')) {
          xhr.send(JSON.stringify(data));
        } else {
          xhr.send(getParamStr(data));
        }
      } else {
        xhr.send();
      }
    } catch (exception) {
      reject({ xhr, msg: exception.message, exception });
    }
  });
}
export default request;

import { Caller, Platform } from '@alife/workstation-utils';
import React, { useEffect } from 'react';
import * as locale from '../locale/common';

import './index.scss';

export default function Download() {
  const handleDownload = () => {
    const handleSeller = Caller.getInstance({ platform: Platform.SELLER });
    const exlaz = {
      platfrom: 'asc_msite',
      spmd: 'd_download_seller_app',
    };
    handleSeller.download(true, JSON.stringify(exlaz));
  };

  useEffect(() => {}, []);

  return (
    <div data-spm="d_m_download" className="downloadPage">
      <div className="lzdLogo" />
      <div className="continueText">{locale.continue_your_business}</div>
      <div className="experienceTip">
        <span>{locale.get_experience}</span>
        <span>{locale.seller_app}</span>
      </div>
      <div
        data-spm="d_download_seller_app"
        className={`${'downloadBtn'} aplus-auto-clk aplus-auto-exp`}
        onClick={handleDownload}
      >
        {locale.download_seller_app}
      </div>
    </div>
  );
}

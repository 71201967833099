import UrlHandler from '@handlers/url-handler';
import retCodeUtil from '@ali/retcode-util';
import Url from 'url';
import get from 'lodash.get';
import { TypeRequestOptions } from '../interfaces';
import requestXhr from './xhr';
import requestMtop from './mtop';
import requestAdminGW from './adminGW';
import isMtop from './isMtop';
import isAdminGW from './isAdminGW';
import { getRequestFilter, getResponseFilter } from './request-filter';
import { getObjectKey } from '../pure-functions/get-object-key';
import { deleteCachedRequest, getCachedRequest, setCachedRequest, OPTIONS_KEY } from './request-storage';

interface RequestError {
    xhr: { status: number }
    msg: string
    exception: { message: string }
}

const addHeader = options => {
    const { data = {} } = options || {};
    const timezone = (new Date).getTimezoneOffset() / 60;
    options.data = {
        _timezone: timezone,
        ...data
    };
}

const defaultOptions: TypeRequestOptions = {
    url: '',
    type: 'json',
    method: 'GET',
    data: {},
    withCache: false,
    noUrlQuery: false,
    noTimezone: false
}

const isCallable = func => func && typeof func === 'function';

/**
 * reqwest方法的代理
 * 主要增加了对retcode的封装
 *
 * @param {Object} options - reqwest接受的参数对象
 * @param {String} [apiName] - 如果指定了该值, 用apiName来做上报标识, 否则会使用去除search的url
 */
export default function request(options: TypeRequestOptions, apiName?: string) {
    let localOptions = { ...defaultOptions, ...options };
    const { noUrlQuery, noTimezone, withCache, requestFilter, responseFilter } = localOptions;
    if (!noUrlQuery) {
        localOptions.data = { ...UrlHandler.getSearchObj(), ...localOptions.data };
    }
    localOptions = getRequestFilter()(localOptions);
    localOptions = (requestFilter && isCallable(requestFilter)) ? requestFilter({ ...localOptions }) : localOptions;

    if (!localOptions.url) {
        return Promise.resolve();
    }

    if (!noTimezone) {
        addHeader(localOptions);
    }
    const { url, method = '', noReload } = localOptions;
    const checkIsMtop = isMtop(url);
    const checkIsAdminGW = isAdminGW(url);
    const retCode = retCodeUtil.retCode(apiName || UrlHandler.getUrlPath(url));

    let requsetPromise = requestXhr as any;
    if (checkIsMtop) { requsetPromise = requestMtop }
    if (checkIsAdminGW) { requsetPromise = requestAdminGW }
    if (!checkIsMtop && !/post/i.test(method)) {
        /**
         * 参数去重
         * 对 { url: '/xxx?a=123', data: { a: '234' } }的场景下会发重复参数的问题进行修复
         * 测试用例
         * 1.
         * {
         *  url: '/xxx',
         *  data: {
         *    a: '123'
         *  }
         * }
         * 2.
         * {
         *  url: '/xxx?b=123',
         *  data: {
         *    a: '123'
         *  }
         * }
         * 3.
         * {
         *  url: '/xxx?a=234',
         *  data: {
         *    a: '123'
         *  }
         * }
         */
        try {
            const formatUrlObject = Url.parse(url || '', true, true) || {};
            const { query = {}, search } = formatUrlObject as any;
            // url中带着的query的优先级是最低的
            localOptions.data = { ...query, ...localOptions.data };
            // 把url中的query参数去掉
            localOptions.url = url.replace(search, '');
        } catch (e) {
            // do nothing
        }
    }
    // dada reloadComponent时会额外传入timestamp导致缓存失效
    // 在生成cacheKey的时候先去除timestamp
    const { timestamp, ...otherData } = options.data || defaultOptions.data || {};
    const { timestamp: localTimeStamp, ...localOtherData } = localOptions.data || defaultOptions.data || {};
    const objectForGenerateCacheKeyOld = {
        url: options.url,
        method: options.method || defaultOptions.method,
        data: otherData,
        type: options.type || defaultOptions.type,
        noUrlQuery: options.noUrlQuery || defaultOptions.noUrlQuery,
        noTimezone: options.noTimezone || defaultOptions.noTimezone
    };
    const objectForGenerateCacheKeyNew = {
        url: localOptions.url,
        method: localOptions.method || defaultOptions.method,
        data: localOtherData,
        type: localOptions.type || defaultOptions.type,
        noUrlQuery: localOptions.noUrlQuery || defaultOptions.noUrlQuery,
        noTimezone: localOptions.noTimezone || defaultOptions.noTimezone
    };
    const cacheKey = getObjectKey(objectForGenerateCacheKeyNew);
    let promise;
    if (getCachedRequest(cacheKey)) {
        // withCache现在默认只复用一次，下一次就重新拉
        const cachePromise = getCachedRequest(cacheKey);
        const ifWithLongCache = get(cachePromise, `${OPTIONS_KEY}.withLongCache`);
        if (!ifWithLongCache) {
            deleteCachedRequest(cacheKey);
        }
        promise = cachePromise;
    } else {
        promise = requsetPromise(localOptions);
        if (withCache) {
            setCachedRequest(getObjectKey(objectForGenerateCacheKeyOld), promise, options);
            setCachedRequest(cacheKey, promise, localOptions);
        }
    }
    return promise
        .then((resp: any = {}) => {
            // noReload 添加noReload属性 防止页面无限刷新
            if ((resp.sessionExpired || resp.code === 601) && !noReload) { // 统一登录态处理逻辑
                location.reload();
            } else {
                retCode.success();
            }
            return Promise.resolve(resp);
        })
        .then((res) => {
            const filterByRequestResponseFilter = (responseFilter && isCallable(responseFilter))
                ? responseFilter(res, localOptions)
                : res;
            const { res: filterRes } = getResponseFilter()({
                req: localOptions,
                res: filterByRequestResponseFilter
            });
            return filterRes;
        })
        .catch(errorObj => {
            retCode.failed(extractErrorInfo(errorObj, localOptions));
            return Promise.reject(errorObj);
        });
}

const defaultError = {
    xhr: { status: 400 },
    msg: 'Unknow error',
    exception: { message: 'Unknow error' }
}

/**
 * 提取接口异常的相关错误信息
 * @param {XHR} xhr - 本次请求的xhr对象
 * @param {String} msg - reqwest的错误信息
 * @param {Error} exception - 格式转换时可能出现的error
 * @param {Object} options - 本次请求的配置对象, 同reqwest的params
 */
export function extractErrorInfo(requestError: RequestError = defaultError, options) {
    let result = '';
    const { xhr, msg, exception } = requestError
    try {
        result = JSON.stringify({
            status: xhr.status,
            msg,
            exception: get(exception, 'message'),
            options,
        });
    } catch (e) {
        result = JSON.stringify({
            msg: 'extractErrorInfo() stringify error',
            exception: get(exception, 'message'),
        });
    }
    return result;
}

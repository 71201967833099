// src/constant/mp-idbank.tsx
import {
  idBankCorporate,
  idBankIndividual
} from "@alife/seller-onboarding-locale";
import { getUrlParam } from "../utils";
var CHOOSE_VERIFICATION_METHODTYPE = getUrlParam("chooseVerificationMethodType") ? Number(getUrlParam("chooseVerificationMethodType")) : null;
var CODE = getUrlParam("code");
var TASK_TYPE = getUrlParam("taskType");
var _a, _b;
var SELLER_ID = ((_b = (_a = window.dadaConfig) == null ? void 0 : _a.sellerId) == null ? void 0 : _b.toString()) || "";
var EnumTaskType = /* @__PURE__ */ ((EnumTaskType2) => {
  EnumTaskType2["id"] = "id";
  EnumTaskType2["bank"] = "bank";
  EnumTaskType2["idBank"] = "idbank";
  return EnumTaskType2;
})(EnumTaskType || {});
var EnumVerificationMethodType = /* @__PURE__ */ ((EnumVerificationMethodType2) => {
  EnumVerificationMethodType2[EnumVerificationMethodType2["individual"] = 1] = "individual";
  EnumVerificationMethodType2[EnumVerificationMethodType2["corporate"] = 0] = "corporate";
  EnumVerificationMethodType2[EnumVerificationMethodType2["manually"] = 2] = "manually";
  return EnumVerificationMethodType2;
})(EnumVerificationMethodType || {});
var EnumVerificationMethod = /* @__PURE__ */ ((EnumVerificationMethod2) => {
  EnumVerificationMethod2["singpass"] = "singpass";
  EnumVerificationMethod2["manual"] = "manual";
  return EnumVerificationMethod2;
})(EnumVerificationMethod || {});
var EnumIDType = /* @__PURE__ */ ((EnumIDType2) => {
  EnumIDType2["CITIZEN"] = "CITIZEN";
  EnumIDType2["PR"] = "PR";
  EnumIDType2["OTHERS"] = "OTHERS";
  return EnumIDType2;
})(EnumIDType || {});
var EnumCompanyStatusType = /* @__PURE__ */ ((EnumCompanyStatusType2) => {
  EnumCompanyStatusType2["nonLive"] = "NON-LIVE";
  EnumCompanyStatusType2["live"] = "LIVE";
  return EnumCompanyStatusType2;
})(EnumCompanyStatusType || {});
var VERIFICATION_METHOD_MAP = {
  [1 /* individual */]: "singpass" /* singpass */,
  [0 /* corporate */]: "singpass" /* singpass */,
  [2 /* manually */]: "manual" /* manual */
};
var SELLER_TYPE_LIST = [
  {
    label: idBankIndividual,
    value: 1
  },
  {
    label: idBankCorporate,
    value: 0
  }
];
export {
  CHOOSE_VERIFICATION_METHODTYPE,
  CODE,
  EnumCompanyStatusType,
  EnumIDType,
  EnumTaskType,
  EnumVerificationMethod,
  EnumVerificationMethodType,
  SELLER_ID,
  SELLER_TYPE_LIST,
  TASK_TYPE,
  VERIFICATION_METHOD_MAP
};

import { DarazLoading, LazadaLoading } from '@ali/merlion-mobile';
import { ASCTools, awaitCatch, Caller, Platform } from '@alife/workstation-utils';
import classname from 'classnames';

export const clazznames = (clazz: Record<string, boolean> = {}) =>
  classname({ ...clazz, 'aplus-auto-exp': true, 'aplus-auto-clk': true });

export const useSpmD = (spmd, clazz: Record<string, boolean> = {}) => ({
  'data-spm': spmd,
  className: clazznames(clazz),
});

export const awaitValidator = async (form, fields) => {
  return await awaitCatch(form.validateFields(fields));
};

export const getCountryLocalLang = () => {
  const langMap = {
    TH: 'th_TH',
    VN: 'vi_VN',
    ID: 'id_ID',
    PH: 'ph_PH',
    SG: 'sg_SG',
    my: 'ms_MY',
  };

  return langMap[ASCTools.getCountry()];
};

export const getUrlQuery = () => new URLSearchParams(window.location.search);

export const currentBU = ASCTools.getBU();

export const Loading = currentBU === 'DARAZ' ? DarazLoading : LazadaLoading;

export const isSellerApp = /lsc\//i.test(navigator.userAgent) || /lazadaseller\//i.test(navigator.userAgent);

export const getDarazDownloadUrl = () => {
  const caller = Caller.getInstance({ platform: Platform.SELLER });
  const isIOS = caller.osSystem.isIOS 
  const isMM = ASCTools.getCountry() === 'MM';
  const darazUrl = {
    ios : 'https://apps.apple.com/bf/app/daraz-seller-center/id1466211050',
    android: 'https://play.google.com/store/apps/details?id=com.daraz.sellercenter&hl=en&gl=US'
  }
  const shopUrl = {
    ios: 'https://apps.apple.com/id/app/shop-seller-center/id1477422130',
    android: 'https://play.google.com/store/apps/details?id=com.shop.sellercenter&hl=en&gl=US'
  }
  if (isMM) {
    return isIOS ? shopUrl.ios : shopUrl.android
  } else {
    return isIOS ? darazUrl.ios : darazUrl.android
  }
}

export const isDaraz = currentBU === 'DARAZ';
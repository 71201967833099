import { Button, Input } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NATIONAL_CODE } from '../constants';
import * as locale from '../locale/common';
import { default as API } from '../service/mobile';
import { getVerifyCode } from '../untils';
import { buyerlogAfterSubmit, loginViaMSite } from '../untils/index';

import '../Code/index.scss';

export default function Code() {
  const [codeValue, setCodeValue] = useState<any>('');
  const [number, setNumber] = useState(0);
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [lenSix, setLenSix] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isTrue, setIsTrue] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handlechange = e => {
    const len = e.length;
    if (len === 6) {
      setLenSix(false);
    }
    if (len < 6) {
      setIsCorrect(false);
      setIsTrue('');
    }
    setCodeValue(e.slice(0, 6));
  };

  const handleData = () => {
    const params = new URLSearchParams(location.hash.split('?')[1]);
    setCode(params.get('code') ?? '');
    setPhone(params.get('phone') ?? '');
  };

  const handleGoQuestionnaire = async () => {
    if (codeValue.length < 6) {
      setLenSix(true);
      return;
    }
    setLoading(true);
    const data = {
      phoneNumber: phone,
      countryCallingCode: code,
      code: codeValue,
      token: localStorage.getItem('threewaytoken') ?? '',
    };

    API.ThreeVerificationCode(data)
      .then((res) => {
        if (res && res?.actionType === 'COMPLETE_PROFILE' && res?.actionParameters?.token) {
          const { loginUserDTO } = res;
          localStorage.setItem('token', res?.actionParameters?.token);
          // buyer三方绑定成功
          buyerlogAfterSubmit(loginUserDTO?.userId, 'msite_lzd_buyer');
          history.push('/threewaypassword');
        } else if (res.success && res?.actionType !== 'COMPLETE_PROFILE') {
          // msite 登录跳首页埋点
          loginViaMSite();
          window.location.href = res?.actionParameters?.loginJumpUrl;
        } else {
          setIsCorrect(true);
          setIsTrue(res?.errorCode?.displayMessage || res?.error);
        }
        setLoading(false);
      })
      .catch(res => {
        setLoading(false);
        setIsCorrect(true);
        setIsTrue(res?.error);
      });
  };

  const handleCode = () => {
    getVerifyCode(undefined, phone, history, true, { type: 'OAUTH_PHONE_VERIFY' });
    count(59);
  };

  const count = num => {
    if (num <= 60 && num > 0) {
      window.timer = setTimeout(() => {
        localStorage.setItem('number', JSON.stringify(num - 1));
        setNumber(num - 1);
      }, 1000);
    }
  };
  // 组件卸载时清除计时器
  useEffect(() => {
    handleData();
    return () => {
      clearTimeout(window.timer);
      localStorage.removeItem('number');
    };
  }, []);

  useEffect(() => {
    const value = localStorage.getItem('number') ?? 0;
    if (value && Number(value) > 0 && Number(value) <= 60) {
      count(Number(value));
    } else if (number < 60 && number > 0) {
      count(number);
    }
  }, [number]);

  return (
    <div data-spm="d_m_thirdWayBindCode" className="codePage aplus-auto-exp">
      <div className="codeTip">{locale.enter_code}</div>
      <div className="codeNumberTip">{locale.enter_sixdigit_code}</div>
      <div className="codeNumber">
        +{NATIONAL_CODE} {phone}
      </div>
      <div className="inputBox">
        <div className="inputList">
          <div
            className={`${'inputValue'} ${isCorrect ? 'wrong' : null} ${
              codeValue.trim() === '' && codeValue.length < 1 ? 'highLight' : null
            }`}
          >
            {codeValue.slice(0, 1)}
          </div>
          <div
            className={`${'inputValue'} ${isCorrect ? 'wrong' : null} ${codeValue.length === 1 ? 'highLight' : null}`}
          >
            {codeValue.slice(1, 2)}
          </div>
          <div
            className={`${'inputValue'} ${isCorrect ? 'wrong' : null} ${codeValue.length === 2 ? 'highLight' : null}`}
          >
            {codeValue.slice(2, 3)}
          </div>
          <div
            className={`${'inputValue'} ${isCorrect ? 'wrong' : null} ${codeValue.length === 3 ? 'highLight' : null}`}
          >
            {codeValue.slice(3, 4)}
          </div>
          <div
            className={`${'inputValue'} ${isCorrect ? 'wrong' : null} ${codeValue.length === 4 ? 'highLight' : null}`}
          >
            {codeValue.slice(4, 5)}
          </div>
          <div
            className={`${'inputValue'} ${isCorrect ? 'wrong' : null} ${codeValue.length === 5 ? 'highLight' : null}`}
          >
            {codeValue.slice(5, 6)}
          </div>
        </div>
        <Input
          value={codeValue}
          type="number"
          maxLength={6}
          data-spm="d_otp"
          className={`${'simpleInput'} aplus-auto-clk aplus-auto-exp`}
          onChange={e => handlechange(e)}
        />
      </div>
      {lenSix && <span style={{ color: 'red', fontSize: '15px' }}>{locale.please_fill_in}</span>}
      {isTrue && (
        <span style={{ color: 'red', fontSize: '15px', width: '100%', wordBreak: 'break-word' }}>{isTrue}</span>
      )}
      <div className="countBox">
        <span className="notReceive">{locale.not_receive_code}</span>
        {number < 1 ? (
          <Button
            fill="none"
            data-spm="d_resend"
            className={`${'button'} aplus-auto-clk aplus-auto-exp`}
            onClick={handleCode}
          >
            {locale.resend}
          </Button>
        ) : (
          <Button fill="none" className={`${'button'}`}>{`${locale.resend}(${number}s)`}</Button>
        )}
      </div>
      <Button
        data-spm="d_next"
        className={`${'bottomBtn'} aplus-auto-clk aplus-auto-exp`}
        onClick={handleGoQuestionnaire}
        loading={loading}
        disabled={loading}
      >
        {locale.next}
      </Button>
    </div>
  );
}

// src/constant/country.ts
import { arrayToMap } from "../utils";
var NATIONALITY_LIST = [
  {
    label: "SINGAPORE CITIZEN",
    value: "SG"
  },
  {
    label: "AFGHAN",
    value: "AF"
  },
  {
    label: "ALBANIAN",
    value: "AL"
  },
  {
    label: "ALGERIAN",
    value: "DZ"
  },
  {
    label: "AMERICAN",
    value: "US"
  },
  {
    label: "ANDORRAN",
    value: "AD"
  },
  {
    label: "ANGOLAN",
    value: "AO"
  },
  {
    label: "ANTIGUAN",
    value: "AG"
  },
  {
    label: "ARGENTINIAN",
    value: "AR"
  },
  {
    label: "ARMENIAN",
    value: "AM"
  },
  {
    label: "AUSTRALIAN",
    value: "AU"
  },
  {
    label: "AUSTRIAN",
    value: "AT"
  },
  {
    label: "AZERBAIJANI",
    value: "AZ"
  },
  {
    label: "BAHAMIAN",
    value: "BS"
  },
  {
    label: "BAHRAINI",
    value: "BH"
  },
  {
    label: "BANGLADESHI",
    value: "BD"
  },
  {
    label: "BARBADOS",
    value: "BB"
  },
  {
    label: "BELARUSSIAN",
    value: "BL"
  },
  {
    label: "BELGIAN",
    value: "BE"
  },
  {
    label: "BELIZEAN",
    value: "BZ"
  },
  {
    label: "BENINESE",
    value: "BJ"
  },
  {
    label: "BHUTANESE",
    value: "BT"
  },
  {
    label: "BOLIVIAN",
    value: "BO"
  },
  {
    label: "BOSNIAN",
    value: "BA"
  },
  {
    label: "BOTSWANA",
    value: "BW"
  },
  {
    label: "BRAZILIAN",
    value: "BR"
  },
  {
    label: "BRITISH",
    value: "GB"
  },
  {
    label: "BRITISH NATIONAL OVERSEAS",
    value: "GG"
  },
  {
    label: "BRITISH OVERSEAS CITIZEN",
    value: "GE"
  },
  {
    label: "BRITISH OVERSEAS TERRITORIES CITIZEN",
    value: "GC"
  },
  {
    label: "BRITISH PROTECTED PERSON",
    value: "GJ"
  },
  {
    label: "BRITISH SUBJECT",
    value: "UK"
  },
  {
    label: "BRUNEIAN",
    value: "BN"
  },
  {
    label: "BULGARIAN",
    value: "BG"
  },
  {
    label: "BURKINABE",
    value: "BF"
  },
  {
    label: "BURUNDIAN",
    value: "BI"
  },
  {
    label: "CAMBODIAN",
    value: "KA"
  },
  {
    label: "CAMEROONIAN",
    value: "CM"
  },
  {
    label: "CANADIAN",
    value: "CA"
  },
  {
    label: "CAPE VERDEAN",
    value: "CV"
  },
  {
    label: "CENTRAL AFRICAN REPUBLIC",
    value: "CF"
  },
  {
    label: "CHADIAN",
    value: "TD"
  },
  {
    label: "CHILEAN",
    value: "CL"
  },
  {
    label: "CHINESE",
    value: "CN"
  },
  {
    label: "CHINESE/HONGKONG SAR",
    value: "HK"
  },
  {
    label: "COLOMBIAN",
    value: "CO"
  },
  {
    label: "COMORAN",
    value: "KM"
  },
  {
    label: "CONGOLESE",
    value: "CG"
  },
  {
    label: "COSTA RICAN",
    value: "CR"
  },
  {
    label: "CROATIAN",
    value: "CB"
  },
  {
    label: "CUBAN",
    value: "CU"
  },
  {
    label: "CYPRIOT",
    value: "CY"
  },
  {
    label: "CZECH",
    value: "CZ"
  },
  {
    label: "D.P.R. KOREAN",
    value: "KP"
  },
  {
    label: "DANISH",
    value: "DK"
  },
  {
    label: "DEMOCRATIC REPUBLIC OF THE CONGO",
    value: "CD"
  },
  {
    label: "DJIBOUTIAN",
    value: "DJ"
  },
  {
    label: "DOMINICAN",
    value: "DM"
  },
  {
    label: "DOMINICAN (REPUBLIC)",
    value: "DO"
  },
  {
    label: "EAST TIMORESE",
    value: "TP"
  },
  {
    label: "ECUADORIAN",
    value: "EC"
  },
  {
    label: "EGYPTIAN",
    value: "EG"
  },
  {
    label: "EQUATORIAL GUINEA",
    value: "GQ"
  },
  {
    label: "ERITREAN",
    value: "ER"
  },
  {
    label: "ESTONIAN",
    value: "EN"
  },
  {
    label: "ETHIOPIAN",
    value: "ET"
  },
  {
    label: "FIJIAN",
    value: "FJ"
  },
  {
    label: "FILIPINO",
    value: "PH"
  },
  {
    label: "FINNISH",
    value: "FI"
  },
  {
    label: "FRENCH",
    value: "FR"
  },
  {
    label: "GABON",
    value: "GA"
  },
  {
    label: "GAMBIAN",
    value: "GM"
  },
  {
    label: "GEORGIAN",
    value: "GO"
  },
  {
    label: "GERMAN",
    value: "DG"
  },
  {
    label: "GHANAIAN",
    value: "GH"
  },
  {
    label: "GREEK",
    value: "GR"
  },
  {
    label: "GRENADIAN",
    value: "GD"
  },
  {
    label: "GUATEMALAN",
    value: "GT"
  },
  {
    label: "GUINEAN",
    value: "GN"
  },
  {
    label: "GUINEAN (BISSAU)",
    value: "GW"
  },
  {
    label: "GUYANESE",
    value: "GY"
  },
  {
    label: "HAITIAN",
    value: "HT"
  },
  {
    label: "HONDURAN",
    value: "HN"
  },
  {
    label: "HUNGARIAN",
    value: "HU"
  },
  {
    label: "ICELANDER",
    value: "IS"
  },
  {
    label: "INDIAN",
    value: "IN"
  },
  {
    label: "INDONESIAN",
    value: "ID"
  },
  {
    label: "IRANIAN",
    value: "IR"
  },
  {
    label: "IRAQI",
    value: "IQ"
  },
  {
    label: "IRISH",
    value: "IE"
  },
  {
    label: "ISRAELI",
    value: "IL"
  },
  {
    label: "ITALIAN",
    value: "IT"
  },
  {
    label: "IVORY COAST",
    value: "CI"
  },
  {
    label: "JAMAICAN",
    value: "JM"
  },
  {
    label: "JAPANESE",
    value: "JP"
  },
  {
    label: "JORDANIAN",
    value: "JO"
  },
  {
    label: "KAZAKHSTANI",
    value: "KZ"
  },
  {
    label: "KENYAN",
    value: "KE"
  },
  {
    label: "KIRIBATI",
    value: "KI"
  },
  {
    label: "KITTIAN & NEVISIAN",
    value: "SW"
  },
  {
    label: "KOREAN, SOUTH",
    value: "KR"
  },
  {
    label: "KUWAITI",
    value: "KW"
  },
  {
    label: "KYRGYZSTAN",
    value: "KY"
  },
  {
    label: "LAOTIAN",
    value: "LA"
  },
  {
    label: "LATVIAN",
    value: "LV"
  },
  {
    label: "LEBANESE",
    value: "LB"
  },
  {
    label: "LESOTHO",
    value: "LS"
  },
  {
    label: "LIBERIAN",
    value: "LR"
  },
  {
    label: "LIBYAN",
    value: "LY"
  },
  {
    label: "LIECHTENSTEINER",
    value: "LI"
  },
  {
    label: "LITHUANIAN",
    value: "LH"
  },
  {
    label: "LUXEMBOURGER",
    value: "LU"
  },
  {
    label: "MACEDONIAN",
    value: "MB"
  },
  {
    label: "MADAGASY",
    value: "MG"
  },
  {
    label: "MALAWIAN",
    value: "MW"
  },
  {
    label: "MALAYSIAN",
    value: "MY"
  },
  {
    label: "MALDIVIAN",
    value: "MV"
  },
  {
    label: "MALIAN",
    value: "ML"
  },
  {
    label: "MALTESE",
    value: "MT"
  },
  {
    label: "MARSHALLESE",
    value: "MH"
  },
  {
    label: "MAURITANEAN",
    value: "MR"
  },
  {
    label: "MAURITIAN",
    value: "MU"
  },
  {
    label: "MEXICAN",
    value: "MX"
  },
  {
    label: "MICRONESIAN",
    value: "MF"
  },
  {
    label: "MOCHINESE/MACAO SAR",
    value: "MO"
  },
  {
    label: "MOLDAVIAN",
    value: "MD"
  },
  {
    label: "MONACAN",
    value: "MC"
  },
  {
    label: "MONGOLIAN",
    value: "MN"
  },
  {
    label: "MONTENEGRIN",
    value: "MJ"
  },
  {
    label: "MOROCCAN",
    value: "MA"
  },
  {
    label: "MOZAMBICAN",
    value: "MZ"
  },
  {
    label: "MYANMAR",
    value: "BU"
  },
  {
    label: "NAMIBIAN",
    value: "NA"
  },
  {
    label: "NAURUAN",
    value: "NR"
  },
  {
    label: "NEPALESE",
    value: "NP"
  },
  {
    label: "NETHERLANDS",
    value: "NT"
  },
  {
    label: "NEW ZEALANDER",
    value: "NZ"
  },
  {
    label: "NI-VANUATU",
    value: "VU"
  },
  {
    label: "NICARAGUAN",
    value: "NI"
  },
  {
    label: "NIGER",
    value: "NE"
  },
  {
    label: "NIGERIAN",
    value: "NG"
  },
  {
    label: "NORWEGIAN",
    value: "NO"
  },
  {
    label: "OMANI",
    value: "OM"
  },
  {
    label: "PAKISTANI",
    value: "PK"
  },
  {
    label: "PALAUAN",
    value: "PW"
  },
  {
    label: "PALESTINIAN",
    value: "PN"
  },
  {
    label: "PANAMANIAN",
    value: "PA"
  },
  {
    label: "PAPUA NEW GUINEAN",
    value: "PG"
  },
  {
    label: "PARAGUAYAN",
    value: "PY"
  },
  {
    label: "PERUVIAN",
    value: "PE"
  },
  {
    label: "POLISH",
    value: "PL"
  },
  {
    label: "PORTUGUESE",
    value: "PT"
  },
  {
    label: "QATARI",
    value: "QA"
  },
  {
    label: "REFUGEE (OTHER THAN XXB)",
    value: "XC"
  },
  {
    label: "REFUGEE (XXB)",
    value: "XB"
  },
  {
    label: "ROMANIAN",
    value: "RO"
  },
  {
    label: "RUSSIAN",
    value: "RF"
  },
  {
    label: "RWANDAN",
    value: "RW"
  },
  {
    label: "SALVADORAN",
    value: "SV"
  },
  {
    label: "SAMMARINESE",
    value: "SM"
  },
  {
    label: "SAMOAN",
    value: "WS"
  },
  {
    label: "SAO TOMEAN",
    value: "ST"
  },
  {
    label: "SAUDI ARABIAN",
    value: "SA"
  },
  {
    label: "SENEGALESE",
    value: "SN"
  },
  {
    label: "SERBIAN",
    value: "RS"
  },
  {
    label: "SEYCHELLOIS",
    value: "SC"
  },
  {
    label: "SIERRA LEONE",
    value: "SL"
  },
  {
    label: "SINGAPORE P.R.",
    value: "SP"
  },
  {
    label: "SLOVAK",
    value: "SK"
  },
  {
    label: "SLOVENIAN",
    value: "SI"
  },
  {
    label: "SOLOMON ISLANDER",
    value: "SB"
  },
  {
    label: "SOMALI",
    value: "SO"
  },
  {
    label: "SOUTH AFRICAN",
    value: "ZA"
  },
  {
    label: "SPANISH",
    value: "ES"
  },
  {
    label: "SRI LANKAN",
    value: "LK"
  },
  {
    label: "ST. LUCIA",
    value: "LC"
  },
  {
    label: "ST. VINCENTIAN",
    value: "VC"
  },
  {
    label: "STATELESS",
    value: "SS"
  },
  {
    label: "SUDANESE",
    value: "SD"
  },
  {
    label: "SURINAMER",
    value: "SR"
  },
  {
    label: "SWEDISH",
    value: "SE"
  },
  {
    label: "SWISS",
    value: "CH"
  },
  {
    label: "SYRIAN",
    value: "SY"
  },
  {
    label: "SZSWAZI",
    value: "SZ"
  },
  {
    label: "TAIWANESE",
    value: "TW"
  },
  {
    label: "TAJIKISTANI",
    value: "TI"
  },
  {
    label: "TANZANIAN",
    value: "TZ"
  },
  {
    label: "THAI",
    value: "TH"
  },
  {
    label: "TOGOLESE",
    value: "TG"
  },
  {
    label: "TONGAN",
    value: "TO"
  },
  {
    label: "TRINIDADIAN & TOBAGONIAN",
    value: "TT"
  },
  {
    label: "TUNISIAN",
    value: "TN"
  },
  {
    label: "TURK",
    value: "TR"
  },
  {
    label: "TURKMEN",
    value: "TM"
  },
  {
    label: "TUVALU",
    value: "TV"
  },
  {
    label: "UGANDAN",
    value: "UG"
  },
  {
    label: "UKRAINIAN",
    value: "UR"
  },
  {
    label: "UNITED ARAB EMIRATES",
    value: "AE"
  },
  {
    label: "UNKNOWN",
    value: "UN"
  },
  {
    label: "URUGUAYAN",
    value: "UY"
  },
  {
    label: "UZBEKISTAN",
    value: "UZ"
  },
  {
    label: "VATICAN CITY STATE (HOLY SEE)",
    value: "VA"
  },
  {
    label: "VENEZUELAN",
    value: "VE"
  },
  {
    label: "VIETNAMESE",
    value: "VN"
  },
  {
    label: "YEMENI",
    value: "YM"
  },
  {
    label: "ZAMBIAN",
    value: "ZM"
  },
  {
    label: "ZIMBABWEAN",
    value: "ZW"
  }
];
var COUNTRY_OF_INCORPORATION_LIST = [
  {
    label: "SINGAPORE",
    value: "SG"
  },
  {
    label: "AFGHANISTAN",
    value: "AF"
  },
  {
    label: "ALAND ISLANDS",
    value: "AX"
  },
  {
    label: "ALBANIAN",
    value: "AL"
  },
  {
    label: "ALGERIA",
    value: "DZ"
  },
  {
    label: "AMERICAN SAMOA",
    value: "AS"
  },
  {
    label: "ANDORRAN",
    value: "AD"
  },
  {
    label: "ANGOLAN",
    value: "AO"
  },
  {
    label: "ANGUILLA",
    value: "AI"
  },
  {
    label: "ANTARCTICA",
    value: "AQ"
  },
  {
    label: "ANTIGUA AND BARBUDA",
    value: "AG"
  },
  {
    label: "ARGENTINA",
    value: "AR"
  },
  {
    label: "ARMENIAN",
    value: "AM"
  },
  {
    label: "ARUBA",
    value: "AW"
  },
  {
    label: "AUSTRALIA",
    value: "AU"
  },
  {
    label: "AUSTRIA",
    value: "AT"
  },
  {
    label: "AZERBAIJAN",
    value: "AZ"
  },
  {
    label: "BAHAMAS",
    value: "BS"
  },
  {
    label: "BAHRAIN",
    value: "BH"
  },
  {
    label: "BANGLADESH",
    value: "BD"
  },
  {
    label: "BARBADOS",
    value: "BB"
  },
  {
    label: "BELARUS",
    value: "BY"
  },
  {
    label: "BELGIUM",
    value: "BE"
  },
  {
    label: "BELIZE",
    value: "BZ"
  },
  {
    label: "BENIN",
    value: "BJ"
  },
  {
    label: "BERMUDA",
    value: "BM"
  },
  {
    label: "BHUTAN",
    value: "BT"
  },
  {
    label: "BOLIVIA,PLURINATIONAL STATE OF",
    value: "BO"
  },
  {
    label: "BONAIRE SINT EUSTATIUS AND SABA",
    value: "BQ"
  },
  {
    label: "BOSNIA AND HERZEGOVINA",
    value: "BA"
  },
  {
    label: "BOTSWANA",
    value: "BW"
  },
  {
    label: "BOUVET ISLAND",
    value: "BV"
  },
  {
    label: "BRAZIL",
    value: "BR"
  },
  {
    label: "BRITISH INDIAN OCEAN TERRITORY",
    value: "IO"
  },
  {
    label: "BRUNEI DARUSSALAM",
    value: "BN"
  },
  {
    label: "BULGARIA",
    value: "BG"
  },
  {
    label: "BURKINA FASO",
    value: "BF"
  },
  {
    label: "BURUNDI",
    value: "BI"
  },
  {
    label: "CABO VERDE",
    value: "CV"
  },
  {
    label: "CAMBODIA",
    value: "KH"
  },
  {
    label: "CAMEROON",
    value: "CM"
  },
  {
    label: "CANADA",
    value: "CA"
  },
  {
    label: "CAYMAN ISLANDS",
    value: "KY"
  },
  {
    label: "CENTRAL AFRICAN REPUBLIC",
    value: "CF"
  },
  {
    label: "CHAD",
    value: "TD"
  },
  {
    label: "CHILE",
    value: "CL"
  },
  {
    label: "CHINA",
    value: "CN"
  },
  {
    label: "CHRISTMAS ISLAND",
    value: "CX"
  },
  {
    label: "COCOS (KEELING) ISLANDS",
    value: "CC"
  },
  {
    label: "COLOMBIA",
    value: "CO"
  },
  {
    label: "COMOROS",
    value: "KM"
  },
  {
    label: "CONGO",
    value: "CG"
  },
  {
    label: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    value: "CD"
  },
  {
    label: "COOK ISLANDS",
    value: "CK"
  },
  {
    label: "COSTA RICA",
    value: "CR"
  },
  {
    label: "COTE D'IVOIRE",
    value: "CI"
  },
  {
    label: "CROATIA",
    value: "HR"
  },
  {
    label: "CUBA",
    value: "CU"
  },
  {
    label: "CURACAO",
    value: "CW"
  },
  {
    label: "CYPRUS",
    value: "CY"
  },
  {
    label: "CZECHIA",
    value: "CZ"
  },
  {
    label: "DENMARK",
    value: "DK"
  },
  {
    label: "DJIBOUTI",
    value: "DJ"
  },
  {
    label: "DOMINICA",
    value: "DM"
  },
  {
    label: "DOMINICAN REPUBLIC",
    value: "DO"
  },
  {
    label: "ECUADOR",
    value: "EC"
  },
  {
    label: "EGYPTIAN",
    value: "EG"
  },
  {
    label: "EL SALVADOR",
    value: "SV"
  },
  {
    label: "EQUATORIAL GUINEA",
    value: "GQ"
  },
  {
    label: "ERITREA",
    value: "ER"
  },
  {
    label: "ESTONIA",
    value: "EE"
  },
  {
    label: "ESWATINI",
    value: "SZ"
  },
  {
    label: "ETHIOPIA",
    value: "ET"
  },
  {
    label: "FALKLAND ISLANDS (MALVINAS)",
    value: "FK"
  },
  {
    label: "FIJI",
    value: "FJ"
  },
  {
    label: "FINLAND",
    value: "FI"
  },
  {
    label: "FRENCH GUIANA",
    value: "GF"
  },
  {
    label: "FRENCH POLYNESIA",
    value: "PF"
  },
  {
    label: "FRENCH SOUTHERN TERRITORIES",
    value: "TF"
  },
  {
    label: "GABON",
    value: "GA"
  },
  {
    label: "GAMBIA",
    value: "GM"
  },
  {
    label: "GB	UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND GD	GRENADA",
    value: "GB"
  },
  {
    label: "GEORGIA",
    value: "GE"
  },
  {
    label: "GERMANY",
    value: "DE"
  },
  {
    label: "GHANA",
    value: "GH"
  },
  {
    label: "GIBRALTAR",
    value: "GI"
  },
  {
    label: "GREECE",
    value: "GR"
  },
  {
    label: "GREENLAND",
    value: "GL"
  },
  {
    label: "GRENADA",
    value: "GD"
  },
  {
    label: "GUADELOUPE",
    value: "GP"
  },
  {
    label: "GUAM",
    value: "GU"
  },
  {
    label: "GUATEMALA",
    value: "GT"
  },
  {
    label: "GUERNSEY",
    value: "GG"
  },
  {
    label: "GUINEA",
    value: "GN"
  },
  {
    label: "GUINEA-BISSAU",
    value: "GW"
  },
  {
    label: "GUYANA",
    value: "GY"
  },
  {
    label: "HAITI",
    value: "HT"
  },
  {
    label: "HEARD ISLAND AND MCDONALD ISLANDS",
    value: "HM"
  },
  {
    label: "HOLY SEE",
    value: "VA"
  },
  {
    label: "HONDURAS",
    value: "HN"
  },
  {
    label: "HONG KONG",
    value: "HK"
  },
  {
    label: "HUNGARY",
    value: "HU"
  },
  {
    label: "ICELAND",
    value: "IS"
  },
  {
    label: "INDIA",
    value: "IN"
  },
  {
    label: "INDONESIA",
    value: "ID"
  },
  {
    label: "IRAN, ISLAMIC REPUBLIC OF",
    value: "IR"
  },
  {
    label: "IRAQ",
    value: "IQ"
  },
  {
    label: "IRELAND",
    value: "IE"
  },
  {
    label: "ISLE OF MAN",
    value: "IM"
  },
  {
    label: "ISRAEL",
    value: "IL"
  },
  {
    label: "ITALY",
    value: "IT"
  },
  {
    label: "JAMAICA",
    value: "JM"
  },
  {
    label: "JAPAN",
    value: "JP"
  },
  {
    label: "JERSEY",
    value: "JE"
  },
  {
    label: "JORDAN",
    value: "JO"
  },
  {
    label: "KAZAKHSTAN",
    value: "KZ"
  },
  {
    label: "KENYA",
    value: "KE"
  },
  {
    label: "KIRIBATI",
    value: "KI"
  },
  {
    label: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    value: "KP"
  },
  {
    label: "KOREA, REPUBLIC OF",
    value: "KR"
  },
  {
    label: "KUWAIT",
    value: "KW"
  },
  {
    label: "KYRGYZSTAN",
    value: "KG"
  },
  {
    label: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    value: "LA"
  },
  {
    label: "LATVIA",
    value: "LV"
  },
  {
    label: "LEBANON",
    value: "LB"
  },
  {
    label: "LESOTHO",
    value: "LS"
  },
  {
    label: "LIBERIA",
    value: "LR"
  },
  {
    label: "LIBYA",
    value: "LY"
  },
  {
    label: "LIECHTENSTEIN",
    value: "LI"
  },
  {
    label: "LITHUANIA",
    value: "LT"
  },
  {
    label: "LUXEMBOURG",
    value: "LU"
  },
  {
    label: "MACAO",
    value: "MO"
  },
  {
    label: "MADAGASCAR",
    value: "MG"
  },
  {
    label: "MALAWI",
    value: "MW"
  },
  {
    label: "MALAYSIA",
    value: "MY"
  },
  {
    label: "MALDIVES",
    value: "MV"
  },
  {
    label: "MALI",
    value: "ML"
  },
  {
    label: "MALTA",
    value: "MT"
  },
  {
    label: "MARSHALL ISLANDS",
    value: "MH"
  },
  {
    label: "MARTINIQUE",
    value: "MQ"
  },
  {
    label: "MAURITANIA",
    value: "MR"
  },
  {
    label: "MAURITIUS",
    value: "MU"
  },
  {
    label: "MAYOTTE",
    value: "YT"
  },
  {
    label: "MEXICO",
    value: "MX"
  },
  {
    label: "MICRONESIA, FEDERATED STATES OF",
    value: "FM"
  },
  {
    label: "MOLDOVA, REPUBLIC OF",
    value: "MD"
  },
  {
    label: "MONACO",
    value: "MC"
  },
  {
    label: "MONGOLIA",
    value: "MN"
  },
  {
    label: "MONTENEGRO",
    value: "ME"
  },
  {
    label: "MONTSERRAT",
    value: "MS"
  },
  {
    label: "MOROCCO",
    value: "MA"
  },
  {
    label: "MOZAMBIQUE",
    value: "MZ"
  },
  {
    label: "MYANMAR",
    value: "MM"
  },
  {
    label: "NAMIBIA",
    value: "NA"
  },
  {
    label: "NAURU",
    value: "NR"
  },
  {
    label: "NEPAL",
    value: "NP"
  },
  {
    label: "NETHERLANDS",
    value: "NL"
  },
  {
    label: "NETHERLANDS",
    value: "NT"
  },
  {
    label: "NETHERLANDS ANTILLES",
    value: "AN"
  },
  {
    label: "NEW CALEDONIA",
    value: "NC"
  },
  {
    label: "NEW ZEALAND",
    value: "NZ"
  },
  {
    label: "NICARAGUA",
    value: "NI"
  },
  {
    label: "NIGER",
    value: "NE"
  },
  {
    label: "NIGERIA",
    value: "NG"
  },
  {
    label: "NIUE",
    value: "NU"
  },
  {
    label: "NORFOLK ISLAND",
    value: "NF"
  },
  {
    label: "NORTH MACEDONIA",
    value: "MK"
  },
  {
    label: "NORTHERN MARIANA ISLANDS",
    value: "MP"
  },
  {
    label: "NORWAY",
    value: "NO"
  },
  {
    label: "OMAN",
    value: "OM"
  },
  {
    label: "OTHERS",
    value: "OT"
  },
  {
    label: "PAKISTAN",
    value: "PK"
  },
  {
    label: "PALAU",
    value: "PW"
  },
  {
    label: "PALESTINE, STATE OF",
    value: "PS"
  },
  {
    label: "PANAMA",
    value: "PA"
  },
  {
    label: "PAPUA NEW GUINEA",
    value: "PG"
  },
  {
    label: "PARAGUAY",
    value: "PY"
  },
  {
    label: "PERU",
    value: "PE"
  },
  {
    label: "PHILIPPINES",
    value: "PH"
  },
  {
    label: "PITCAIRN",
    value: "PN"
  },
  {
    label: "POLAND",
    value: "PL"
  },
  {
    label: "PORTUGAL",
    value: "PT"
  },
  {
    label: "PUERTO RICO",
    value: "PR"
  },
  {
    label: "QATAR",
    value: "QA"
  },
  {
    label: "REUNION",
    value: "RE"
  },
  {
    label: "ROMANIA",
    value: "RO"
  },
  {
    label: "RUSSIAN FEDERATION",
    value: "RU"
  },
  {
    label: "RWANDA",
    value: "RW"
  },
  {
    label: "SAINT BARTHELEMY",
    value: "BL"
  },
  {
    label: "SAINT HELENA ASCENSION AND TRISTAN DA CUNHA",
    value: "SH"
  },
  {
    label: "SAINT KITTS AND NEVIS",
    value: "KN"
  },
  {
    label: "SAINT LUCIA",
    value: "LC"
  },
  {
    label: "SAINT MARTIN (FRENCH PART)",
    value: "MF"
  },
  {
    label: "SAINT PIERRE AND MIQUELON",
    value: "PM"
  },
  {
    label: "SAINT VINCENT AND THE GRENADINES",
    value: "VC"
  },
  {
    label: "SAMOA",
    value: "WS"
  },
  {
    label: "SAN MARINO",
    value: "SM"
  },
  {
    label: "SAO TOME AND PRINCIPE",
    value: "ST"
  },
  {
    label: "SAUDI ARABIA",
    value: "SA"
  },
  {
    label: "SENEGAL",
    value: "SN"
  },
  {
    label: "SERBIA",
    value: "RS"
  },
  {
    label: "SERBIA AND MONTENEGRO",
    value: "CS"
  },
  {
    label: "SEYCHELLES",
    value: "SC"
  },
  {
    label: "SIERRA LEONE",
    value: "SL"
  },
  {
    label: "SINT MAARTEN (DUTCH PART)",
    value: "SX"
  },
  {
    label: "SLOVAKIA",
    value: "SK"
  },
  {
    label: "SLOVENIA",
    value: "SI"
  },
  {
    label: "SOLOMON ISLANDS",
    value: "SB"
  },
  {
    label: "SOMALIA",
    value: "SO"
  },
  {
    label: "SOUTH AFRICA",
    value: "ZA"
  },
  {
    label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    value: "GS"
  },
  {
    label: "SOUTH SUDAN",
    value: "SS"
  },
  {
    label: "SPAIN",
    value: "ES"
  },
  {
    label: "SRI LANKA",
    value: "LK"
  },
  {
    label: "SUDAN",
    value: "SD"
  },
  {
    label: "SURINAME",
    value: "SR"
  },
  {
    label: "SVALBARD AND JAN MAYEN",
    value: "SJ"
  },
  {
    label: "SWEDEN",
    value: "SE"
  },
  {
    label: "SWITZERLAND",
    value: "CH"
  },
  {
    label: "SYRIAN ARAB REPUBLIC",
    value: "SY"
  },
  {
    label: "TAIWAN",
    value: "TW"
  },
  {
    label: "TAJIKISTAN",
    value: "TJ"
  },
  {
    label: "TANZANIA, UNITED REPUBLIC OF",
    value: "TZ"
  },
  {
    label: "THAILAND",
    value: "TH"
  },
  {
    label: "TIMOR-LESTE",
    value: "TL"
  },
  {
    label: "TOGO",
    value: "TG"
  },
  {
    label: "TOKELAU",
    value: "TK"
  },
  {
    label: "TONGA",
    value: "TO"
  },
  {
    label: "TRINIDAD & TOBAGO",
    value: "TT"
  },
  {
    label: "TUNISIA",
    value: "TN"
  },
  {
    label: "TURKIYE",
    value: "TR"
  },
  {
    label: "TURKMENISTAN",
    value: "TM"
  },
  {
    label: "TURKS AND CAICOS ISLANDS",
    value: "TC"
  },
  {
    label: "TUVALU",
    value: "TV"
  },
  {
    label: "UGANDA",
    value: "UG"
  },
  {
    label: "UKRAINE",
    value: "UA"
  },
  {
    label: "UNITED ARAB EMIRATES",
    value: "AE"
  },
  {
    label: "UNITED STATES MINOR OUTLYING ISLANDS",
    value: "UM"
  },
  {
    label: "UNITED STATES OF AMERICA",
    value: "US"
  },
  {
    label: "URUGUAY",
    value: "UY"
  },
  {
    label: "UZBEKISTAN",
    value: "UZ"
  },
  {
    label: "VANUATU",
    value: "VU"
  },
  {
    label: "VENEZUELA BOLIVARIAN REPUBLIC OF",
    value: "VE"
  },
  {
    label: "VIET NAM",
    value: "VN"
  },
  {
    label: "VIRGIN ISLANDS, BRITISH",
    value: "VG"
  },
  {
    label: "VIRGIN ISLANDS, U.S.",
    value: "VI"
  },
  {
    label: "WALLIS AND FUTUNA",
    value: "WF"
  },
  {
    label: "WESTERN SAHARA",
    value: "EH"
  },
  {
    label: "YEMEN",
    value: "YE"
  },
  {
    label: "ZAMBIA",
    value: "ZM"
  },
  {
    label: "ZIMBABWE",
    value: "ZW"
  }
];
var NATIONALITY_LIST_MAP = arrayToMap(NATIONALITY_LIST);
var COUNTRY_OF_INCORPORATION_LIST_MAP = arrayToMap(COUNTRY_OF_INCORPORATION_LIST);
export {
  COUNTRY_OF_INCORPORATION_LIST,
  COUNTRY_OF_INCORPORATION_LIST_MAP,
  NATIONALITY_LIST,
  NATIONALITY_LIST_MAP
};

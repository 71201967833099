const arr2obj = (arr: any[], key?: string) => {
  const obj = {};
  for (const value of arr) {
    if (typeof value === 'string') {
      obj[value] = true;
    } else if (key) {
      obj[value[key]] = value;
    }
  }
  return obj;
}

export default arr2obj;

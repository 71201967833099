import set from './set';

const treeObj = obj => {
  const flatResult = {};
  if (obj === undefined || obj === null) {
    return;
  } else if (Array.isArray(obj)) {
    return;
  } else if (typeof obj === 'object') {
    Object.entries(obj).forEach(([key, value]) => {
      const tokens = key.split('.');
      const firstToken = tokens[0] ? tokens[0] : '';
      if (tokens.length > 1) {
        set(flatResult, tokens, value);
      } else if (tokens.length === 1) {
        flatResult[firstToken] = value;
      }
    });
  }
  return flatResult;
};

export default treeObj;

export interface ISandBoxCodeBlock {
  type: 'JSFunction';
  value: string;
}

export function isJSFunction(block: ISandBoxCodeBlock | any) {
  return typeof block === 'object' && block.type === 'JSFunction';
}

export function createSandBox(ctx) {
  function makeSandBoxFunction(code: string) {
    // ()=>...
    // function(){...}
    return new Function(
      'ctx',
      `
    var args = Array.from(arguments).slice(1);
    args.push(ctx);
    var sandbox = Object.assign({ window: null, arguments: args  }, ctx);
    with(sandbox){
      var fn = ${code}; 
      return fn.apply(this, arguments);
    }`,
    ).bind(null, ctx);
  }
  return (block: ISandBoxCodeBlock | ((...args: any) => any)) => {
    if (typeof block === 'function') {
      return function() {
        return block.apply(this, [...arguments, ctx]);
      };
    }

    if (typeof block !== 'object') {
      return null;
    }

    const { type, value: code } = block;

    if (type === 'JSFunction') {
      return makeSandBoxFunction(code);
    }
  };
}

import { cloneJson, compose, mapDeep } from './index';

const get = (ctx, pathArr, missReturn: any = undefined) => {
  try {
    if (!pathArr || pathArr.length === 0) {
      return ctx;
    }
    const pathStr = pathArr.shift();
    if (!ctx.hasOwnProperty(pathStr)) {
      throw new Error('Not define in this Object');
    }
    return get(ctx[pathStr], pathArr, missReturn);
  } catch (e) {
    return missReturn;
  }
};

const getThunk = ctx => path => {
  let result = get(ctx, path.split('.'), '');
  if (typeof result !== 'string') {
    result = JSON.stringify(result);
  }
  return result;
};

const getThunkWithMiss = ctx => path => {
  let result = get(ctx, path.split('.'));
  if (result === undefined) {
    result = `\${${path}}`;
  } else if (typeof result !== 'string') {
    result = JSON.stringify(result);
  }

  return result;
};

const pathRegex = /\$\{((\w|\.)+)\}/;
const pathRegexGlobal = /\$\{((\w|\.)+)\}/g;
const pureRegex = /^\$\{[^(${)]+\}$/g;

/**
 * ${product.id} => product.id
 * @param str
 */
export const replacePlaceholder = (str: string) => str.replace(pathRegex, '$1');

/**
 * {product: {id: 1}} => product.id => 1
 * @param ctx
 */
const getValueByTokenThunk = ctx =>
  compose(
    getThunk(ctx),
    replacePlaceholder,
  );

/**
 * {product: {id: 1}} => "${product.id}" =>  1
 * @param ctx
 */
export const replaceToken = ctx => expression => {
  if (typeof expression === 'string') {
    const tokens = expression.match(pureRegex);

    if (tokens && tokens.length === 1) {
      const [, path] = expression.match(pathRegex);
      return get(ctx, path.split('.'), undefined);
    }

    return expression.replace(pathRegexGlobal, getValueByTokenThunk(ctx));
  } else {
    return expression;
  }
};

export const checkAndParseJson = result => {
  try {
    // 转义字符串
    result = /^\d+$/.test(result) ? result : JSON.parse(result);
  } catch (e) {
    if (/^undefined$/.test(result)) {
      result = undefined;
    }
  }
  return result;
};
/**
 *
 * formData.test => formData["test"]
 * formData.Root-Product => formData["Root-Product"]
 * formData.Root-Product.id => formData["Root-Product"]["id"]
 * formData.Root - Product => formData["Root"] - Product
 */
export const convertPath = str => str.replace(/\.((\w|-)+)/g, match => `["${match.slice(1)}"]`);

export const visitObjectStringThunk = callback => originObj => {
  return mapDeep(
    originObj,
    (obj, path) => {
      const newObject = {};

      if (Array.isArray(obj)) {
        return obj.map((object, index) => {
          if (typeof object === 'string' || typeof object === 'number') {
            return callback(object, path + '.' + index);
          } else {
            return object;
          }
        })
      }

      Object.entries(obj).forEach(([key, object]) => {
        if (typeof object === 'string' || typeof object === 'number') {
          newObject[key] = callback(object, path + '.' + key);
        } else {
          newObject[key] = object;
        }
      });

      return newObject;
    },
    { isIncludeArray: true },
  );
};

export const replaceObjectToken = ctx => object => {
  if (!object) {
    return object;
  }
  const replaceTokenWithCtx = str => replaceToken(ctx)(str);
  return visitObjectStringThunk(replaceTokenWithCtx)(object);
};

export const hasToken = (object, regex = pathRegex) => {
  let hasTokenMark = false;
  const checkStrToken = str => {
    hasTokenMark = hasTokenMark || regex.test(str);
  };
  visitObjectStringThunk(checkStrToken)(cloneJson(object));
  return hasTokenMark;
};

/**
 * {product: {id: 1}} => product.id => 1
 * {product: {id: 1}} => "${product.id2}" =>  "${product.id2}"
 * @param ctx
 * @returns {any}
 */
const getValueByTokenThunkWithExpress = ctx =>
  compose(
    getThunkWithMiss(ctx),
    replacePlaceholder,
  );

/**
 * {product: {id: 1}} => "${product.id}" =>  1
 * {product: {id: 1}} => "${product.id2}" =>  "${product.id2}"
 * @param ctx
 * @return {(expression: any) => (string | any)}
 */
export const replaceTokenWithExpress = ctx => expression => {
  if (typeof expression === 'string') {
    const tokens = expression.match(pureRegex);

    if (tokens && tokens.length === 1) {
      const [, path] = expression.match(pathRegex);
      return get(ctx, path.split('.'), `\${${path}}`);
    }

    return expression.replace(pathRegexGlobal, getValueByTokenThunkWithExpress(ctx));
  } else {
    return expression;
  }
};

export const replaceObjectTokenWithExpress = ctx => object => {
  if (!object) {
    return object;
  }
  const replaceTokenWithCtx = str => replaceTokenWithExpress(ctx)(str);
  return visitObjectStringThunk(replaceTokenWithCtx)(object);
};

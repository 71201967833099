import { wrapCatch } from "../hof/wrap-catch";

export const equlasArray = (targetA, targetB, CompareStrategy) => {
  const length = targetA.length
  if (length !== targetB.length) { return false }
  for (let i = 0; i < length; i++) {
    const itemA = targetA[i];
    const itemB = targetB[i];
    if (itemA !== itemB) { return equalsDeep(itemA, itemB, CompareStrategy) }
  }
  return true
}

export const equalsObject = (targetA, targetB, CompareStrategy) => {
  const aKeys = Object.keys(targetA);
  const bKeys = Object.keys(targetB);
  if (aKeys.length !== bKeys.length) { return false; }
  return aKeys.every(key => {
    const func = CompareStrategy ? CompareStrategy[key] : null;
    const valueA = targetA[key];
    const valueB = targetB[key];
    if (func) {
      return func(valueA, valueB);
    } else if(Array.isArray(valueA)) {
      return equlasArray(valueA, valueB, CompareStrategy);
    } else {
      return equalsDeep(valueA, valueB, CompareStrategy)
    }
  });
}

/**
 * 使用策略进行判断，帮助优化性能
 * 忽略 buff，symbol 等类型，用于普通数据判断
 * 
 * @param targetA 
 * @param targetB 
 * @param CompareStrategy 对比策略，根据字段key来判断，方便业务定制
 */
export const equalsDeep = (targetA, targetB, CompareStrategy) => {
  if (targetA === targetB) { return true; }
  const typeA = typeof targetA;
  const typeB = typeof targetB;
  if (typeA !== typeB) { return false; }
  if (typeA !== 'object') { return false; } // 基础类型 === 已经判断
  return Array.isArray(targetA)
    ? equlasArray(targetA, targetB, CompareStrategy)
    : equalsObject(targetA, targetB, CompareStrategy);
};

export const equals = wrapCatch(equalsDeep, true);

export type TypeFactor = (...args) => any

export const wrapCatch = (func: TypeFactor, fallbackValue: any = null) => (...args) => {
  try {
    return func.apply(null, args);
  } catch (err) {
    return fallbackValue;
  }
};

export const parseJson = wrapCatch(data => (typeof data === 'string' ? JSON.parse(data) : data));

// src/index.ts
export * from "./constant";
export * from "./utils";
import Encrypt from "encryptlong";
import JSEncrypt from "jsencrypt";
import useAscOss from "./use-asc-oss";
var IS_PRO = !window.location.host.includes("staging");
var REPORT_DEBUG = sessionStorage.getItem("REPORT_DEBUG") === "1" || location.href.includes("REPORT_DEBUG=1");
var traceReport = (category, success, content = "", message, inputData) => {
  if (!IS_PRO && !REPORT_DEBUG)
    return;
  try {
    const { c1, c2, c3, c4, c5, c6, c7, c8, sampleRate } = message || {};
    if (c1 && !content) {
      content = c1;
    }
    const msg = content || JSON.stringify(message);
    const reportData = {
      category,
      msg,
      w_succ: success,
      c1,
      c2,
      c3,
      c4,
      c5,
      c6,
      c7,
      c8,
      bl1: inputData,
      sampleRate
    };
    console.warn(`traceReport_${reportData.category}_${reportData.c1}_${reportData.w_succ}}`, reportData);
    window.__itrace && window.__itrace.report && window.__itrace.report(reportData);
  } catch (e) {
    console.error("traceReport error", e);
  }
};
var initBaxiaReport = (payload) => {
  window.baxiaCommon && window.baxiaCommon.init({
    showCallback: () => traceReport(126, 0, payload)
  });
};
function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i.test(userAgent);
}
var publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0urCNC0H1gA5lA6sAfDA74yFgoWEhZIkeUIralWtB4RlC0nKZcpjW9m2QYPKi/KhpyQBB7Su6fyWiwQhiIDuWWZpoXFcXxGTGovSwJWE1Ux5udZvPsHDym35+DbS4HGwsPJs9/H+oNEZp18h4gv6NZfFo5mVjsK2rqddDjA8tzlVNcRbar+diO1L63XdRJc9l151c9mq5vHRuPqk8AD1YeTZCIUhaIa6vD5io1/BGuNS4QgYLvZUIUP2PNkSzSjrDGsaPA/v4P6hzzuRQyS9W+wXsHC/9YlDXwkpJfHxgIgPotQkdRW3XOk9G84k/ax1kdzOUbneVrXNIiZjYI1phQIDAQAB";
var encryptByRSA = (plainText) => {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
  const result = jsEncrypt.encrypt(plainText);
  return result;
};
var encryptLongTextByRSA = (plainText) => {
  const PUBLIC_KEY = publicKey;
  const encrypt = new Encrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const result = encrypt.encryptLong(plainText);
  return result;
};
export {
  encryptByRSA,
  encryptLongTextByRSA,
  initBaxiaReport,
  isMobile,
  traceReport,
  useAscOss
};

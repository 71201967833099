import { TypeRequestOptions } from "@interfaces/request";

const REQUEST_CACHE_KEY = '_dadaRequestCache';
export const OPTIONS_KEY = '_options';
interface ICachePromise extends Promise<any> {
  _options?: TypeRequestOptions
}

declare global {
  interface Window { _dadaRequestCache: Record<string, ICachePromise>; }
};

// 添加request缓存能力，方便性能战役后续做预加载 @苏桥
window[REQUEST_CACHE_KEY] = window[REQUEST_CACHE_KEY] || {};

export function getCachedRequest(cacheKey: string): Promise<any> | undefined {
  return window[REQUEST_CACHE_KEY][cacheKey];
}

export function setCachedRequest(cacheKey: string, requestPromise: Promise<any>, options?: TypeRequestOptions): void {
  window[REQUEST_CACHE_KEY][cacheKey] = requestPromise;
  try {
    window[REQUEST_CACHE_KEY][cacheKey][OPTIONS_KEY] = options;
  } catch (e) {
    // do nothing
    console.error(e);
  }
}

export function deleteCachedRequest(cacheKey: string): boolean {
  delete window[REQUEST_CACHE_KEY][cacheKey];
  return true;
}
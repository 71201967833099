// src/constant/idbank-field-info.ts
import { idBankBusinessRegistration, idBankVnTaxInfoExampleList } from "@alife/seller-onboarding-locale";
import { ASCTools } from "@alife/workstation-utils";
var darazBusinessNumber = {
  regular: "^[a-zA-Z0-9\\-]+$"
};
var darazBankAccountName = {
  regular: "^[A-Za-z0-9 ()]+$"
};
var _a, _b, _c;
var DEFAULT_FIELD_INFO_MAP = {
  ID: {
    idType: {
      options: [
        {
          label: "KTP",
          value: "KTP",
          regular: "^[0-9]{16}$",
          image: "https://img.alicdn.com/imgextra/i4/O1CN01voqw4C20IoiY0lFVN_!!6000000006827-0-tps-1377-924.jpg"
        },
        {
          label: "Passport",
          value: "Passport",
          regular: "^[0-9A-Z]{1,9}$",
          image: "https://img.alicdn.com/imgextra/i4/O1CN01yNxUre1bpusa0AuNm_!!6000000003515-0-tps-881-625.jpg"
        }
      ]
    },
    idName: {
      regular: "^([A-Za-z0-9]{1,1})([A-Za-z0-9 .]{0,})([A-Za-z0-9.]{1,1})$"
    },
    idNumber: {
      regular: "^[A-Za-z0-9\\- ]+$"
    },
    businessNumber: {
      regular: "^[0-9]{13}$"
    },
    businessOwnerName: {
      regular: "^([A-Za-z0-9]{1,1})([A-Za-z0-9 .]{0,})([A-Za-z0-9.]{1,1})$"
    },
    businessType: {
      defaultValue: "Informasi Bisnis (NIB & Izin Usaha dari OSS)",
      options: [
        {
          label: "Informasi Bisnis (NIB & Izin Usaha dari OSS)",
          value: "Informasi Bisnis (NIB & Izin Usaha dari OSS)",
          image: "https://gw.alicdn.com/imgextra/i1/O1CN01T0hmGa1x1vBI37Xml_!!6000000006384-0-tps-2550-4200.jpg,https://gw.alicdn.com/imgextra/i1/O1CN01FkwSze1EZDGKFYp51_!!6000000000365-0-tps-2481-3508.jpg,https://gw.alicdn.com/imgextra/i1/O1CN01bWqJkg1ZNOyhhT80d_!!6000000003182-0-tps-2481-3898.jpg,https://gw.alicdn.com/imgextra/i2/O1CN01A592YI1dv4YlfBGPQ_!!6000000003797-0-tps-2481-3898.jpg,https://gw.alicdn.com/imgextra/i2/O1CN01YP5czv1ULVGC5Jnpc_!!6000000002501-0-tps-2481-3508.jpg"
        }
      ]
    },
    bankAccountName: {},
    bankAccountNumber: {
      regular: "^[0-9]{10,19}$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i4/O1CN01z74Via1SVv6rBORi5_!!6000000002253-0-tps-1377-924.jpg" },
        { url: "https://img.alicdn.com/imgextra/i2/O1CN01C254a927Hd01S1lpp_!!6000000007772-0-tps-1377-924.jpg" },
        { url: "https://img.alicdn.com/imgextra/i4/O1CN01gYpmP71HSkaxonAes_!!6000000000757-0-tps-1377-924.jpg" },
        { url: "https://img.alicdn.com/imgextra/i2/O1CN01Yvkbwq1K3VcEUEbkl_!!6000000001108-0-tps-1377-924.jpg" },
        { url: "https://img.alicdn.com/imgextra/i1/O1CN01lx5ale1V26AfW1cgr_!!6000000002594-0-tps-1377-924.jpg" }
      ]
    }
  },
  PH: {
    idType: {
      options: [
        {
          label: "TIN ID",
          value: "TIN ID",
          regular: "^[0-9-]{12,15}$",
          image: "https://img.alicdn.com/imgextra/i1/O1CN01lbbpq11nzKa4oaYbE_!!6000000005160-0-tps-585-364.jpg",
          imageBackSide: "https://img.alicdn.com/imgextra/i1/O1CN01vk51PW253m0rDAYK9_!!6000000007471-0-tps-488-310.jpg"
        },
        {
          label: "Driver License",
          value: "Driver License",
          regular: "^[0-9a-zA-Z-]{11,14}$",
          image: "https://img.alicdn.com/imgextra/i3/O1CN019Qtr5j21hqCLo16zh_!!6000000007017-0-tps-961-607.jpg",
          imageBackSide: "https://img.alicdn.com/imgextra/i4/O1CN01WBnXRP1lYeOoMdryh_!!6000000004831-0-tps-816-508.jpg"
        },
        {
          label: "National ID",
          value: "National ID",
          regular: "^[0-9]+$",
          image: "https://img.alicdn.com/imgextra/i4/O1CN01kPdgc81sJGHK5qypj_!!6000000005745-2-tps-1024-576.png",
          imageBackSide: "https://img.alicdn.com/imgextra/i2/O1CN01bGTvEV1zErudtQUCn_!!6000000006683-2-tps-861-567.png"
        },
        {
          label: "SSS",
          value: "SSS",
          regular: "^d{9}$",
          image: "https://img.alicdn.com/imgextra/i1/O1CN01dspGnn1wkWJaMFxZA_!!6000000006346-0-tps-499-312.jpg",
          imageBackSide: "https://img.alicdn.com/imgextra/i4/O1CN01QBI9OG1cAX01EEDn4_!!6000000003560-0-tps-369-235.jpg"
        },
        {
          label: "Passport",
          value: "Passport",
          regular: "^[0-9a-zA-Z]+$",
          image: "https://img.alicdn.com/imgextra/i2/O1CN01K0faIl1SjChyuj4rj_!!6000000002282-0-tps-858-594.jpg",
          imageBackSide: "https://img.alicdn.com/imgextra/i2/O1CN01K0faIl1SjChyuj4rj_!!6000000002282-0-tps-858-594.jpg"
        },
        {
          label: "Unified Multi-Purpose ID (UMID)",
          value: "UMID",
          regular: "^d{12}$",
          image: "https://img.alicdn.com/imgextra/i1/O1CN010x4RW91dMjA5xK4rP_!!6000000003722-2-tps-525-336.png",
          imageBackSide: "https://img.alicdn.com/imgextra/i4/O1CN012GVDkR1bmiP4kXrgb_!!6000000003508-0-tps-525-332.jpg"
        },
        {
          label: "Others",
          value: "Others"
        }
      ]
    },
    idName: {
      regular: "^[a-zA-Z ]+$"
    },
    idNumber: {
      regular: "^[0-9a-zA-Z\\- ]+$"
    },
    businessNumber: {
      regular: "^[0-9A-Za-z ]+$"
    },
    businessOwnerName: {
      regular: "^[a-zA-Z ]+$"
    },
    businessType: {
      defaultValue: "Business Registration",
      options: [
        {
          label: "BIR 2303",
          value: "Business Registration",
          image: "https://img.alicdn.com/imgextra/i4/O1CN01PBg3wq1WWcOyf9S9w_!!6000000002796-0-tps-1368-983.jpg"
        }
      ]
    },
    bankAccountName: {
      regular: "^[0-9a-zA-Z .,()*]+$"
    },
    bankAccountNumber: {
      regular: "^[0-9]+$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i2/O1CN01M8uNnd1s1OjHqwLh3_!!6000000005706-0-tps-1659-889.jpg" }
      ]
    },
    taxInfo: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i4/O1CN01PBg3wq1WWcOyf9S9w_!!6000000002796-0-tps-1368-983.jpg" }
      ]
    },
    vatRegistered: {
      defaultValue: 1
    }
  },
  SG: {
    idType: {
      defaultValue: "NRIC",
      options: [
        {
          label: "NRIC",
          value: "NRIC",
          regular: "^[0-9A-Z]{9}$",
          image: "https://gw.alicdn.com/imgextra/i3/O1CN01HygXck1SMIlaqIuBL_!!6000000002232-2-tps-507-415.png",
          imageBackSide: "https://gw.alicdn.com/imgextra/i4/O1CN01GPPCUk1nNlyGQllIk_!!6000000005078-2-tps-506-407.png"
        },
        {
          label: "PR",
          value: "PR",
          regular: "^[0-9a-zA-Z]{9}$",
          image: "https://gw.alicdn.com/imgextra/i3/O1CN01HygXck1SMIlaqIuBL_!!6000000002232-2-tps-507-415.png",
          imageBackSide: "https://gw.alicdn.com/imgextra/i4/O1CN01GPPCUk1nNlyGQllIk_!!6000000005078-2-tps-506-407.png"
        }
      ]
    },
    idName: {
      regular: "^[A-Za-z0-9]{1,1})([A-Za-z0-9 .]{0,})([A-Za-z0-9.]{1,1}$"
    },
    idNumber: {
      regular: "^[0-9a-zA-Z\\- ]+$"
    },
    businessNumber: {
      regular: "^[0-9A-Z]{9,10}$"
    },
    businessOwnerName: {
      regular: "^([A-Za-z0-9]{1,1})([A-Za-z0-9 .]{0,})([A-Za-z0-9.]{1,1})$"
    },
    businessType: {
      options: [
        {
          label: "Business Registration (ACRA BizFile)",
          value: "Business Registration (ACRA BizFile)",
          image: "https://gw.alicdn.com/imgextra/i2/O1CN019YxvZe1DzwodOcrA5_!!6000000000288-2-tps-922-453.png"
        }
      ],
      defaultValue: "Business Registration (ACRA BizFile)"
    },
    bankAccountName: {
      regular: "^[A-Za-z0-9?][A-Za-z0-9&+-.()（）,，/?？:：'‘s?]*[A-Za-z0-9.?]$"
    },
    bankAccountNumber: {
      regular: "^[0-9]+$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://gw.alicdn.com/imgextra/i1/O1CN01Sza7UK1NO4DUI0wNu_!!6000000001559-2-tps-813-412.png" }
      ]
    }
  },
  VN: {
    idType: {
      defaultValue: "Personal ID",
      options: [
        {
          label: "Chứng minh nhân dân",
          value: "Personal ID",
          regular: "^([0-9]{9}|[0-9]{12})$",
          image: "https://gw.alicdn.com/imgextra/i1/O1CN01lM9hXM29KUdDnafRU_!!6000000008049-0-tps-685-456.jpg,https://gw.alicdn.com/imgextra/i1/O1CN01pT402X1buxE9xZyxE_!!6000000003526-2-tps-683-430.png,https://gw.alicdn.com/imgextra/i4/O1CN01A025IK1w1dJZ4nUsF_!!6000000006248-0-tps-800-500.jpg",
          imageBackSide: "https://gw.alicdn.com/imgextra/i3/O1CN01ixyLPJ1dAMAAlM7NW_!!6000000003695-0-tps-693-445.jpg"
        },
        {
          label: "Passport",
          value: "Passport",
          image: "https://img.alicdn.com/imgextra/i2/O1CN01Zgvq6C25akOn2Am7Y_!!6000000007543-2-tps-1514-1052.png"
        }
      ]
    },
    idName: {
      regular: "^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ. ]+$"
    },
    idNumber: {
      regular: "^[0-9a-zA-Z\\- ]+$"
    },
    businessNumber: {},
    businessOwnerName: {
      regular: "^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ .]+$"
    },
    businessType: {
      defaultValue: "Company",
      options: [
        {
          label: "Company",
          value: "Company",
          image: "https://gw.alicdn.com/imgextra/i2/O1CN01Qq6EOo1oHBqRyCcsT_!!6000000005199-0-tps-579-831.jpg,https://gw.alicdn.com/imgextra/i2/O1CN013DOAJC1S29I2PlsQS_!!6000000002188-0-tps-649-854.jpg"
        },
        {
          label: "Business Household",
          value: "Business Household",
          image: "https://gw.alicdn.com/imgextra/i2/O1CN01Qq6EOo1oHBqRyCcsT_!!6000000005199-0-tps-579-831.jpg,https://gw.alicdn.com/imgextra/i2/O1CN013DOAJC1S29I2PlsQS_!!6000000002188-0-tps-649-854.jpg"
        }
      ]
    },
    bankAccountName: {
      regular: "^[A-Za-z0-9.,()*- ]+$"
    },
    bankAccountNumber: {
      regular: "^[0-9]+$"
    },
    bankInfo: {
      exampleList: [
        {
          url: "https://img.alicdn.com/imgextra/i1/O1CN01HN3Jy927PPk6aBHed_!!6000000007789-0-tps-1241-2205.jpg"
        },
        {
          url: "https://img.alicdn.com/imgextra/i2/O1CN01c4Ui3M1F81S5mhJFB_!!6000000000441-2-tps-888-565.png"
        }
      ]
    },
    taxInfo: {
      exampleList: (_c = (_b = (_a = idBankVnTaxInfoExampleList) == null ? void 0 : _a.split) == null ? void 0 : _b.call(_a, ",")) == null ? void 0 : _c.map((url) => ({ url }))
    }
  },
  MY: {
    idType: {
      defaultValue: "Personal ID",
      options: [
        {
          label: "Personal ID",
          value: "Personal ID",
          regular: "^[0-9]{12}$",
          image: "https://gw.alicdn.com/imgextra/i3/O1CN01MNeNAf1K90KkQdzJO_!!6000000001120-2-tps-456-242.png",
          imageBackSide: "https://gw.alicdn.com/imgextra/i3/O1CN01KLjFuP1GXxRs6LW3T_!!6000000000633-2-tps-449-243.png"
        },
        {
          label: "Passport",
          value: "Passport",
          regular: "^[0-9A-Z]{1,9}$",
          image: "https://img.alicdn.com/imgextra/i2/O1CN014eJRLr1hep99JNKha_!!6000000004303-0-tps-1269-875.jpg"
        }
      ]
    },
    idName: {
      regular: "^([A-Za-z0-9]{1,1})([A-Za-z0-9 .]{0,})([A-Za-z0-9.]{1,1})$"
    },
    idNumber: {
      regular: "^[0-9]{12}$"
    },
    businessNumber: {
      regular: "^[a-zA-Z0-9-]+$"
    },
    businessOwnerName: {
      regular: "^([A-Za-z0-9]{1,1})([A-Za-z0-9 .]{0,})([A-Za-z0-9.]{1,1})$"
    },
    businessType: {
      options: [
        {
          label: "Sole Proprietorship",
          value: "Sole Proprietorship",
          image: "https://img.alicdn.com/imgextra/i1/O1CN01R1OlTr1RkkHsjzebq_!!6000000002150-0-tps-600-772.jpg"
        },
        {
          label: "Enterprise",
          value: "Enterprise",
          image: "https://img.alicdn.com/imgextra/i1/O1CN01R1OlTr1RkkHsjzebq_!!6000000002150-0-tps-600-772.jpg"
        },
        {
          label: "Partnership",
          value: "Partnership",
          image: "https://img.alicdn.com/imgextra/i1/O1CN01R1OlTr1RkkHsjzebq_!!6000000002150-0-tps-600-772.jpg"
        },
        {
          label: "Private Limited Company",
          value: "Private Limited Company",
          image: "https://img.alicdn.com/imgextra/i1/O1CN01R1OlTr1RkkHsjzebq_!!6000000002150-0-tps-600-772.jpg"
        },
        {
          label: "Public Limited Company",
          value: "Public Limited Company",
          image: "https://img.alicdn.com/imgextra/i1/O1CN01R1OlTr1RkkHsjzebq_!!6000000002150-0-tps-600-772.jpg"
        }
      ]
    },
    bankAccountName: {
      regular: "^[A-Za-z0-9 ()&]+$"
    },
    bankAccountNumber: {
      regular: "^[0-9]{9,19}$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://gw.alicdn.com/imgextra/i4/O1CN01KAcgRS1xnYRVTw5dX_!!6000000006488-2-tps-709-383.png" }
      ]
    },
    taxInfo: {
      exampleList: [{ url: "https://img.lazcdn.com/us/media/549ae2f0cc8f08982d9afbbd92a77d47-1899-772.jpg" }]
    }
  },
  TH: {
    idType: {
      defaultValue: "Citizen ID",
      options: [
        {
          label: "Thai National ID",
          value: "Citizen ID",
          regular: "^[0-9]{13}$",
          image: "https://img.alicdn.com/imgextra/i2/O1CN011YOo7K257QsqeGtSO_!!6000000007479-0-tps-3814-2447.jpg"
        },
        {
          label: "Passport",
          value: "Passport",
          regular: "^[A-Z0-9]{1,9}$",
          image: "https://img.alicdn.com/imgextra/i4/O1CN01yNxUre1bpusa0AuNm_!!6000000003515-0-tps-881-625.jpg"
        }
      ]
    },
    idName: {
      regular: "^[฀-๿a-zA-Z ]+$"
    },
    idNumber: {},
    adult: {
      defaultValue: "1"
    },
    businessNumber: {
      regular: "^[0-9]{13}$"
    },
    businessOwnerName: {
      regular: "^[฀-๿a-zA-Z0-9 ]+$"
    },
    businessOwnerDocument: {
      exampleList: [
        {
          url: ASCTools.getLang() === "th_TH" ? "https://img.alicdn.com/imgextra/i4/O1CN01QQWzSL1hJIf3xNVMC_!!6000000004256-0-tps-1312-938.jpg" : "https://img.alicdn.com/imgextra/i2/O1CN01x4BWzr1GoRyPVGsIw_!!6000000000669-0-tps-1316-938.jpg"
        }
      ]
    },
    taxNo: {
      regular: "^[0-9]+$"
    },
    businessType: {
      defaultValue: "Business Registration",
      options: [
        {
          label: idBankBusinessRegistration,
          value: "Business Registration",
          image: "https://img.alicdn.com/imgextra/i3/O1CN01TbQknq1WXzIQF1lTD_!!6000000002799-0-tps-3627-2210.jpg"
        }
      ]
    },
    bankAccountName: {
      regular: "^[฀-๿a-zA-Z0-9 ,.()*]+$"
    },
    bankAccountNumber: {
      regular: "^[0-9]{10,19}$"
    },
    bankInfo: {
      exampleList: [
        {
          url: "https://img.alicdn.com/imgextra/i4/O1CN013YfMzh1TXa5wkxpa8_!!6000000002392-0-tps-4115-2609.jpg"
        },
        {
          url: "https://img.alicdn.com/imgextra/i2/O1CN01J8Xely1xlGUZwKASw_!!6000000006483-2-tps-1110-654.png"
        }
      ]
    },
    taxInfo: {
      exampleList: [
        { url: "https://gw.alicdn.com/imgextra/i3/O1CN01GSBITC1oQLlbxj9Kc_!!6000000005219-0-tps-1029-729.jpg" }
      ]
    }
  },
  PK: {
    idType: {
      defaultValue: "Personal ID",
      options: [
        {
          label: "Personal ID",
          value: "Personal ID",
          image: "https://img.alicdn.com/imgextra/i3/O1CN01dsR5Hq1WjtjuN5X7W_!!6000000002825-2-tps-300-238.png",
          imageBackSide: "https://img.alicdn.com/imgextra/i2/O1CN01wgcKQl1OsaKH1QSVF_!!6000000001761-2-tps-300-238.png"
        }
      ]
    },
    businessNumber: darazBusinessNumber,
    businessFrontSide: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i3/O1CN01rbEFd21qwX5Nb24AD_!!6000000005560-0-tps-538-812.jpg" }
      ]
    },
    bankAccountName: darazBankAccountName,
    bankAccountNumber: {
      regular: "^[0-9]{16}$"
    },
    IBAN: {
      regular: "^PK[0-9|A-Z]{22}$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i2/O1CN01yd9hvx1y1IWCpg1Zj_!!6000000006518-0-tps-475-206.jpg" }
      ]
    }
  },
  BD: {
    idType: {
      defaultValue: "Personal ID",
      options: [
        {
          label: "Personal ID",
          value: "Personal ID",
          image: "https://img.alicdn.com/imgextra/i1/O1CN011GN7ay1UZFLvLYXUm_!!6000000002531-2-tps-236-148.png",
          imageBackSide: "https://img.alicdn.com/imgextra/i1/O1CN01nmLHqG1GOLIkyfhcC_!!6000000000612-2-tps-238-148.png"
        }
      ]
    },
    businessNumber: darazBusinessNumber,
    businessFrontSide: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i4/O1CN01znbqir29HI7ZYkMCn_!!6000000008042-0-tps-905-1280.jpg" }
      ]
    },
    bankAccountName: darazBankAccountName,
    bankAccountNumber: {
      regular: "^[0-9]+$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i1/O1CN01kbBVRz1KlwDhfoZW6_!!6000000001205-0-tps-1195-559.jpg" }
      ]
    }
  },
  NP: {
    idType: {
      defaultValue: "Personal ID",
      options: [
        {
          label: "Personal ID",
          value: "Personal ID",
          image: "https://img.alicdn.com/imgextra/i3/O1CN01dsR5Hq1WjtjuN5X7W_!!6000000002825-2-tps-300-238.png",
          imageBackSide: "https://img.alicdn.com/imgextra/i2/O1CN01wgcKQl1OsaKH1QSVF_!!6000000001761-2-tps-300-238.png"
        }
      ]
    },
    businessNumber: darazBusinessNumber,
    businessFrontSide: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i4/O1CN01elGEOu24WL3PdeH9o_!!6000000007398-0-tps-647-849.jpg" }
      ]
    },
    bankAccountName: darazBankAccountName,
    bankAccountNumber: {
      regular: "^[0-9a-zA-Z]+$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i3/O1CN01ye2uuX1NDXJaQdG5b_!!6000000001536-0-tps-1164-584.jpg" }
      ]
    }
  },
  LK: {
    idType: {
      defaultValue: "Personal ID",
      options: [
        {
          label: "Personal ID",
          value: "Personal ID",
          image: "https://img.alicdn.com/imgextra/i2/O1CN01Y0HeRu1o5Ho6U1yvJ_!!6000000005173-0-tps-1520-976.jpg",
          imageBackSide: "https://img.alicdn.com/imgextra/i2/O1CN017BgjZi1kNMz0LUTTE_!!6000000004671-0-tps-1532-958.jpg"
        }
      ]
    },
    businessNumber: darazBusinessNumber,
    businessFrontSide: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i4/O1CN01WsccQ91NBFKvgTa4V_!!6000000001531-0-tps-1109-1536.jpg" }
      ]
    },
    bankAccountName: darazBankAccountName,
    bankAccountNumber: {
      regular: "^[0-9]+$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://gw.alicdn.com/imgextra/i4/O1CN01KAcgRS1xnYRVTw5dX_!!6000000006488-2-tps-709-383.png" }
      ]
    }
  },
  MM: {
    idType: {
      defaultValue: "Personal ID",
      options: [
        {
          label: "Personal ID",
          value: "Personal ID",
          image: "https://img.alicdn.com/imgextra/i3/O1CN01dsR5Hq1WjtjuN5X7W_!!6000000002825-2-tps-300-238.png",
          imageBackSide: "https://img.alicdn.com/imgextra/i2/O1CN01wgcKQl1OsaKH1QSVF_!!6000000001761-2-tps-300-238.png"
        }
      ]
    },
    businessNumber: darazBusinessNumber,
    businessFrontSide: {
      exampleList: [
        { url: "https://img.alicdn.com/imgextra/i3/O1CN01Y3wvXL28DL5XdiCLg_!!6000000007898-0-tps-1676-2364.jpg" }
      ]
    },
    bankAccountName: darazBankAccountName,
    bankAccountNumber: {
      regular: "^[0-9]+$"
    },
    bankInfo: {
      exampleList: [
        { url: "https://gw.alicdn.com/imgextra/i4/O1CN01KAcgRS1xnYRVTw5dX_!!6000000006488-2-tps-709-383.png" }
      ]
    }
  }
};
export {
  DEFAULT_FIELD_INFO_MAP
};

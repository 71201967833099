import goldlog from '@alife/intl-util/lib/side-effects/goldlog';
import { Button, Form, Input, Toast } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ventureCodes } from '../constants';
import * as locale from '../locale/common';
import { default as API } from '../service/mobile';
import { checkPhoneNumber, getCountry } from '../untils';

import '../Mobile/index.scss';

export default function ThreeBindMobile() {
  // 手机号
  const [phoneNumber, setPhoneNumber] = useState('');
  // 区号
  const [abbreviation, setAbbreviation] = useState('');
  // 手机是否合法提示
  const [vaildMesage, setVaildMesage] = useState('');
  // 设置红框样式标识
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const country = getCountry() as string;
  // 发送短信
  const handlePhoneNumber = (e) => {
    setVaildMesage('');
    setPhoneNumber(e.trim());
  };

  const handleGetVerifyCode = (data) => {
    API.getThreeWayVerifyCode(data)
      .then((res) => {
        if (res && res?.data?.actionType === 'NC_AUTH') {
          history.push(`/threewaymanmachine?phone=${phoneNumber}&code=${abbreviation}`);
          setIsError(false);
        } else if (res && res?.data?.success) {
          history.push(`/threewaycode?phone=${phoneNumber}&code=${abbreviation}`);
          // LOG phone
          goldlog({
            key: '/onboarding.onboarding_signup.new_m_onboarding_thirdsentotp_success',
            type: 'CLK',
            method: 'GET',
          });
          setIsError(false);
        } else {
          setVaildMesage(res.data.errorCode?.displayMessage || res?.data?.error || '');
          setIsError(true);
        }
        setLoading(false);
      })
      .catch((res) => {
        Toast.show({
          icon: 'fail',
          content: res?.error,
        });
        setLoading(false);
      });
  };

  const renderselect = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="countryShow">
        <img src={ventureCodes[country].flagUrl} alt={ventureCodes[country].text} />

        <div style={{ fontSize: '15px', color: '#595F6D', fontFamily: 'Roboto-Regular' }}>
          +{ventureCodes[country].nationalCode}
        </div>
      </div>
    );
  };

  // 三方绑定时需要先check一下手机号是否已被使用
  const fieldCheckPromiseNew = (phonCheckNumber, msg, exsitMsg?) => {
    return new Promise((resolve, reject) => {
      const trigger = async () => {
        if (!checkPhoneNumber(phonCheckNumber)) {
          reject(msg);
          return;
        }
        const { success, data } = await API.threeWayPhoneCheck({
          phone: phonCheckNumber,
          nationCode: ventureCodes[country].nationalCode,
        });

        const { error } = data
        const { bindingStatus } = data?.data || {}
        if (success && bindingStatus === 'EXISTED') {
          reject(exsitMsg);
        } else if (success && bindingStatus === 'NOT_EXISTED') {
          resolve('');
        } else {
          reject(error);
        }
      };
      trigger();
    });
  };

  // check手机号未使用时 则走接下来的绑定流程
  const onFinish = (values: any) => {
    if (values) {
      const data = {
        phoneNumber: values.number,
        countryCallingCode: `${abbreviation}`,
        type: 'OAUTH_PHONE_VERIFY',
      };
      fieldCheckPromiseNew(values.number, locale.msite_mobile_rule, locale.number_already_used)
        .then(() => {
          handleGetVerifyCode(data);
        })
        .catch((error) => {
          setVaildMesage(error);
        });
    }
  };

  useEffect(() => {
    const value = ventureCodes[country].nationalCode;
    setAbbreviation(value);
    return () => {
      localStorage.setItem('number', '60');
    };
  }, []);

  return (
    <div data-spm="d_m_thirdWayBindMobile" className="mobilePage aplus-auto-exp">
      <div className="content">
        <span className="phoneNumber">{locale.mobile_number}</span>
        <span className="codeTip">{locale.send_code_tip}</span>
        <div className="phoneBox">
          <Form
            form={form}
            onFinish={onFinish}
            footer={
              <div className="bottomNext">
                <Button
                  data-spm="d_next"
                  loading={loading}
                  className={`${'nextBtn'} aplus-auto-clk aplus-auto-exp`}
                  color="primary"
                  type="submit"
                >
                  {locale.next}
                </Button>
                <div data-spm={`${'d_terms&condition'}`} className={`${'nextTip'} aplus-auto-clk aplus-auto-exp`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: locale.seller_center_protocol_text,
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  ></div>
                </div>
              </div>
            }
          >
            <div className="registerPhone">
              <Form.Item
                name="number"
                required
                rules={[
                  {
                    required: true,
                    message: locale.mobile_required_tip,
                  },
                  () => ({
                    validator(_, value) {
                      return new Promise((resolve, reject) => {
                        if (!value) {
                          setIsError(true);
                          resolve('');
                        } else if (/[^0-9]/.test(value)) {
                          setIsError(true);
                          reject(locale.must_be_number);
                        } else {
                          setIsError(false);
                          resolve('');
                        }
                      });
                    },
                  }),
                ]}
              >
                <Input
                  onChange={handlePhoneNumber}
                  data-spm="d_country"
                  className={`${!isError ? 'phoneInput' : 'errPhoneInput'} aplus-auto-clk aplus-auto-exp countryInput`}
                  placeholder={locale.enter_phone_tip}
                />
              </Form.Item>
              {renderselect()}
            </div>
            {vaildMesage && <div className="phoneError">{vaildMesage}</div>}
          </Form>
        </div>
      </div>
    </div>
  );
}

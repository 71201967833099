import { EnumOtpType } from '@/constants';
import { When } from '@alife/workstation-utils';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Loading, Toast } from 'antd-mobile';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONST_SWITCH_BUTTON, EnumOtpChannel, ventureCodes } from '../constants';
import * as locale from '../locale/common';
import API, { getChannelList } from '../service/mobile';
import { getCountry } from '../untils';
import './index.scss';

export const PASSWORD_ERROR_CODE = ['GLOBAL_UIC_USER_PASSWORD_MISTAKE'];

const renderCountry = (country) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="countryShow">
      <img src={ventureCodes[country].flagUrl} alt={ventureCodes[country].text} />
      <div style={{ fontSize: '15px', color: '#595F6D', fontFamily: 'Roboto-Regular' }}>
        +{ventureCodes[country].nationalCode}
      </div>
    </div>
  );
};

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [eye, setEye] = useState(true);
  const [accountError, setAccountError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [show, setShow] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const reg = /^[0-9]+$/;
  const country = getCountry() as string;

  // 取第一个为默认选择登录渠道
  const { data: otpChannel = EnumOtpChannel.SMS, loading: initLoading } = useRequest(
    () => getChannelList(EnumOtpType.login).then((res = []) => res[0] || EnumOtpChannel.SMS),
    {
      onError: (err: any) => {
        Toast.show({
          icon: 'fail',
          content: err?.error,
        });
      },
    },
  );

  const handleSubmit = (values: any) => {
    if (values) {
      setLoading(true);
      const { account, password } = values;

      API.loginByPassword({
        countryCallingCode: ventureCodes[country].nationalCode,
        phoneNumber: reg.test(values.account) ? account : undefined,
        email: reg.test(values.account) ? undefined : account,
        password,
        riskInfo: '',
      })
        .then((res) => {
          const { success, data } = res || {};
          const { errorCode, actionParameters, actionType } = data || {};
          const { loginJumpUrl } = actionParameters || {};
          if (success && loginJumpUrl) {
            location.href = loginJumpUrl;
          } else if (errorCode) {
            const code = errorCode?.key ?? '';
            if (PASSWORD_ERROR_CODE.includes(code)) {
              setPasswordError(errorCode?.displayMessage);
              setAccountError('');
            } else {
              setAccountError(errorCode?.displayMessage);
              setPasswordError('');
            }
            setShow(true);
          } else {
            const { tokenType } = actionParameters;
            if (tokenType && actionType === 'REDIRECT') {
              const { redirectUrl } = actionParameters;
              // 后面可以直接替换成接口的redirectUrl
              // const redirectUrl = Url.getQueryString('redirect_url') || '';
              location.href = redirectUrl;
            } else {
              Toast.show({
                icon: 'fail',
                content: locale.NoPermission,
              });
            }
          }
          setLoading(false);
        })
        
    }
  };

  const handleAccount = () => {
    setShow(false);
  };

  const renderEye = () => {
    return (
      <img
        onClick={() => setEye(!eye)}
        className="eye"
        src={
          !eye
            ? 'https://img.alicdn.com/imgextra/i4/O1CN01It9D781O0XQBPqtuR_!!6000000001643-2-tps-86-84.png'
            : 'https://img.alicdn.com/imgextra/i4/O1CN01H5FlWQ1XZBvpoA0oF_!!6000000002937-2-tps-84-84.png'
        }
        alt=""
      />
    );
  };

  // 统一跳转到OTP登录页面
  const LoginWithOTPHandle = () => {
    const account = form.getFieldValue('account');
    const isPhone = /^[0-9]+$/.test(account);
    if (isPhone) {
      history.push(`/otp?phone=${account}`);
    } else {
      history.push('/otp');
    }
  };

  return (
    <div data-spm="d_m_login" className="loginPage aplus-auto-exp">
      <div className="content">
        <div className="loginBack">
          <div className="aplus-auto-clk aplus-auto-exp title" data-spm="d_signup">
            {locale.Login}
          </div>
          <a
            className="aplus-auto-clk aplus-auto-exp sign-up-text"
            data-spm="d_signup"
            href={`/apps/register/index${location.search}`}
          >
            {locale.SignUp}
          </a>
        </div>
        <div className="phoneBox">
          <Form
            form={form}
            onFinish={handleSubmit}
            footer={
              <div className="bottomNext">
                <Button
                  data-spm="d_next"
                  loading={loading}
                  className={`${'nextBtn'} aplus-auto-clk aplus-auto-exp`}
                  color="primary"
                  type="submit"
                >
                  {locale.Login}
                </Button>
                <div className="otp-button">
                  <When condition={initLoading}>
                    <Loading color="primary" className="LoginWithSMS" />
                  </When>
                  <When condition={!initLoading}>
                    <div className="LoginWithSMS" onClick={LoginWithOTPHandle}>
                      {CONST_SWITCH_BUTTON[otpChannel].switchLocale}
                    </div>
                  </When>
                </div>
              </div>
            }
          >
            <div className="LoginPassword">
              <Form.Item
                name="account"
                rules={[
                  {
                    required: true,
                    message: locale.accountisrequire,
                  },
                  () => ({
                    validator(_, value) {
                      return new Promise((resolve, reject) => {
                        if (!value) {
                          resolve('');
                        } else {
                          const isPhone = /^[0-9]+$/.test(value);
                          setIsPhone(isPhone);
                          resolve('');
                        }
                      });
                    },
                  }),
                ]}
              >
                <Input
                  data-spm="d_login_account"
                  className={`${'phoneInput'} aplus-auto-clk aplus-auto-exp`}
                  placeholder={locale.MobileNumberAndEmail}
                  onChange={handleAccount}
                />
              </Form.Item>
              {isPhone && renderCountry(country)}
            </div>
            {show && <div className="errorBox">{accountError}</div>}
            <div className="aplus-auto-clk aplus-auto-exp LoginPassword" data-spm="d_loginwithsms">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: locale.password_required,
                  },
                ]}
              >
                <Input
                  type={eye ? 'password' : ''}
                  data-spm="d_login_password"
                  className={`${'phoneInput'} aplus-auto-clk aplus-auto-exp`}
                  placeholder={locale.password}
                />
              </Form.Item>
              {renderEye()}
            </div>
            {show && <div className="errorBox">{passwordError}</div>}
          </Form>
        </div>
      </div>
    </div>
  );
}

import goldlog from '@alife/intl-util/lib/side-effects/goldlog';
import { Toast } from 'antd-mobile';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as locale from '../locale/common';
import API from '../service/mobile';
import { getScript } from '../untils';

import '../Manmachine/index.scss';

export default function Machine() {
  const history = useHistory();
  const ncScriptSrc = '//aeis.alicdn.com/sd/ncpc/nc.js?t=201806113';
  const handleData = () => {
    const params = new URLSearchParams(location.hash.split('?')[1]);
    const phone = params.get('phone') ?? '';
    const code = params.get('code') ?? '';
    return {
      phone,
      code,
    };
  };
  const handleGetVerifyCode = (data, phone, code) => {
    API.getThreeWayVerifyCode(data)
      .then((res) => {
        if (res && res?.data && res?.data?.actionType === 'NC_AUTH') {
          location.reload();
        } else if (res && res?.data?.success) {
          history.push(`/threewaycode?phone=${phone}&code=${code}`);
          // LOG phone
          goldlog({
            key: '/onboarding.onboarding_signup.new_m_onboarding_thirdsentotp_success',
            type: 'CLK',
            method: 'GET',
          });
        } else {
          Toast.show({
            icon: 'fail',
            content: res?.data?.errorCode?.displayMessage || res?.data?.error,
          });
          location.reload();
        }
      })
      .catch((res) => {
        Toast.show({
          icon: 'fail',
          content: res?.data?.errorCode?.displayMessage || res?.data?.error,
        });
      });
  };

  const initSlider = () => {
    const nc = new noCaptcha({
      renderTo: '#nc',
      appkey: 'QPXW',
      scene: 'register',
      token: '',
      language: 'en',
      isEnabled: true,
      // customWidth: 350,
      timeout: 3000,
      times: 5,
      apimap: {
        // 指定从aeis.alicdn.com上加载设备指纹js组件资源
        umid_Url: '//aeis.alicdn.com/security/umscript/3.3.24/um.js',
        // 指定初始化请求调用cfall.aliyun.com域名接口
        initialize: 'https://cfsg.aliyun.com/nocaptcha/initialize.jsonp',
        // 指定滑动数据分析请求调用cfall.aliyun.com域名接口
        analyze: 'https://cfsg.aliyun.com/nocaptcha/analyze.jsonp',
      },
      callback: (res) => {
        const ncToken = {
          ncSessionId: res.csessionid,
          ncSig: res.sig,
          ncToken: res.token,
        };
        const phoneCode = handleData();
        const data = {
          phoneNumber: phoneCode.phone,
          countryCallingCode: phoneCode.code,
          type: 'OAUTH_PHONE_VERIFY',
          riskInfo: JSON.stringify(ncToken),
        };
        handleGetVerifyCode(data, phoneCode.phone, phoneCode.code);
      },
    });
    nc.upLang('en', {
      _startTEXT: locale.slide_start_text,
      _yesTEXT: locale.slide_yes_text,
    });
  };

  const insertScript = () => {
    getScript(ncScriptSrc).then(() => {
      initSlider();
    });
  };

  const handleBack = () => {
    history.go(-1);
  };

  useEffect(() => {
    insertScript();
    return () => {
      localStorage.setItem('number', '60');
    };
  }, []);
  return (
    <div data-spm="d_m_thirdWayBindManmachine" className="machinePage aplus-auto-exp">
      {/* <div onClick={handleBack} className="arrowBack">
        <LeftOutline className="backIcon" />
      </div> */}
      <div className="shield">
        <img
          src="https://img.alicdn.com/imgextra/i3/O1CN01ZD1sSs29PzN489Rua_!!6000000008061-2-tps-400-400.png"
          alt=""
        />
      </div>
      <div className="tipTop">{locale.account_security}</div>
      <div className="tipBottom">{locale.slide_to_right}</div>
      <div className="Hslider" style={{ width: '100%' }}>
        <div style={{ width: '100%' }} id="nc" />
      </div>
    </div>
  );
}

/**
 * 传入一个对象，获取唯一标识该对象的key
 */
export const getObjectKey = (obj) => {
  if (obj === null) {
    return 'null';
  }

  if (obj === undefined) {
    return 'undefined';
  }

  if (Object.prototype.toString.call(obj) === '[object Array]') {
    return `[${obj.map(item => getObjectKey(item)).join(',')}]`;
  }
  
  if (typeof obj === 'object') {
    return '{' + Object.entries(obj)
      // 字段顺序调整一下 保证顺序一致且稳定
      .sort(([akey], [bkey]) => akey.localeCompare(bkey))
      .map(([key, value]) => `"${key}":${getObjectKey(value)}`)
      .join(',') + '}';
  }

  if (typeof obj === 'string') {
    return `"${obj}"`;
  }

  if (typeof obj === 'number') {
    return obj;
  }

  return obj;
}
export default function enableMtopEmoji() {
  if (!(window as any).lib || !(window as any).lib.mtop || (window as any).lib.mtop.ERROR) {
    throw new Error('Mtop 初始化失败！请参考Mtop文档http://gitlab.alibaba-inc.com/mtb/lib-mtop');
    return;
  }

  // 处理 data 中的 emoji 字符
  function processDataEmoji(next) {
    const that = this;
    const options = this.options;
    const params = this.params;
    let hasEmoji = false;

    // 字符串时,转换 Emoji 成 UTF16
    // http://stackoverflow.com/questions/36673959/how-to-convert-string-with-emoji-%EF%B8%8F-convert-emoji-to-string-like-this-url-wit
    function _process(data) {
      if (typeof data === 'object') {
        data = JSON.stringify(data);
      }
      if (typeof data === 'string') {
        /// (\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff])/g
        // tslint:disable-next-line: only-arrow-functions
        return data.replace(/([\ud835-\ud83f][\u0000-\uffff])/g, function ($) {
          hasEmoji = true;
          return '\\u' + $.charCodeAt(0).toString(16) + '\\u' + $.charCodeAt(1).toString(16);
        });
      } else {
        return data;
      }
    }

    // tslint:disable-next-line: no-unused-expression
    params.data && (params.data = _process(params.data));
    // lib-mtop-open 的 data 处理
    // tslint:disable-next-line: no-unused-expression
    that.sendData && (that.sendData = _process(that.sendData));

    return next().then(() => {
      const retJson = options.retJson;
      let ret = retJson.ret;

      if (ret instanceof Array) {
        ret = ret.join(',');
      }

      // 验签失败时且含有 Emoji 时重试
      if (hasEmoji && ret.indexOf('FAIL_SYS_ILLEGAL_ACCESS') > -1) {
        // TODO retry
        return that.__sequence([
          that.__processToken,
          that.__processRequestUrl,
          that.middlewares,
          that.__processRequest,
        ]);
      }
    });
  }

  if ((window as any).lib.mtop.middlewares) {
    (window as any).lib.mtop.middlewares.push(processDataEmoji);
  }
}

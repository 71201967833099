import React from 'react';
import { Mask, Card } from 'antd-mobile';
import { CloseCircleOutline } from 'antd-mobile-icons';
import { DialogProps } from 'antd-mobile/es/components/dialog/dialog';
import './index.scss';
import { useBoolean } from 'ahooks';

export type IDialogProps = DialogProps & {
  title?: React.ReactNode | ((props: Partial<any>) => React.ReactNode);
  showBottomClose?: boolean;
  onCancel?: () => void;
  /** 点击背景蒙层可关闭 */
  closeMask?: boolean;
};

export const useDialog = ({
  title,
  content: Content,
  showBottomClose: showBottomClose,
  onCancel,
  closeMask = true,
}: IDialogProps): [React.FC<IDialogProps>, { close: () => void; create: (config?) => void }] => {
  const [visible, { setFalse, setTrue }] = useBoolean(false);
  return [
    () => {
      return (
        <Mask
          visible={visible}
          onMaskClick={() => {
            closeMask && setFalse();
          }}
        >
          <div className="overlay-content">
            <Card
              title={title}
              headerStyle={{
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              {Content}
            </Card>
            {showBottomClose && (
              <CloseCircleOutline
                fontSize={30}
                color="#fff"
                className="close"
                onClick={() => {
                  onCancel && onCancel();
                  setTimeout(() => {
                    setFalse();
                  });
                }}
              />
            )}
          </div>
        </Mask>
      );
    },
    {
      create: () => setTrue(),
      close: () => setFalse(),
    },
  ];
};

// src/index.ts
import { internetNotAvailable, systemError, systemTimeout } from "@alife/seller-onboarding-locale";
import { axios, getCookie } from "@alife/workstation-utils";
import { message } from "antd";
import { Toast } from "antd-mobile";
import { initBaxiaReport, isMobile, traceReport } from "@alife/seller-onboarding-shared";
import internalReportPayloadRules from "./payload-rules";
export * from "./typing";
function toast(msg) {
  isMobile() ? Toast.show({
    icon: "fail",
    content: msg
  }) : message.error(msg);
}
var ASC_COMMON_API_MONITOR = 127;
var timeStampCache = /* @__PURE__ */ new Map();
var getOmitFormData = (data) => {
  if (!data)
    return;
  const deleteKeys = ["encryptPassword", "pwd", "password"];
  try {
    const parsedData = typeof data === "string" ? JSON.parse(data) : data;
    for (const key of deleteKeys) {
      delete parsedData[key];
    }
    return typeof data === "string" ? JSON.stringify(parsedData) : parsedData;
  } catch (e) {
    console.error(e);
  }
  return data;
};
axios.interceptors.request.use(
  (config) => {
    const timestampSymbol = Symbol("startTime");
    timeStampCache.set(timestampSymbol, Date.now());
    config.metadata = { startTimeSymbol: timestampSymbol };
    const url = config.url || "";
    const { getPayload } = internalReportPayloadRules[url] || {};
    const payload = getPayload && getPayload({ formData: config.data });
    payload && initBaxiaReport(payload);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
var getReportMessage = (_mtopRes, config) => {
  var _a, _b, _c, _d, _e;
  const apiUrl = config == null ? void 0 : config.url;
  const reportFormData = config == null ? void 0 : config.reportFormData;
  const formData = (config == null ? void 0 : config.data) || {};
  const { data, ret } = _mtopRes || {};
  const responseHeaders = (_mtopRes == null ? void 0 : _mtopRes.responseHeaders) || "";
  const responseData = typeof data === "string" ? data : JSON.stringify(data || "{}");
  const match = responseHeaders.match(/x-eagleeye-id: (\S+)/);
  const eagleEyeId = match ? match[1] : "";
  const errorMsg = (data == null ? void 0 : data.error) || ((_a = data == null ? void 0 : data.errorCode) == null ? void 0 : _a.displayMessage) || (data == null ? void 0 : data.msg) || (data == null ? void 0 : data.returnMsg) || (data == null ? void 0 : data.errorMsg) || (data == null ? void 0 : data.message) || (!(ret == null ? void 0 : ret.toString().match(/SUCCESS/ig)) ? (_b = ret == null ? void 0 : ret.toString().split("::")) == null ? void 0 : _b[1] : "");
  const errorKey = ((_c = data == null ? void 0 : data.errorCode) == null ? void 0 : _c.key) || (data == null ? void 0 : data.errorCode) || (ret == null ? void 0 : ret.toString());
  const { getPayload } = apiUrl && internalReportPayloadRules[apiUrl] || {};
  const payload = reportFormData ? formData : getPayload && getPayload({ formData });
  const timestampSymbol = (_d = config == null ? void 0 : config.metadata) == null ? void 0 : _d.startTimeSymbol;
  const duration = Date.now() - timeStampCache.get(timestampSymbol);
  const msgMap = {
    接口异常退出: systemError,
    接口超时: systemTimeout,
    解析JSON失败: systemError
  };
  return {
    c1: apiUrl,
    c2: payload,
    c3: errorKey,
    c5: eagleEyeId,
    c6: errorMsg && msgMap[errorMsg] || errorMsg,
    c7: responseData,
    c4: duration,
    c8: getCookie("cna") + " / " + ((_e = window.dadaConfig) == null ? void 0 : _e.sellerId)
  };
};
axios.interceptors.response.use(
  (result) => {
    var _a, _b;
    if ((_a = result == null ? void 0 : result.config) == null ? void 0 : _a.data) {
      result.config.data = getOmitFormData(result.config.data);
    }
    const { _mtopRes, config } = result || {};
    const data = _mtopRes == null ? void 0 : _mtopRes.data;
    const { exceptionHandler = true, ignoreSuccess = true } = config || {};
    const message2 = getReportMessage(_mtopRes, config);
    const { c6: errorMsg, c3: errorKey = "" } = message2;
    const isSuccess = typeof (data == null ? void 0 : data.success) !== "undefined" ? data.success : (_mtopRes == null ? void 0 : _mtopRes.retType) === 0 || (data == null ? void 0 : data.notSuccess) === false || (data == null ? void 0 : data.code) === "200" || ((_b = _mtopRes == null ? void 0 : _mtopRes.ret) == null ? void 0 : _b.toString().split("::")[0]) === "SUCCESS";
    if (!ignoreSuccess || !isSuccess) {
      traceReport(ASC_COMMON_API_MONITOR, isSuccess ? 1 : 0, errorKey, message2, JSON.stringify(result || {}));
    }
    if (exceptionHandler && !isSuccess && errorMsg) {
      toast(errorMsg);
    }
    const actualData = {
      success: isSuccess,
      error: errorMsg,
      ...data
    };
    const _mtopResData = {
      ..._mtopRes,
      data: actualData,
      success: isSuccess
    };
    return {
      ...result,
      _mtopRes: _mtopResData,
      success: isSuccess
    };
  },
  (err) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    if ((_b = (_a = err == null ? void 0 : err.config) == null ? void 0 : _a.config) == null ? void 0 : _b.data) {
      err.config.config.data = getOmitFormData((_d = (_c = err == null ? void 0 : err.config) == null ? void 0 : _c.config) == null ? void 0 : _d.data);
    }
    const exceptionHandler = typeof ((_f = (_e = err == null ? void 0 : err.config) == null ? void 0 : _e.config) == null ? void 0 : _f.exceptionHandler) !== "undefined" ? (_h = (_g = err == null ? void 0 : err.config) == null ? void 0 : _g.config) == null ? void 0 : _h.exceptionHandler : true;
    const message2 = {
      ...getReportMessage((_i = err == null ? void 0 : err.config) == null ? void 0 : _i._mtopRes, (_j = err == null ? void 0 : err.config) == null ? void 0 : _j.config),
      ...(err == null ? void 0 : err.retType) === "ABORT" && typeof navigator !== "undefined" && !(navigator == null ? void 0 : navigator.onLine) ? { c6: internetNotAvailable } : {}
    };
    const errorMsg = message2.c6 || (err == null ? void 0 : err.message) || systemError;
    const { c3: errorKey = "" } = message2;
    traceReport(ASC_COMMON_API_MONITOR, 0, errorKey, message2, JSON.stringify(err.config || {}));
    exceptionHandler && toast(errorMsg);
    err.error = errorMsg;
    err.message = errorMsg;
    return Promise.reject(err);
  }
);
function mtopAxios(data) {
  return axios({
    ...data,
    ...(data == null ? void 0 : data.v) && {
      mtopConfig: {
        v: data.v,
        ...data == null ? void 0 : data.mtopConfig
      }
    }
  }).then((res) => {
    return res._mtopRes;
  });
}
export {
  mtopAxios
};

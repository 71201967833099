const defaultLocale = {
  invalidate: 'invalidate time',
  yesterday: 'yesterday'
}

const getFriendlyDate = (time: number, options: any = {}) => {
  const { locale = defaultLocale, displayTime = false } = options;
  if (time < 0) {
    return locale.invalidate
  }
  const inputDate = new Date(time);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const isToday = inputDate.toDateString() === currentDate.toDateString();
  currentDate.setDate(currentDate.getDate() - 1);
  const isYesterday = inputDate.toDateString() === currentDate.toDateString();
  const timeStr = inputDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'});
  if (isToday) {
    return timeStr
  } else if (isYesterday) {
    return locale.yesterday;
  } else {
    const date = inputDate.toLocaleDateString('en-GB').replace(`/${year}`, '')
    return `${date}${displayTime ? ` ${timeStr}` : ''}`;
  }
}

export default getFriendlyDate;

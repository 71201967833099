import { ASCTools } from '@alife/workstation-utils';
import { Space } from 'antd-mobile';
import type { TagProps } from 'antd-mobile/es/components/tag';
import type React from 'react';
import './index.scss';

export const EMAIL_DOWN_MAP =
  {
    SG: ['@gmail.com', '@yahoo.com', '@hotmail.com', '@outlook.com'],
    VN: ['@gmail.com', '@icloud.com', '@yahoo.com', '@outlook.com'],
    MY: ['@gmail.com', '@yahoo.com', '@hotmail.com', '@outlook.com'],
    ID: ['@gmail.com', '@yahoo.com', '@gmail.co.id', '@yahoo.co.id'],
    PH: ['@gmail.com', '@yahoo.com', '@outlook.com'],
    TH: ['@gmail.com', '@icloud.com', '@hotmail.com', '@outlook.com'],
  }[ASCTools.getCountry()] ?? [];

const EmailTag: React.FC<{ onClickTag?: (item: string) => void } & TagProps> = ({ onClickTag, ...arg }) => {
  if (EMAIL_DOWN_MAP.length === 0) {
    return null;
  }

  return (
    <Space className="email-tag-group  aplus-module-auto-exp" data-spm="email_down_tag_group_msite">
      {EMAIL_DOWN_MAP.map((item) => (
        <div
          className="email-tag-group-tag aplus-auto-exp aplus-auto-clk"
          data-spm={`d_email_down_tag_${item}_msite`}
          key={item}
          onClick={(e) => {
            onClickTag && onClickTag(item);
          }}
        >
          {item}
        </div>
      ))}
    </Space>
  );
};

export default EmailTag;

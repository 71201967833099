import { fetchOscConfig } from '@alife/lago-utils';
import { ASCTools } from '@alife/workstation-utils';
import { useMount } from 'ahooks';
import { useEffect } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Bind from '../m-register/Bind';
import Code from '../m-register/Code';
import Create from '../m-register/Create';
import Download from '../m-register/Download';
import Login from '../m-register/Login';
import Manmachine from '../m-register/Manmachine';
import Mobile from '../m-register/Mobile';
import OTP from '../m-register/OTP';
import Password from '../m-register/Password';
import ThreeWayBind from '../m-register/ThreeWayBind';
import ThreeWayCode from '../m-register/ThreeWayCode';
import ThreeWayManmachine from '../m-register/ThreeWayManmachine';
import ThreeWayPassword from '../m-register/ThreeWayPassword';
import { default as API } from '../m-register/service/mobile';
import { logger } from '../m-register/untils/logger';

const currentBU = ASCTools.getBU();
export const isLazada = currentBU === 'LAZADA';

function Page() {
  useEffect(() => {
    const getPixelSwitch = async () => {
      const data = await fetchOscConfig('87');
      const { isPixel } = data;
      // 是则区分上报，否则不区分,均上报
      if (isPixel) {
        logger.init();
      } else {
        logger.setFacebook(true);
        logger.setGoogle(true);
        logger.init();
      }
    };
    isLazada && getPixelSwitch();
    if (localStorage.getItem('buyer_app_source') === 'register') {
      localStorage.setItem('buyer_app_source', 'register,login');
    }
  }, []);
  useMount(() => {
    const { referralCode = '' } = logger;
    referralCode &&
      API.referralReport({ referralCode })
        .then(res => {
          console.info('report success');
        })
        .catch(() => {
          console.info('report failed');
        });
  });
  return (
    <HashRouter>
      <Switch>
        <Redirect to="/login" exact from="/" />
        <Route path="/mobile" exact component={Mobile}></Route>
        <Route path="/manmachine" exact component={Manmachine}></Route>
        <Route path="/code" exact component={Code}></Route>
        <Route path="/login" exact component={Login}></Route>
        <Route path="/password" exact component={Password}></Route>
        <Route path="/download" exact component={Download}></Route>
        <Route path="/create" exact component={Create}></Route>
        <Route path="/bind" exact component={Bind}></Route>
        <Route path="/threeWayBind" exact component={ThreeWayBind}></Route>
        <Route path="/threeWayManmachine" exact component={ThreeWayManmachine}></Route>
        <Route path="/threeWayCode" exact component={ThreeWayCode}></Route>
        <Route path="/threeWayPassword" exact component={ThreeWayPassword}></Route>
        <Route path="/otp" exact component={OTP}></Route>
        {/* <Route component={Home} /> */}
      </Switch>
    </HashRouter>
  );
}

export default Page;

import curry from "./curry";

export const getByPath = (object, pathArr) => {
  let index = 0;
  const length = pathArr.length;
  while (object != null && index < length) {
    object = object[pathArr[index++]];
  }
  return (index && index === length) ? object : undefined;
}

export const get = (obj, path, fallbackValue?: any) => {
  const res = getByPath(obj, typeof path === 'string' ? path.split('.') : path);
  return res === undefined ? fallbackValue : res;
}

export const getCurry = curry((obj, path) => get(obj, path));

export default get; // for lib import

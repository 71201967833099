const noop = () => {};
const mockArms = {
  api: noop,
  error: noop,
  sum: noop,
  avg: noop,
  reportBehavior: noop,
  performance: noop,
};

let arms = window.__bl;
if (!arms) {
  arms = mockArms;
}

export default arms;
